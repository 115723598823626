import { PDF_FONTS, vfs } from './vfs_fonts';
import { FlossPalette } from '@orthly/ui';
import type { TCreatedPdf } from 'pdfmake/build/pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import type { Content, ContentText, ContextPageSize, StyleDictionary, TDocumentDefinitions } from 'pdfmake/interfaces';

const DEFAULT_PAGE_MARGIN = 40;

export type ScanImageUrls = {
    front: string;
    right: string;
    left: string;
    upper: string;
    lower: string;
};

export interface PatientScansPdfConfig {
    practice_name: string;
    practice_phone: string;
    doctor_name: string;
    patient_name: string;
    appointment_date: string;
    portal_link: string;
    scan_image_urls: ScanImageUrls;
    scan_annotations?: Record<string, string>;
    doctor_message?: string;
}

export class PatientScansPdfGenerator {
    private readonly config: PatientScansPdfConfig;

    constructor(config: PatientScansPdfConfig) {
        this.config = config;
        pdfMake.vfs = vfs;
        pdfMake.fonts = PDF_FONTS;
    }

    private generateDocumentTitle() {
        return `${this.config.patient_name}'s ${this.config.appointment_date} appointment`;
    }

    private generateHeader(currentPage: number, pageCount: number, _pageSize: ContextPageSize): Content {
        return [
            {
                columns: [
                    [
                        { text: this.config.practice_name, style: 'practiceName', margin: [0, 0, 0, 4] },
                        { text: this.config.practice_phone, style: 'subtitle' },
                    ],
                    {
                        width: 'auto',
                        text: `Page ${currentPage}/${pageCount}`,
                        style: 'subtitle',
                    },
                ],
                margin: [DEFAULT_PAGE_MARGIN, DEFAULT_PAGE_MARGIN, DEFAULT_PAGE_MARGIN, 0],
            },

            {
                columns: [
                    [
                        {
                            text: `${this.config.patient_name}'s ${this.config.appointment_date} appointment with Dr. ${this.config.doctor_name}`,
                            style: 'heading',
                            margin: [0, 10, 0, 6],
                        },
                        {
                            text: 'Tip: You can also visualize the digital version of this by scanning the QR code.',
                            style: 'subtitle',
                        },
                    ],
                    { qr: this.config.portal_link, fit: 50, width: 'auto' },
                ],
                columnGap: 50,
                margin: [DEFAULT_PAGE_MARGIN, 20, DEFAULT_PAGE_MARGIN, 0],
            },

            {
                canvas: [
                    {
                        type: 'line',
                        x1: 0,
                        y1: 5,
                        x2: 595 - 2 * DEFAULT_PAGE_MARGIN,
                        y2: 5,
                        lineWidth: 0.5,
                        lineColor: FlossPalette.DARK_TAN,
                    },
                ],
                margin: [DEFAULT_PAGE_MARGIN, 15, 0, 20],
            },
        ];
    }

    private generateHeading(text: string, topMargin: number = 0): ContentText {
        return { text, style: 'heading', margin: [0, topMargin, 0, 25] };
    }

    private generateScansSection(): Content {
        const generateFrontScan = (): Content => {
            const front = this.config.scan_image_urls.front;

            if (front === '') {
                return [];
            }

            return {
                columns: [
                    [
                        { text: 'Front', style: 'subtitle', margin: [0, 0, 0, 4] },
                        { image: 'front', fit: [340, 150], alignment: 'center' },
                    ],
                ],
            };
        };

        const generateSideScans = (): Content => {
            const leftSide = this.config.scan_image_urls.left;
            const rightSide = this.config.scan_image_urls.right;

            if (leftSide === '' && rightSide === '') {
                return [];
            }

            if (leftSide === '') {
                return {
                    columns: [
                        [
                            { text: 'Right side', style: 'subtitle' },
                            { image: 'right', fit: [170, 110], alignment: 'center', margin: [0, 20, 0, 0] },
                        ],
                    ],
                    margin: [0, 30, 0, 0],
                };
            }

            if (rightSide === '') {
                return {
                    columns: [
                        [
                            { text: 'Left side', style: 'subtitle' },
                            { image: 'left', fit: [170, 110], alignment: 'center', margin: [0, 20, 0, 0] },
                        ],
                    ],
                    margin: [0, 30, 0, 0],
                };
            }

            return {
                columns: [
                    [
                        { text: 'Right side', style: 'subtitle' },
                        { image: 'right', fit: [170, 110], alignment: 'center', margin: [0, 20, 0, 0] },
                    ],
                    [
                        { text: 'Left side', style: ['subtitle', 'rightAlign'] },
                        { image: 'left', fit: [170, 110], alignment: 'center', margin: [0, 20, 0, 0] },
                    ],
                ],
                margin: [0, 30, 0, 0],
            };
        };

        const generateArchScans = (): Content => {
            const upperArch = this.config.scan_image_urls.upper;
            const lowerArch = this.config.scan_image_urls.lower;

            const hasAnnotationsOrMessage = !!this.config.scan_annotations || !!this.config.doctor_message;

            if (upperArch === '' && lowerArch === '') {
                return [];
            }

            if (upperArch === '') {
                return {
                    columns: [
                        [
                            { text: 'Lower arch', style: 'subtitle', margin: [0, 0, 0, 20] },
                            { image: 'lower', fit: [165, 165], alignment: 'center' },
                        ],
                    ],
                    margin: [0, 30, 0, 0],
                    pageBreak: hasAnnotationsOrMessage ? 'after' : undefined,
                };
            }

            if (lowerArch === '') {
                return {
                    columns: [
                        [
                            { text: 'Upper arch', style: 'subtitle', margin: [0, 0, 0, 20] },
                            { image: 'upper', fit: [165, 165], alignment: 'center' },
                        ],
                    ],
                    margin: [0, 30, 0, 0],
                    pageBreak: hasAnnotationsOrMessage ? 'after' : undefined,
                };
            }

            return {
                columns: [
                    [
                        { text: 'Upper arch', style: 'subtitle', margin: [0, 0, 0, 20] },
                        { image: 'upper', fit: [165, 165], alignment: 'center' },
                    ],
                    [
                        { text: 'Lower arch', style: ['subtitle', 'rightAlign'], margin: [0, 0, 0, 20] },
                        { image: 'lower', fit: [165, 165], alignment: 'center' },
                    ],
                ],
                margin: [0, 30, 0, 0],
                pageBreak: hasAnnotationsOrMessage ? 'after' : undefined,
            };
        };

        return [this.generateHeading('Your scans'), generateFrontScan(), generateSideScans(), generateArchScans()];
    }

    private generateDoctorMessageSection(): Content {
        if (!this.config.doctor_message) {
            return [];
        }

        return [
            this.generateHeading("Dr.'s message"),

            {
                text: this.config.doctor_message,
                style: 'doctorMessage',
            },
        ];
    }

    private generateAnnotationsSection(): Content {
        if (!this.config.scan_annotations) {
            return [];
        }

        return [
            this.generateHeading('Annotations', 20),

            Object.keys(this.config.scan_annotations).map(key => [
                { image: key, fit: [595, 400], alignment: 'center' },
            ]),
        ];
    }

    private generateStyles(): StyleDictionary {
        return {
            practiceName: {
                bold: true,
                fontSize: 10,
                color: FlossPalette.BLACK,
            },
            heading: {
                fontSize: 16,
                color: FlossPalette.BLACK,
                font: 'Bagoss',
            },
            subtitle: {
                color: FlossPalette.GRAY,
                fontSize: 9,
            },
            rightAlign: {
                alignment: 'right',
            },
            doctorMessage: {
                fontSize: 10,
                color: FlossPalette.BLACK,
                lineHeight: 1.3,
            },
        };
    }

    async generatePdf(): Promise<TCreatedPdf> {
        const dd: TDocumentDefinitions = {
            info: {
                title: this.generateDocumentTitle(),
            },
            header: this.generateHeader.bind(this),
            content: [
                this.generateScansSection(),
                this.generateDoctorMessageSection(),
                this.generateAnnotationsSection(),
            ],
            styles: this.generateStyles(),
            images: { ...this.config.scan_image_urls, ...this.config.scan_annotations },
            defaultStyle: {
                font: 'Inter',
            },
            pageMargins: [DEFAULT_PAGE_MARGIN, 190, DEFAULT_PAGE_MARGIN, DEFAULT_PAGE_MARGIN],
        };

        return pdfMake.createPdf(dd);
    }
}
