import { DeleteProductStationUnitMappingDialog } from '../EditDialogs/DeleteProductStationUnitMappingDialog';
import { EditProductStationUnitMappingDialog } from '../EditDialogs/EditProductStationUnitMappingDialog';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlProductStationUnitMappingDto } from '@orthly/graphql-schema';
import { useRootActionCommand } from '@orthly/ui';
import { DeleteIcon, EditIcon, IconButton, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

const EditMapping_Mutation = graphql(`
    mutation EditMapping_Mutation($updateProductStationUnitMappingArgs: UpdateProductStationUnitMappingArgs!) {
        updateProductStationUnitMappingUnits(
            updateProductStationUnitMappingArgs: $updateProductStationUnitMappingArgs
        ) {
            ...ProductStationUnitMappingDTO
        }
    }
`);

const DeleteMapping_Mutation = graphql(`
    mutation DeleteMapping_Mutation($id: String!) {
        deleteProductStationUnitMapping(id: $id) {
            ...ProductStationUnitMappingDTO
        }
    }
`);

export const EditMappingRowActions: React.VFC<{
    row: LabsGqlProductStationUnitMappingDto;
    refetchMappings: () => Promise<unknown>;
}> = ({ row, refetchMappings }) => {
    const editMappingMutation = useMutation(EditMapping_Mutation);
    const {
        submit: editMapping,
        submitting: editingMapping,
        open: openEditMappingDialog,
        setOpen: setOpenEditMappingDialog,
    } = useRootActionCommand(editMappingMutation, {
        onSuccess: async () => {
            await refetchMappings();
        },
        successMessage: 'Product Station Unit Mapping edited!',
    });

    const deleteMapping = useMutation(DeleteMapping_Mutation);
    const {
        submit: submitDeleteMapping,
        submitting: deletingMapping,
        open: openDeleteMappingDialog,
        setOpen: setOpenDeleteMappingDialog,
    } = useRootActionCommand(deleteMapping, {
        onSuccess: async () => {
            await refetchMappings();
        },
        successMessage: 'Product Station Unit Mapping deleted!',
    });

    return (
        <>
            <Tooltip title={'Edit'}>
                <IconButton onClick={() => setOpenEditMappingDialog(true)}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={'Delete'}>
                <IconButton onClick={() => setOpenDeleteMappingDialog(true)}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            <EditProductStationUnitMappingDialog
                open={openEditMappingDialog}
                setOpen={setOpenEditMappingDialog}
                submit={editMapping}
                submitting={editingMapping}
                mapping={row}
            />
            <DeleteProductStationUnitMappingDialog
                open={openDeleteMappingDialog}
                setOpen={setOpenDeleteMappingDialog}
                submit={submitDeleteMapping}
                submitting={deletingMapping}
                mapping={row}
            />
        </>
    );
};
