import { SmileLibraryCreationForm } from './SmileLibraryCreationForm';
import { CadSmileLibraryList_Fragment, SmileLibraryList } from './SmileLibraryList.graphql';
import { useQuery } from '@apollo/client';
import type {
    CadSmileLibraryList_FragmentFragment,
    CadSmileLibraryList_QueryQuery,
} from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { StackY } from '@orthly/ui';
import { Button, Text } from '@orthly/ui-primitives';
import React from 'react';

export const CadSmileLibraryList_Query = graphql(`
    query CadSmileLibraryList_Query {
        getAllCadSmileLibraries {
            ...CadSmileLibraryList_Fragment
        }
    }
`);

export const SmileLibraryManagerLandingPage = () => {
    const { data, refetch } = useQuery<CadSmileLibraryList_QueryQuery>(CadSmileLibraryList_Query, {
        variables: {},
    });

    const existingNames = React.useMemo(() => {
        const names =
            data?.getAllCadSmileLibraries?.map(f => {
                const fragmentData = getFragmentData<CadSmileLibraryList_FragmentFragment>(
                    CadSmileLibraryList_Fragment,
                    f,
                );
                return fragmentData.name;
            }) ?? [];
        return new Set(names);
    }, [data?.getAllCadSmileLibraries]);

    return (
        <StackY sx={{ width: '100%', height: '100vh', overflow: 'hidden', flex: 'auto' }}>
            <Text variant={'h3'}>Smile Libraries</Text>
            <SmileLibraryList
                data={data?.getAllCadSmileLibraries?.map(f =>
                    getFragmentData<CadSmileLibraryList_FragmentFragment>(CadSmileLibraryList_Fragment, f),
                )}
            />
            <SmileLibraryCreationForm existingNames={existingNames} onSuccess={async () => await refetch()}>
                <Button variant={'secondary'}>Update Cache</Button>
            </SmileLibraryCreationForm>
        </StackY>
    );
};
