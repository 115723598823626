import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LoadBlocker } from '@orthly/ui';
import { Button, styled, Text, Alert, Grid } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import dayjs from 'dayjs';
import React from 'react';

const Layout = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    padding: 32,
});

export const GetIteroIntegrationAuthDetails_Query = graphql(`
    query GetIteroIntegrationAuthDetails {
        getIteroIntegrationAuthDetails {
            authorizeUrl
            expiresAt
            isServiceOperational
        }
    }
`);

export const IteroAuthentication: React.VFC = () => {
    const { data, refetch, loading } = useQuery(GetIteroIntegrationAuthDetails_Query, {
        fetchPolicy: 'no-cache',
    });

    const checkWindowClosedRef = React.useRef<ReturnType<typeof setInterval>>();
    const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

    const { value: isIteroAdminToolEnabled } = useFeatureFlag('isIteroAdminToolEnabled');
    const expirationDate = dayjs(data?.getIteroIntegrationAuthDetails?.expiresAt ?? undefined);
    const now = dayjs();
    const isIntegrationActive = expirationDate > now;
    const daysFromNow = expirationDate.diff(now, 'day');
    const shouldShowWarning = daysFromNow < 60;

    const handleAuthenticationClick = () => {
        clearInterval(checkWindowClosedRef.current);

        const oAuthWindow = window.open(
            data?.getIteroIntegrationAuthDetails?.authorizeUrl,
            '_blank',
            `scrollbars=yes, resizable=yes, width=800, height=800`,
        );

        setIsButtonDisabled(true);

        checkWindowClosedRef.current = setInterval(() => {
            if (oAuthWindow?.closed) {
                clearInterval(checkWindowClosedRef.current);
                setIsButtonDisabled(false);

                // When popup closes we refetch the data
                void refetch();
            }
        }, 100);
    };

    React.useEffect(
        () => () => {
            clearInterval(checkWindowClosedRef.current);
        },
        [],
    );

    return (
        <LoadBlocker blocking={loading} loader={'circular'}>
            <Layout>
                <Text variant={'h5'}>Welcome to the iTero Integration Tool.</Text>

                {isIntegrationActive ? (
                    <Grid container direction={'column'} spacing={2}>
                        <Grid item>
                            <Text variant={'h6'} color={'GREEN'}>
                                The Dandy Lab is successfully authenticated to the iTero API.
                            </Text>
                        </Grid>
                        <Grid item>
                            <Text>
                                Authentication will expire in <b>{daysFromNow} days</b> from now (on{' '}
                                {expirationDate.toString()})
                            </Text>
                        </Grid>
                    </Grid>
                ) : (
                    <Text variant={'h6'} color={'ATTENTION'}>
                        The Dandy Lab is not yet authenticated to the iTero API.
                    </Text>
                )}

                {data?.getIteroIntegrationAuthDetails?.isServiceOperational ? (
                    <Text variant={'h6'} color={'GREEN'}>
                        iTero Integration API Status: Operational.
                    </Text>
                ) : (
                    <Text variant={'h6'} color={'ATTENTION'}>
                        iTero Integration API Status: Broken.
                    </Text>
                )}

                {shouldShowWarning && (
                    <Alert severity={'warning'}>
                        Please send a message to #epd-ordering and ask them refresh the iTero integration.
                    </Alert>
                )}

                {isIteroAdminToolEnabled && (
                    <Grid container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item>
                            <Text variant={'body2'}>
                                Click on the following button to launch the login process that will re-authenticate our
                                API integration with iTero.
                            </Text>
                        </Grid>
                        <Grid item>
                            <Button disabled={isButtonDisabled} variant={'primary'} onClick={handleAuthenticationClick}>
                                Authenticate to iTero
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Layout>
        </LoadBlocker>
    );
};

export default IteroAuthentication;
