import { OrderListItemCellText } from '../../../../components/OrderListItem/OrderListItemCellText';
import { DesignTaskListItemTrackerCell } from '../../../../components/OrderListItem/OrderListItemCells';
import { OrderListItemLayout } from '../../../../components/OrderListItem/OrderListItemLayout';
import { OrderListItemTitle } from '../../../../components/OrderListItem/OrderListItemTitle';
import { workflowTaskItemLabel } from '../../state/Inbox.selectors';
import { CompletedInboxDesignListItemFeedback } from './CompletedInboxDesignListItemFeedback.graphql';
import { InboxListItemActions } from './InboxListItemActions';
import type { LabsGqlWorkflowTaskFragment } from '@orthly/graphql-operations';
import type { LabsGqlActiveInternalDesignTaskSummary } from '@orthly/graphql-schema';
import { ToothUtils, CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import type { IOrderItemV2DTO } from '@orthly/items';
import { stylesFactory, Text, FlossPalette, Grid } from '@orthly/ui-primitives';
import type { OrderOpsListItemContentProps } from '@orthly/veneer';
import {
    AssignTaskButton,
    PatientOrderCount,
    useDesignTaskTimeRemainingText,
    useDesignTaskMatchPendingText,
    OrderListItemFlags,
    getTaskButtonTaskFromLegacyFragment,
} from '@orthly/veneer';
import _ from 'lodash';
import type { MouseEventHandler } from 'react';
import React from 'react';

const useStyles = stylesFactory(() => ({
    rootGrid: {
        '& $button': { visibility: `hidden` },
        '&:hover $button': { visibility: `visible` },
    },
    button: {},
}));

function formatToSkuRows(items: IOrderItemV2DTO[]): string[] {
    const byDisplayName = _.groupBy(items, i => {
        const material = CartItemV2Utils.getItemDisplayMaterial(i);
        const displayName = CartItemV2Utils.getDisplayName(i);
        return material ? `${displayName} - ${material}` : displayName;
    });
    return Object.entries(byDisplayName).map(([name, items]) => {
        const unns = CartItemV2Utils.getItemGroupUniqueUNNs(items);
        if (unns.length === 0) {
            return name;
        }
        return `${name} (#${CartItemV2Utils.getUnnsDisplay(unns, 3)})`;
    });
}

const Circle: React.FC<{ color: string }> = ({ color: backgroundColor }) => {
    const size = 5;
    return <div style={{ backgroundColor, width: size, height: size, borderRadius: '50%' }} />;
};

const OrderTag: React.FC<{ text: string; color: string }> = ({ text, color }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Circle color={color} />
            <span style={{ marginLeft: 4, marginRight: 12, color: '#7E7C78', fontWeight: 500, fontSize: '14px' }}>
                {text}
            </span>
        </div>
    );
};

interface InboxDesignListItemProps {
    task: LabsGqlWorkflowTaskFragment;
    listItemContent: OrderOpsListItemContentProps;
    updatedSinceLastView?: boolean;
    onClick: MouseEventHandler;
    refetch: () => Promise<any>;
    dateMessage: string;
    activeInternalDesignTask?: LabsGqlActiveInternalDesignTaskSummary;
}

export const InboxDesignListItem: React.FC<InboxDesignListItemProps> = props => {
    const { listItemContent, task, updatedSinceLastView, onClick, refetch, dateMessage, activeInternalDesignTask } =
        props;
    const { patient, patient_order_count, items_v2: raw_items_v2 } = listItemContent.order;
    const classes = useStyles();

    const { skuRows, hasAnterior, hasPosterior } = React.useMemo(() => {
        const items = OrderItemV2Utils.parseItems(raw_items_v2);
        const unns = CartItemV2Utils.getItemGroupUniqueUNNs(items);
        return {
            skuRows: formatToSkuRows(items),
            hasAnterior: ToothUtils.checkGroupMembership('Anterior', unns),
            hasPosterior: ToothUtils.checkGroupMembership('Posterior', unns),
        };
    }, [raw_items_v2]);

    const designCapabilityRequirements = activeInternalDesignTask?.requirements.map(r => r.name) ?? [];
    const designDueText = useDesignTaskTimeRemainingText(task);
    const pendingAssignmentText = useDesignTaskMatchPendingText(activeInternalDesignTask);
    // We either show info about the design due date if the task is assigned
    // or info about why the task is not yet assigned
    const assignmentInfo = designDueText ?? pendingAssignmentText;

    const isTaskCompleted = !!task.closeout;

    return (
        <OrderListItemLayout
            rowTitle={`Order Id: ${listItemContent.order.id}`}
            started_at={task.started_at}
            closeout={task.closeout}
            updatedSinceLastView={updatedSinceLastView}
            onClick={onClick}
            className={classes.rootGrid}
            middleCellTwoWidth={3}
            middleCellThreeWidth={1}
            title={
                <>
                    <Grid container item direction={`row`} spacing={2}>
                        <Grid item style={{ flexGrow: 1 }}>
                            <OrderListItemCellText medium variant={'title'} text={workflowTaskItemLabel(task)} />
                        </Grid>
                        {!isTaskCompleted && (
                            <Grid item style={{ padding: 4 }}>
                                <AssignTaskButton task={getTaskButtonTaskFromLegacyFragment(task)} refetch={refetch} />
                            </Grid>
                        )}
                    </Grid>
                    <OrderListItemCellText variant={'subtitle'} text={dateMessage} />
                    {isTaskCompleted ? (
                        <CompletedInboxDesignListItemFeedback task={task} listItemContent={listItemContent} />
                    ) : (
                        assignmentInfo && <OrderListItemCellText variant={'subtitle'} text={assignmentInfo} />
                    )}
                </>
            }
            middleCellOne={
                <OrderListItemTitle
                    disableSearchOnClick
                    patient_name={`${patient.first_name} ${patient.last_name}`}
                    flagContent={
                        <OrderListItemFlags
                            flags={listItemContent.flags}
                            review_flag_reasons={listItemContent.review_flag_reasons}
                        />
                    }
                    patient_order_count={patient_order_count}
                />
            }
            middleCellTwo={
                <Grid container direction={'column'}>
                    <Grid container style={{ flexWrap: 'nowrap', alignItems: 'center' }}>
                        <PatientOrderCount patient_order_count={raw_items_v2.length} />
                        <OrderListItemCellText variant={'title'} text={skuRows.join(', ')} />
                    </Grid>

                    <Grid container>
                        {hasAnterior ? <OrderTag text={'Anterior'} color={'#9DB1E8'} /> : null}
                        {hasPosterior ? <OrderTag text={'Posterior'} color={'#9DE8C7'} /> : null}
                    </Grid>

                    <Grid container>
                        {designCapabilityRequirements.length > 0 ? (
                            <OrderTag text={designCapabilityRequirements.join(', ')} color={FlossPalette.DARK_TAN} />
                        ) : null}
                    </Grid>
                </Grid>
            }
            middleCellThree={
                <Text
                    style={{ marginLeft: 'auto', textAlign: 'right', paddingTop: '4px' }}
                    variant={'caption'}
                    color={'DARK_GRAY'}
                >
                    {listItemContent.manufacturer_name}
                </Text>
            }
            trackerCell={
                <DesignTaskListItemTrackerCell
                    actions={<InboxListItemActions buttonClass={classes.button} task={task} />}
                    task={task}
                    order={listItemContent.order}
                    state={listItemContent.trackerProps.state}
                    hasIssue={listItemContent.trackerProps.hasIssue}
                    containerStyle={listItemContent.trackerProps.containerStyle}
                    assignmentInfo={assignmentInfo}
                />
            }
        />
    );
};
