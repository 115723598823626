import type { LabsGqlWorkflowTaskCloseoutFragment } from '@orthly/graphql-operations';
import type { GridSize } from '@orthly/ui-primitives';
import { stylesFactory, FlossPalette, Grid, Tooltip, Icon } from '@orthly/ui-primitives';
import clsx from 'clsx';
import moment from 'moment';
import type { MouseEventHandler } from 'react';
import React from 'react';

export const ORDER_LIST_ITEM_HEIGHT = 120;

const borderStyle = `1px solid ${FlossPalette.DARK_TAN}`;

const useStyles = stylesFactory(() => ({
    root: { padding: '0 32px', height: ORDER_LIST_ITEM_HEIGHT, backgroundColor: '#fff' },
    rootClickable: { cursor: 'pointer', '&:hover': { background: FlossPalette.TAN } },
    contentRoot: { borderBottom: borderStyle, height: '100%' },
    cell: {
        padding: '12px 8px 8px',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        height: '100%',
        position: 'relative',
    },
    titleCell: {
        borderRight: borderStyle,
        overflowY: 'scroll',
        maxWidth: 'calc(16.666667% + 8px)',
        flexBasis: 'calc(16.666667% + 8px)',
        marginLeft: -8,
        paddingTop: 8,
        '&::-webkit-scrollbar': { display: 'none' },
    },
}));

const UpdatedDot: React.FC = () => {
    return (
        <div
            style={{
                position: 'absolute',
                left: 18,
                top: 22,
                backgroundColor: FlossPalette.BURGUNDY,
                width: 6,
                height: 6,
                borderRadius: 6,
            }}
        />
    );
};

const StartedDot: React.FC<{ started_at: string }> = ({ started_at }) => {
    return (
        <Tooltip title={`Task started at ${moment(started_at).format(`MM/DD/YY, h:mm a`)}`}>
            <Icon
                icon={'PlayCircleFilledIcon'}
                style={{
                    color: FlossPalette.GRAY,
                    position: 'absolute',
                    left: 10,
                    top: 13,
                    width: 16,
                    height: 16,
                }}
            />
        </Tooltip>
    );
};

interface OrderListItemLayoutProps {
    started_at?: string | null;
    closeout?: LabsGqlWorkflowTaskCloseoutFragment | null;
    title: React.ReactNode;
    rowTitle: string;
    middleCellOne: React.ReactNode;
    middleCellTwo: React.ReactNode;
    middleCellTwoWidth?: GridSize;
    middleCellThree?: React.ReactNode;
    middleCellThreeWidth?: GridSize;
    trackerCell: React.ReactNode;
    onClick?: MouseEventHandler;
    updatedSinceLastView?: boolean;
    className?: string;
}

export const OrderListItemLayout: React.FC<OrderListItemLayoutProps> = props => {
    const { onClick, middleCellTwoWidth, middleCellThreeWidth } = props;
    const classes = useStyles();
    return (
        <Grid
            role={'row'}
            container
            title={props.rowTitle}
            className={clsx(classes.root, !!onClick && classes.rootClickable, props.className)}
        >
            {props.started_at && !props.closeout && <StartedDot started_at={props.started_at} />}
            {(!props.started_at || props.closeout) && props.updatedSinceLastView && <UpdatedDot />}
            <Grid container className={classes.contentRoot}>
                {/*
                    This data-testid is a hack around EPDPLT-3848.
                    We are using the row title to identify the order id.
                    Once we resolve the race condition in our firebase authentication, we can remove this data-testid.
                */}
                <Grid
                    role={'cell'}
                    container
                    data-testid={props.rowTitle}
                    item
                    xs={12}
                    sm={2}
                    className={clsx(classes.titleCell, classes.cell)}
                    onClick={onClick}
                    onAuxClick={onClick}
                >
                    {props.title}
                </Grid>
                <Grid
                    role={'cell'}
                    container
                    item
                    xs={6}
                    sm={2}
                    className={classes.cell}
                    onClick={onClick}
                    onAuxClick={onClick}
                >
                    {props.middleCellOne}
                </Grid>
                <Grid
                    role={'cell'}
                    container
                    item
                    style={{ overflowY: 'auto' }}
                    xs={6}
                    sm={middleCellTwoWidth ?? (props.middleCellThree !== undefined ? 2 : 4)}
                    className={classes.cell}
                    onClick={onClick}
                    onAuxClick={onClick}
                >
                    {props.middleCellTwo}
                </Grid>
                {props.middleCellThree !== undefined && (
                    <Grid
                        role={'cell'}
                        container
                        item
                        xs={6}
                        sm={middleCellThreeWidth ?? 2}
                        className={classes.cell}
                        onClick={onClick}
                        onAuxClick={onClick}
                    >
                        {props.middleCellThree}
                    </Grid>
                )}
                <Grid role={'cell'} container item xs={12} sm={4} className={classes.cell}>
                    {props.trackerCell}
                </Grid>
            </Grid>
        </Grid>
    );
};

const ROOT_STYLE: React.CSSProperties = {
    width: '100%',
    transform: 'unset',
    height: 16,
    background: FlossPalette.DARK_TAN,
};

export const OrderListItemMiddleCellLoading: React.FC = () => (
    <>
        <div style={{ ...ROOT_STYLE, width: '75%', marginBottom: 8 }} />
        <div style={{ ...ROOT_STYLE, width: '50%' }} />
    </>
);

export const OrderListItemTitleCellLoading: React.FC = () => (
    <div style={{ ...ROOT_STYLE, paddingTop: 12, height: 24 }} />
);

export const OrderListItemTrackerCellLoading: React.FC = () => <div style={{ ...ROOT_STYLE, height: '75%' }} />;

export const OrderListItemLoading: React.FC<{ columns: 4 | 5 }> = props => (
    <OrderListItemLayout
        rowTitle={`Order Id: Loading`}
        title={<OrderListItemTitleCellLoading />}
        middleCellOne={<OrderListItemMiddleCellLoading />}
        middleCellTwo={<OrderListItemMiddleCellLoading />}
        middleCellThree={props.columns === 5 ? <OrderListItemMiddleCellLoading /> : undefined}
        trackerCell={<OrderListItemTrackerCellLoading />}
    />
);
