import { type CapacityStationDtoFragment } from '@orthly/graphql-inline-react';
import type { LabsGqlCreateProductStationUnitMappingArgs } from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

type ProductStationUnitMappingFormFields = Pick<
    LabsGqlCreateProductStationUnitMappingArgs,
    'product_header' | 'station_id' | 'units'
>;

const INITIAL_VALUES: ProductStationUnitMappingFormFields = {
    product_header: ``,
    station_id: ``,
    units: 0,
};

interface ProductStationUnitMappingFormProps {
    loading: boolean;
    stations: CapacityStationDtoFragment[];
    onSubmit: (result: ProductStationUnitMappingFormFields) => void;
    initialValues?: ProductStationUnitMappingFormFields;
}

export const CreateProductStationUnitMappingForm: React.VFC<ProductStationUnitMappingFormProps> = ({
    stations,
    onSubmit,
    initialValues,
}) => {
    return (
        <QuickForm<ProductStationUnitMappingFormFields>
            onSubmit={onSubmit}
            initialValues={initialValues ?? INITIAL_VALUES}
            fields={{
                product_header: {
                    label: 'Product Header',
                    type: 'text',
                },
                station_id: {
                    label: 'Station',
                    type: 'select',
                    options: _.sortBy(stations, m => m.name.trim().toLowerCase()).map(({ id, name, unit_type }) => ({
                        value: id,
                        label: `${name} | ${unit_type}`,
                    })),
                },
                units: {
                    label: 'Units',
                    type: 'number',
                },
            }}
        />
    );
};
