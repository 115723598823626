/* eslint-disable max-lines-per-function */
import { RefabAnnotationsModal } from './RefabAnnotationsModal';
import { useQuery } from '@apollo/client';
import type { RefabAnnotation, RefabFlowLabOrder, ReturnedItemsInfo } from '@orthly/dentin';
import { RefabAcknowledgeReturn, useRefabFlowAction, useRefabFlowSelector } from '@orthly/dentin';
import { graphql } from '@orthly/graphql-inline-react';
import { Text, stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    columnsWrapper: {
        margin: `12px 0 0`,
    },
    column: {
        marginBottom: 0,
        paddingBottom: 0,
        '&:first-child': {
            marginLeft: 0,
            paddingLeft: 0,
        },
        '&:last-child': {
            marginRight: 0,
            paddingRight: 0,
        },
        width: `100%`,
    },
}));

export const VeneerRefabSupplementalInfoStepTwoDesignRevision_Query = graphql(`
    query VeneerRefabSupplementalInfoStepTwoDesignRevision_Query($orderId: String!) {
        getLatestDesignOrderDesignRevision(labOrderId: $orderId) {
            ...OrderDesignPreviewDesign_Fragment
        }
    }
`);

export const RefabSupplementalInfoStepTwoGraphql: React.VFC<{
    order?: RefabFlowLabOrder;
    itemsToReturn: ReturnedItemsInfo[];
    additionalInfoRequests: string[];
    hasAckedReturnPolicy: boolean;
    setHasAckedReturnPolicy: (hasAcked: boolean) => void;
}> = ({ order, itemsToReturn, hasAckedReturnPolicy, setHasAckedReturnPolicy }) => {
    const classes = useStyles();
    const [isAnnotatorOpen, setIsAnnotatorOpen] = React.useState(false);
    const patchState = useRefabFlowAction('PATCH_STATE');
    const [isAnnotationListModalOpen, setIsAnnotationListModalOpen] = React.useState(false);
    const annotatorNotes = React.useRef('');
    const annotations = useRefabFlowSelector(s => s.annotations);

    const setAnnotations = (annotations: RefabAnnotation[]) => {
        patchState({ annotations });
    };

    const { data: designRevisionQueryData, refetch: refetchDesign } = useQuery(
        VeneerRefabSupplementalInfoStepTwoDesignRevision_Query,
        {
            variables: {
                orderId: order?.id ?? '',
            },
            skip: !order,
        },
    );
    const latestDesignFragment = designRevisionQueryData?.getLatestDesignOrderDesignRevision;

    if (!order) {
        return null;
    }
    return (
        <>
            <Grid container className={classes.columnsWrapper} spacing={4}>
                <Grid item sm={12} md={10} className={classes.column}>
                    <Text medium variant={'body1'}>
                        Markup Design Preview
                    </Text>
                    <RefabAnnotationsModal
                        order={order}
                        refetchDesign={refetchDesign}
                        latestDesignFragment={latestDesignFragment ?? undefined}
                        annotations={annotations}
                        setAnnotations={setAnnotations}
                        annotatorNotes={annotatorNotes}
                        isAnnotatorOpen={isAnnotatorOpen}
                        setIsAnnotatorOpen={setIsAnnotatorOpen}
                        isAnnotationListModalOpen={isAnnotationListModalOpen}
                        setIsAnnotationListModalOpen={setIsAnnotationListModalOpen}
                    />
                </Grid>
            </Grid>
            {itemsToReturn.length > 0 && (
                <RefabAcknowledgeReturn
                    hasAcked={hasAckedReturnPolicy}
                    setHasHacked={setHasAckedReturnPolicy}
                    itemsToReturn={itemsToReturn}
                />
            )}
        </>
    );
};
