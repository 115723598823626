/* eslint-disable no-nested-ternary */
import { useAdminUiSelector } from '../../redux/ui/ui.state';
import { AnalyticsClient } from '../../utils/analyticsClient';
import { RouterUtils } from '../../utils/router/RouterUtils';
import type { PartnerNameFragment } from '../../utils/usePartners';
import { usePartnersData } from '../../utils/usePartners';
import { failsSearch } from '../Impersonate/GlobalImpersonate';
import { PalatePracticeRoot } from './PalatePracticeRoot';
import type { PracticeData } from './PalatePracticeRoot.types';
import { PracticeOverview } from './components/PracticeOverview';
import { usePalateAction } from './state/Palate.actions';
import { useRootStyles, CONTAINER_WIDTH } from './usePalateStyles';
import { ChatItemSkeleton } from '@orthly/dentin';
import { useGetPracticeDetailsLazyQuery } from '@orthly/graphql-react';
import { Format } from '@orthly/runtime-utils';
import type { SimpleAutocompleteOption } from '@orthly/ui';
import { useBeforeUnload } from '@orthly/ui';
import { FlossPalette, Text, FlossPaletteUtils, Collapse, Grid } from '@orthly/ui-primitives';
import { SpotlightSearchItem, SpotlightSearchAutocomplete } from '@orthly/veneer';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

interface PracticeOpt extends SimpleAutocompleteOption {
    label: string;
    practiceId: string;
}

function usePartnerOptions(search?: string, selectedOrganizationId?: string) {
    const { data: rawPartners, loading } = usePartnersData();
    const options = React.useMemo(() => {
        const partners: PartnerNameFragment[] = rawPartners?.listPracticeNames || [];
        return partners.flatMap<PracticeOpt>(partner => {
            const label = partner.name;
            const subtitle = partner.primary_contact_email ?? undefined;
            return failsSearch(partner.id, label, subtitle, search) && selectedOrganizationId !== partner.id
                ? []
                : {
                      subtitle,
                      label,
                      practiceId: partner.id,
                      value: partner.id,
                  };
        });
    }, [rawPartners, search, selectedOrganizationId]);
    return { options, loading };
}

function usePracticeOptions(practiceId?: string) {
    const history = useHistory();
    const [search, setSearch] = React.useState<string | undefined>();
    const [practiceData, setPracticeData] = React.useState<PracticeData | undefined>();
    const resetCriteria = usePalateAction('CLEAR_ORDER_FILTERS');
    const [triggerQuery, { loading: detailsLoading }] = useGetPracticeDetailsLazyQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        onCompleted: data => {
            const organization_id = practiceData?.organization_id;
            if (organization_id && data) {
                setPracticeData({
                    organization_id,
                    data: data.getPracticeDetails,
                });
            }
        },
    });
    const onClickRow = React.useCallback(
        (organization_id: string) => {
            setPracticeData({ organization_id });
            triggerQuery({ variables: { practice_id: organization_id } });
        },
        [triggerQuery],
    );
    React.useEffect(() => {
        if (practiceId) {
            onClickRow(practiceId);
        }
    }, [onClickRow, practiceId, triggerQuery]);
    const { options: practiceOptions, loading: loadingPartners } = usePartnerOptions(
        search,
        practiceData?.organization_id,
    );
    return {
        practiceData,
        search,
        setSearch,
        onClickRow,
        practiceOptions,
        detailsLoading,
        onReset: () => {
            setSearch(undefined);
            setPracticeData(undefined);
            resetCriteria();
            if (practiceId) {
                // if the practice id was sent in the url, reset the url to remove the id in case the user refreshes the page
                history.push(RouterUtils.pathForScreen('palate'));
            }
        },
        loading: loadingPartners,
    };
}

const PracticeAutocompleteItem: React.FC<PracticeOpt> = props => {
    const { label, practiceId } = props;
    return (
        <SpotlightSearchItem
            key={practiceId}
            title={label}
            titleVariant={'body2'}
            boldTitle
            lightPadding
            details={<PracticeOverview practiceId={practiceId} />}
        />
    );
};

const PracticeAutocompleteItemWrapper = (listProps: React.HTMLAttributes<HTMLLIElement>, props: PracticeOpt) => {
    const searchItem = <PracticeAutocompleteItem {...props} />;
    return (
        <li {...listProps} key={props.value}>
            {searchItem}
        </li>
    );
};

const PalateSearch: React.FC<RouteComponentProps<{ practiceId?: string }>> = props => {
    const classes = useRootStyles();
    // track palate open time from the timestamp of first render, and never update
    const [palateOpenTime] = React.useState(Date.now());
    const { practiceOptions, search, setSearch, loading, onClickRow, practiceData, onReset, detailsLoading } =
        usePracticeOptions(props.match.params.practiceId);
    const AutocompleteOverride = practiceData ? (
        <PalatePracticeRoot practiceData={practiceData} onReset={onReset} loading={detailsLoading} />
    ) : undefined;

    // when Palate is closed, make a call to trackTimeOnPalate with Date.now() as the end time
    useBeforeUnload(_event => {
        AnalyticsClient.track('Palate - App Closed', {
            timeElapsedSeconds: (Date.now() - palateOpenTime) / 1000,
        });
    });

    React.useEffect(() => {
        const WAIT_FOR_USER_TO_STOP_TYPING = 2000; // wait for 2 seconds after no results are found before believing user is done typing

        // set a timeout so we don't immediately trigger when a user types 1 character into search, which creates 0 results
        const timer = setTimeout(() => {
            if (practiceOptions.length === 0) {
                AnalyticsClient.track('Palate - No Practices Found', { search: search ?? '' });
            } else {
                AnalyticsClient.track('Palate - Practices Found', { search: search ?? '' });
            }
        }, WAIT_FOR_USER_TO_STOP_TYPING);

        return () => {
            clearTimeout(timer);
        };
    }, [practiceOptions, search]);

    const history = useAdminUiSelector(s => s.palateVisitedPractices);
    const historyOptions = history.map<PracticeOpt>(item => ({
        label: item.name,
        value: item.id,
        practiceId: item.id,
    }));

    const showPracticeOptions = !!search && search.length >= 1;

    return (
        <Grid container>
            <Collapse in={!!AutocompleteOverride} style={{ width: '100%' }}>
                {AutocompleteOverride ?? null}
            </Collapse>
            <Collapse in={!AutocompleteOverride} style={{ width: '100%' }} unmountOnExit>
                <Grid style={{ width: '100%', padding: 16 }} className={classes.container}>
                    <SpotlightSearchAutocomplete
                        onSelectOption={(value: string) => onClickRow(value)}
                        placeholder={'Search by practice name or ID'}
                        loading={loading}
                        setSearch={setSearch}
                        search={search}
                        options={showPracticeOptions ? practiceOptions : historyOptions}
                        renderOption={PracticeAutocompleteItemWrapper}
                        AutocompleteOverride={AutocompleteOverride}
                        onClose={() => {}}
                        palate
                        open
                    />
                </Grid>
                {!showPracticeOptions && historyOptions.length === 0 ? (
                    <Grid container alignItems={'center'} style={{ height: '60vh' }}>
                        <Grid container style={{ padding: '0 32px' }} justifyContent={'center'}>
                            <Grid container direction={'column'} style={{ width: CONTAINER_WIDTH }}>
                                <ChatItemSkeleton style={{ color: FlossPalette.TAN }} />
                                <ChatItemSkeleton
                                    style={{ color: FlossPaletteUtils.toRgba('LIGHT_GRAY', 0.4), marginTop: 16 }}
                                />
                            </Grid>
                            <Grid container direction={'column'} alignItems={'center'}>
                                <Text variant={'h5'} medium>
                                    Get started by looking up practices
                                </Text>
                                <Text variant={'body2'} style={{ color: FlossPalette.GRAY, marginTop: 8 }}>
                                    All your previous searches will appear on this page.
                                </Text>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container style={{ paddingTop: 16, paddingLeft: 16 }} direction={'column'}>
                        <Text color={'DARK_GRAY'} variant={'caption'}>
                            {practiceOptions.length === 0
                                ? loading
                                    ? 'Loading...'
                                    : `No practices were found with "${search}"`
                                : showPracticeOptions
                                  ? Format.pluralize('Result', practiceOptions.length)
                                  : 'Previously viewed practices'}
                        </Text>
                        {practiceOptions.length === 0 && !loading && (
                            <Text variant={'caption'} medium style={{ marginTop: 8 }}>
                                Try searching for a different practice instead!
                            </Text>
                        )}
                    </Grid>
                )}
            </Collapse>
        </Grid>
    );
};

export const PalateRoot: React.FC = () => {
    const basePath = RouterUtils.pathForScreen('palate');
    return (
        <Switch>
            <Route path={[`${basePath}/:practiceId`, `${basePath}`]} component={PalateSearch} />
            <Redirect to={basePath} />
        </Switch>
    );
};
