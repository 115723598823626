import { EditStationDialog } from '../EditDialogs/EditStationDialog';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlCapacityStationDto } from '@orthly/graphql-schema';
import { useRootActionCommand } from '@orthly/ui';
import { EditIcon, IconButton, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

const EditStation_Mutation = graphql(`
    mutation EditStation_Mutation($updateCapacityStationArgs: UpdateCapacityStationArgs!) {
        updateCapacityStation(updateCapacityStationArgs: $updateCapacityStationArgs) {
            ...CapacityStationDTO
        }
    }
`);

export const EditStationRowActions: React.VFC<{
    row: LabsGqlCapacityStationDto;
    refetchStations: () => Promise<unknown>;
}> = ({ row, refetchStations }) => {
    const editStationMutation = useMutation(EditStation_Mutation);
    const {
        submit: editStation,
        submitting: editingStation,
        open: openEditStationDialog,
        setOpen: setOpenEditStationDialog,
    } = useRootActionCommand(editStationMutation, {
        onSuccess: async () => {
            await refetchStations();
        },
        successMessage: 'Product Station edited!',
    });

    return (
        <>
            <Tooltip title={'Edit'}>
                <IconButton onClick={() => setOpenEditStationDialog(true)}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <EditStationDialog
                open={openEditStationDialog}
                setOpen={setOpenEditStationDialog}
                submit={editStation}
                submitting={editingStation}
                station={row}
            />
        </>
    );
};
