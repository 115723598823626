import { OrderListItemCellText } from '../../components/OrderListItem/OrderListItemCellText';
import { OrderListItemPartnerCell, OrderListItemItemsLabCell } from '../../components/OrderListItem/OrderListItemCells';
import {
    OrderListItemLoading,
    OrderListItemLayout,
    OrderListItemTitleCellLoading,
    OrderListItemMiddleCellLoading,
    ORDER_LIST_ITEM_HEIGHT,
} from '../../components/OrderListItem/OrderListItemLayout';
import { OrderListItemTitle } from '../../components/OrderListItem/OrderListItemTitle';
import { RouterUtils } from '../../utils/router/RouterUtils';
import { LabsUtilsBase } from '@orthly/dentin';
import type { LabsGqlDesignQaEvaluationDtoWithOrderDesignFragment } from '@orthly/graphql-operations';
import { useGetMyPendingDesignQaEvaluationsQuery, useCancelDesignQaEvaluationsMutation } from '@orthly/graphql-react';
import { LabsGqlOrderDesignType } from '@orthly/graphql-schema';
import { OrderItemV2Utils } from '@orthly/items';
import { useRootActionCommand, Icon } from '@orthly/ui';
import { stylesFactory, Grid, IconButton } from '@orthly/ui-primitives';
import { useListDisplayState } from '@orthly/veneer';
import _ from 'lodash';
import moment from 'moment/moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import type { FixedSizeListProps, ListChildComponentProps, ListOnItemsRenderedProps } from 'react-window';
import { FixedSizeList as List } from 'react-window';

const LIST_ITEM_HEIGHT = 100;

const useStyles = stylesFactory(() => ({
    rootGrid: {
        '& $button': { visibility: `hidden` },
        '&:hover $button': { visibility: `visible` },
    },
    button: {},
}));

interface UseVirtualizedListEvaluationsRes<ListItemContent> {
    onItemsRendered: (args: ListOnItemsRenderedProps) => void;
    evaluations: (LabsGqlDesignQaEvaluationDtoWithOrderDesignFragment | undefined)[];
    listHeight: number;
    ids: string[];
    startIndex: number;
    evaluationToListItemContent: (evaluation: LabsGqlDesignQaEvaluationDtoWithOrderDesignFragment) => ListItemContent;
    idsLoading: boolean;
    style?: React.CSSProperties;
    refetch: () => Promise<unknown>;
}

interface EvaluationsVirtualizedListData<ListItemContent> {
    startIndex: number;
    evaluations: (LabsGqlDesignQaEvaluationDtoWithOrderDesignFragment | undefined)[];
    evaluationToListItemContent: (evaluation: LabsGqlDesignQaEvaluationDtoWithOrderDesignFragment) => ListItemContent;
    refetch: () => Promise<unknown>;
    ListItem: React.ComponentType<{
        evaluation?: ListItemContent;
        refetch: () => Promise<unknown>;
    }>;
}

interface EvaluationsVirtualListProps<ListItemContent> extends UseVirtualizedListEvaluationsRes<ListItemContent> {
    listItemHeight: number;
    ListItem: React.ComponentType<{ evaluation?: ListItemContent; refetch: () => Promise<unknown> }>;
}

interface EvaluationsVirtualizedListItemProps<ListItemContent = unknown> extends ListChildComponentProps {
    data: EvaluationsVirtualizedListData<ListItemContent>;
}

const OVERSCAN_COUNT = 10;

function evaluationFromProps({ index, data: { evaluations, startIndex } }: EvaluationsVirtualizedListItemProps) {
    return evaluations[index - startIndex];
}

function orderListItemPropsAreEqual(
    prev: EvaluationsVirtualizedListItemProps,
    next: EvaluationsVirtualizedListItemProps,
): boolean {
    // if the top level list props have changed we need to re-render
    if (!_.isEqual(prev.style, next.style) || prev.index !== next.index) {
        return false;
    }
    // if the component or callback have changed we need to re-render
    if (
        prev.data.evaluationToListItemContent !== next.data.evaluationToListItemContent ||
        prev.data.ListItem !== next.data.ListItem
    ) {
        return false;
    }
    const prevOrder = evaluationFromProps(prev);
    const nextOrder = evaluationFromProps(next);
    // for the order itself we only need to ensure its the same id and version (via updated_at)
    return prevOrder?.id === nextOrder?.id && prevOrder?.updated_at === nextOrder?.updated_at;
}

interface EvaluationVirtualizedListItemProps<ListItemContent> extends ListChildComponentProps {
    data: EvaluationsVirtualizedListData<ListItemContent>;
}

const EvaluationsVirtualizedListItem = React.memo<EvaluationVirtualizedListItemProps<unknown>>(props => {
    const order = evaluationFromProps(props);
    const { ListItem, evaluationToListItemContent, refetch } = props.data;
    return (
        <div style={props.style}>
            <ListItem evaluation={order ? evaluationToListItemContent(order) : undefined} refetch={refetch} />
        </div>
    );
}, orderListItemPropsAreEqual);

function useInitialScrollOffset(startIndex: number, listItemHeight: number): number {
    // use a ref to make this stable
    const initialScrollOffsetRef = React.useRef(Math.max(startIndex * listItemHeight - listItemHeight, 0));
    return initialScrollOffsetRef.current;
}

type EvaluationsVirtualizedListProps<ListItemContent> = Omit<FixedSizeListProps, 'itemData'> & {
    itemData: EvaluationsVirtualizedListData<ListItemContent>;
};
const EvaluationsVirtualizedList = List as React.ComponentType<EvaluationsVirtualizedListProps<any>>;

interface UseVirtualizedListItemsProps<ListItemContent> {
    itemHeight: number;
    initialFocusedId?: string;
    idsLoading: boolean;
    evaluationToListItemContent: (order: LabsGqlDesignQaEvaluationDtoWithOrderDesignFragment) => ListItemContent;
    toolbarHeight?: number;
}

function useVirtualizedListEvaluations<ListItemContent>(
    props: UseVirtualizedListItemsProps<ListItemContent>,
): UseVirtualizedListEvaluationsRes<ListItemContent> {
    const { idsLoading, itemHeight, evaluationToListItemContent, toolbarHeight } = props;
    const { listHeight } = useListDisplayState(itemHeight, toolbarHeight);
    const { data: getEvaluationsData, refetch } = useGetMyPendingDesignQaEvaluationsQuery({
        variables: {},
    });
    const evaluations = getEvaluationsData?.getMyPendingDesignQaEvaluations ?? [];

    return {
        idsLoading,
        refetch,
        evaluations,
        listHeight,
        evaluationToListItemContent,
        onItemsRendered: () => {},
        startIndex: 0,
        ids: evaluations.map(e => e.id),
    };
}

export const EvaluationsVirtualList = <ListItemContent extends unknown>(
    props: EvaluationsVirtualListProps<ListItemContent>,
) => {
    const {
        onItemsRendered,
        evaluations,
        listHeight,
        ids,
        startIndex,
        listItemHeight,
        evaluationToListItemContent,
        style,
        refetch,
    } = props;
    const initialScrollOffset = useInitialScrollOffset(startIndex, listItemHeight);
    const itemKeyCb = React.useCallback((index: number) => ids[index] ?? index, [ids]);
    return (
        <EvaluationsVirtualizedList
            itemKey={itemKeyCb}
            overscanCount={OVERSCAN_COUNT}
            height={listHeight}
            itemCount={ids.length}
            initialScrollOffset={initialScrollOffset}
            itemData={{ startIndex, evaluations, evaluationToListItemContent, refetch, ListItem: props.ListItem }}
            width={'100%'}
            itemSize={props.listItemHeight}
            onItemsRendered={onItemsRendered}
            style={style}
        >
            {EvaluationsVirtualizedListItem}
        </EvaluationsVirtualizedList>
    );
};

interface InboxListItemProps {
    evaluation?: LabsGqlDesignQaEvaluationDtoWithOrderDesignFragment;
    refetch: () => Promise<unknown>;
}

const EvaluationDesignTypeMap: Record<LabsGqlOrderDesignType, string> = {
    [LabsGqlOrderDesignType.FinishedDesign]: 'Finishing Design',
    [LabsGqlOrderDesignType.FinishedDesignWithModel]: 'Model Design',
    [LabsGqlOrderDesignType.Prep]: 'Prep Design',
};

const InboxListItemContent = React.memo<InboxListItemProps>(props => {
    const history = useHistory();
    const classes = useStyles();
    const { evaluation } = props;

    const { submit: deleteSubmit, submitting: deletionSubmitting } = useRootActionCommand(
        useCancelDesignQaEvaluationsMutation(),
        {
            successMessage: 'QA Evaluation deleted!',
            onSuccess: async () => {
                await props.refetch();
            },
        },
    );

    const onClick = React.useCallback(
        e => {
            // button 1 is the middle mouse button.
            const openInNewWindow = e?.metaKey || e?.ctrlKey || e?.button === 1;
            const url = `${RouterUtils.pathForScreen('design_qa')}/${evaluation?.id}`;
            if (openInNewWindow) {
                window.open(url);
            } else {
                history.push(url);
            }
        },
        [history, evaluation?.id],
    );

    if (!evaluation || !evaluation.order) {
        return <OrderListItemLoading columns={4} />;
    }

    const { patient, doctor_name } = evaluation.order;

    return (
        <OrderListItemLayout
            started_at={undefined}
            closeout={null}
            onClick={onClick}
            className={classes.rootGrid}
            rowTitle={`Order Id: ${evaluation.order_id}`}
            title={
                <>
                    <Grid container item direction={`row`} spacing={2}>
                        <Grid item style={{ flexGrow: 1 }}>
                            <OrderListItemCellText
                                medium
                                variant={'title'}
                                text={`${EvaluationDesignTypeMap[evaluation.design_type]} Evaluation`}
                            />
                        </Grid>
                    </Grid>
                    <OrderListItemCellText
                        variant={'subtitle'}
                        text={moment(evaluation.created_at).format('MM/DD/YYYY hh:mm a')}
                    />
                </>
            }
            middleCellOne={
                evaluation ? (
                    <OrderListItemTitle
                        disableSearchOnClick
                        patient_name={`${patient.first_name} ${patient.last_name}`}
                        patient_order_count={0}
                    />
                ) : (
                    <OrderListItemTitleCellLoading />
                )
            }
            middleCellTwo={
                evaluation ? (
                    <OrderListItemPartnerCell partner_name={''} doctor_name={doctor_name} />
                ) : (
                    <OrderListItemMiddleCellLoading />
                )
            }
            middleCellThree={
                evaluation ? (
                    <OrderListItemItemsLabCell
                        products={LabsUtilsBase.productsForOrderV2(
                            OrderItemV2Utils.parseItems(evaluation.order.items_v2),
                        ).join(', ')}
                    />
                ) : (
                    <OrderListItemMiddleCellLoading />
                )
            }
            // There is no tracker because the QA is entirely divorced from the order status
            trackerCell={
                !evaluation.is_calibration && (
                    <IconButton
                        disabled={deletionSubmitting}
                        onClick={async () => {
                            if (evaluation) {
                                await deleteSubmit({ evaluation_ids: [evaluation.id] });
                            }
                        }}
                    >
                        <Icon icon={'TrashIcon'} />
                    </IconButton>
                )
            }
        />
    );
}, listItemPropsAreEqual);

function listItemPropsAreEqual(prev: InboxListItemProps, _next: InboxListItemProps): boolean {
    return _.isEqual(prev.evaluation, prev.evaluation);
}

export const DesignQaEvaluationList: React.VFC = () => {
    const itemProps = useVirtualizedListEvaluations({
        idsLoading: false,
        itemHeight: LIST_ITEM_HEIGHT,
        evaluationToListItemContent: evaluation => evaluation,
    });

    return (
        <EvaluationsVirtualList
            listItemHeight={ORDER_LIST_ITEM_HEIGHT}
            ListItem={InboxListItemContent}
            {...itemProps}
        />
    );
};
