import type { FirebasePreviewFileMulti } from '../../../hooks';
import { DandyLightbox } from '../../DandyLightbox';
import { Box, Text, FlossPalette, Checkbox } from '@orthly/ui-primitives';
import React from 'react';

interface ShareScansWithPatientModalAdditionalContentProps {
    includeAnnotations: boolean;
    setIncludeAnnotations: (includeAnnotations: boolean) => void;
    imagePreviews: FirebasePreviewFileMulti[] | undefined;
    disableAnnotations?: boolean;
}

export const ShareScansWithPatientModalAdditionalContent: React.FC<
    ShareScansWithPatientModalAdditionalContentProps
> = ({ includeAnnotations, setIncludeAnnotations, imagePreviews, disableAnnotations }) => {
    const [selectedAttachmentPreview, setSelectedAttachmentPreview] = React.useState<string | undefined>(undefined);
    const disabled = !imagePreviews || imagePreviews.length === 0;
    const opacity = '40%';

    React.useEffect(() => {
        if (!disabled) {
            setIncludeAnnotations(true);
        }
    }, [disabled, setIncludeAnnotations]);
    return (
        <Box
            sx={{
                borderRight: `1px solid ${FlossPalette.DARK_TAN}`,
                padding: '0 24px',
            }}
        >
            <Text variant={'body2'} medium color={'BLACK'} sx={{ marginBottom: '16px' }}>
                Additional Content
            </Text>
            {!disableAnnotations && (
                <Box
                    sx={{
                        border: `1px solid ${FlossPalette.DARK_TAN}`,
                        borderRadius: '8px',
                        backgroundColor: FlossPalette.TAN,
                        display: 'flex',
                        padding: '16px',
                        marginBottom: '16px',
                    }}
                >
                    <Checkbox
                        data-testid={'include-annotations-checkbox'}
                        color={'primary'}
                        checked={includeAnnotations}
                        onChange={e => setIncludeAnnotations(e.target.checked)}
                        style={{ margin: 0 }}
                        disabled={disabled}
                    />
                    <Box sx={{ marginLeft: '16px' }}>
                        <Text variant={'body2'} medium color={'BLACK'} sx={{ opacity: disabled ? opacity : '100%' }}>
                            Include scan annotations
                        </Text>
                        <Text variant={'body2'} color={'GRAY'} sx={{ marginBottom: '16px' }}>
                            {disabled ? `You didn't create any yet` : `${imagePreviews?.length} annotations included`}
                        </Text>
                        {!disabled &&
                            imagePreviews?.map(preview => {
                                return (
                                    <img
                                        key={preview.name}
                                        alt={preview.name}
                                        src={preview.source}
                                        onClick={() => {
                                            setSelectedAttachmentPreview(preview.name);
                                        }}
                                        style={{
                                            width: '106px',
                                            height: '56px',
                                            borderRadius: '8px',
                                            border: `1px solid ${FlossPalette.DARK_TAN}`,
                                            cursor: 'pointer',
                                        }}
                                    />
                                );
                            })}
                    </Box>
                </Box>
            )}
            <Box
                sx={{
                    border: `1px solid ${FlossPalette.DARK_TAN}`,
                    borderRadius: '8px',
                    backgroundColor: FlossPalette.TAN,
                    display: 'flex',
                    padding: '16px',
                }}
            >
                <Checkbox color={'primary'} checked={false} onChange={() => {}} style={{ margin: 0 }} disabled={true} />
                <Box sx={{ marginLeft: '16px' }}>
                    <Text variant={'body2'} medium color={'BLACK'} sx={{ opacity: opacity }}>
                        Include treatment brochures
                    </Text>
                    <Text variant={'body2'} color={'GRAY'}>
                        Coming soon!
                    </Text>
                </Box>
            </Box>
            <DandyLightbox
                previews={imagePreviews}
                setSelectedAttachmentPreview={setSelectedAttachmentPreview}
                selectedAttachmentPreview={selectedAttachmentPreview}
            />
        </Box>
    );
};
