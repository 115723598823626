import type { EditCapacityDialogProps } from '../CapacityStationsRoot.graphql';
import { CapacityStationForm } from '../Forms/CapacityStationForm';
import type { LabsGqlOrganizationDtoFragment } from '@orthly/graphql-operations';
import { RootActionDialog } from '@orthly/ui';
import React from 'react';

type EditCapacityRuleStationProps = EditCapacityDialogProps & {
    labs: LabsGqlOrganizationDtoFragment[];
    labsLoading: boolean;
};

export const EditCapacityStationDialog: React.VFC<EditCapacityRuleStationProps> = (
    args: EditCapacityRuleStationProps,
) => {
    const { submitting, open, setOpen, submit, labs, labsLoading } = args;
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Create Capacity Station'}
            content={
                <CapacityStationForm
                    loading={submitting}
                    labs={labs}
                    labsLoading={labsLoading}
                    onSubmit={values => {
                        submit(values).catch(console.error);
                    }}
                />
            }
            hideButton={true}
        />
    );
};
