import { getCapabilitiesForTask, getLabelForTaskType } from '../../../../utils/Capabilities';
import type { LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment } from '@orthly/graphql-operations';
import { LabsGqlDesignTaskType } from '@orthly/graphql-schema';
import {
    FormControlLabel,
    Tooltip,
    FormControl,
    RadioPrimitive as Radio,
    RadioGroupPrimitive as RadioGroup,
    makeStyles,
    createStyles,
    FlossPalette,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        focusTaskRadioSection: {
            borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
        },
    }),
);

const taskRadioButtonDisabled = (
    task: LabsGqlDesignTaskType,
    designersCapabilities: LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment,
): boolean => {
    const designerCapabilitiesForTask = getCapabilitiesForTask(designersCapabilities, task);
    return !Object.entries(designerCapabilitiesForTask).some(([_, level]) => level !== null);
};

const TaskRadioButton: React.VFC<{
    value: LabsGqlDesignTaskType | null;
    capabilities: LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment;
}> = props => {
    const { value, capabilities } = props;
    const label = getLabelForTaskType(value);
    const disabled = value === null ? false : taskRadioButtonDisabled(value, capabilities);
    return (
        <Tooltip
            title={disabled ? `No capabilities configured for ${label}` : `${label} chosen as focus task type.`}
            arrow
            placement={'top'}
        >
            <FormControlLabel
                control={<Radio color={'secondary'} />}
                value={value}
                label={label}
                aria-label={label}
                disabled={disabled}
            />
        </Tooltip>
    );
};

export const FocusTaskRadioGroup: React.VFC<{
    focusTask?: LabsGqlDesignTaskType | null;
    handleFocusTaskChange: (changed: LabsGqlDesignTaskType | null) => void;
    capabilities: LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment;
}> = props => {
    const { focusTask, handleFocusTaskChange, capabilities } = props;
    const classes = useStyles();

    return (
        <FormControl variant={'standard'} className={classes.focusTaskRadioSection}>
            <RadioGroup
                aria-label={'Design Task Selection for Focus Areas'}
                value={focusTask}
                onChange={changed => handleFocusTaskChange(changed.target.value as LabsGqlDesignTaskType)}
                row
            >
                <TaskRadioButton value={LabsGqlDesignTaskType.DesignPrep} capabilities={capabilities} />
                <TaskRadioButton value={LabsGqlDesignTaskType.AutomateReview} capabilities={capabilities} />
                <TaskRadioButton value={LabsGqlDesignTaskType.InternalDesign} capabilities={capabilities} />
                <TaskRadioButton value={LabsGqlDesignTaskType.InjectionMoldDesign} capabilities={capabilities} />
                <TaskRadioButton value={LabsGqlDesignTaskType.DesignReview} capabilities={capabilities} />
                <TaskRadioButton value={LabsGqlDesignTaskType.ModelDesign} capabilities={capabilities} />
                <TaskRadioButton value={null} capabilities={capabilities} />
            </RadioGroup>
        </FormControl>
    );
};
