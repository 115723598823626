import { CapacityStationRuleTable } from './CapacityStationTables/CapacityStationRuleTable';
import { EditCapacityStationRuleDialog } from './CreateDialogs/EditCapacityStationRuleDialog';
import { useMutation, useQuery } from '@apollo/client';
import type { CapacityStationDtoFragment, CapacityStationRuleDtoFragment } from '@orthly/graphql-inline-react';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import { ActionCard, useRootActionCommand } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

const CapacityStations_Fragment = graphql(`
    fragment CapacityStationDTO on CapacityStationDTO {
        id
        created_at
        updated_at
        organization_id
        name
        unit_type
    }
`);

const CapacityStationRules_Fragment = graphql(`
    fragment CapacityStationRuleDTO on CapacityStationRuleDTO {
        id
        created_at
        updated_at
        capacity_station_id
        name
        work_order_type
        default_unit_limit
        default_unit_granularity
        matching_logic_json
        lab_product_codes
    }
`);

const CreateCapacityStationRule_Mutation = graphql(`
    mutation CreateCapacityStationRule_Mutation($createCapacityStationRuleArgs: CreateCapacityStationRuleArgs!) {
        createCapacityStationRule(createCapacityStationRuleArgs: $createCapacityStationRuleArgs) {
            ...CapacityStationRuleDTO
        }
    }
`);

const AllCapacityStations_Query = graphql(`
    query AllCapacityStations {
        getAllCapacityStations {
            ...CapacityStationDTO
        }
    }
`);

const AllCapacityStationRules_Query = graphql(`
    query AllCapacityStationRules {
        getAllCapacityStationRules {
            ...CapacityStationRuleDTO
        }
    }
`);

export type StationRow = CapacityStationDtoFragment & { organization_name?: string };
export type RuleRow = CapacityStationRuleDtoFragment & { station_name?: string };

export const CapacityStationRulesRoot: React.FC = () => {
    const { loading: loadingStations, data: stations } = useQuery(AllCapacityStations_Query, {
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const stationsResult = getFragmentData(CapacityStations_Fragment, stations?.getAllCapacityStations);

    const {
        loading: loadingRules,
        data: rules,
        error: rulesError,
        refetch: refetchRules,
    } = useQuery(AllCapacityStationRules_Query, {
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const rulesResult = getFragmentData(CapacityStationRules_Fragment, rules?.getAllCapacityStationRules);
    const createCapacityStationRule = useMutation(CreateCapacityStationRule_Mutation);
    const {
        submit: submitRule,
        submitting: submittingRule,
        open: openAddRuleDialog,
        setOpen: setOpenAddRuleDialog,
    } = useRootActionCommand(createCapacityStationRule, {
        onSuccess: async () => {
            await refetchRules();
            setOpenAddRuleDialog(false);
        },
        successMessage: 'Capacity Station Rule created!',
    });

    const ruleRows: RuleRow[] = React.useMemo(
        () =>
            (rulesResult ?? []).map(rule => ({
                ...rule,
                station_name: stationsResult?.find(({ id }) => id === rule.capacity_station_id)?.name ?? undefined,
            })),
        [rulesResult, stationsResult],
    );

    return (
        <Grid container>
            <Grid item xs={12}>
                <CapacityStationRuleTable
                    ruleRows={ruleRows}
                    loadingRules={loadingRules}
                    setOpenAddRuleDialog={setOpenAddRuleDialog}
                    refetchRules={refetchRules}
                />
                <EditCapacityStationRuleDialog
                    setOpen={setOpenAddRuleDialog}
                    submitting={submittingRule}
                    open={openAddRuleDialog}
                    stations={stationsResult ? [...stationsResult] : []}
                    submit={submitRule}
                    loadingStations={loadingStations}
                />
            </Grid>
            {rulesError && (
                <ActionCard title={'Error retrieving capacity station rules'} subtitle={''} variant={'alert'} />
            )}
        </Grid>
    );
};
