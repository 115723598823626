import { useGuidedWaxupContext } from '../../state/GuidedWaxupContext';
import { GuidedWaxupAccessoryModals, type GuidedWaxupScheduleModal } from './GuidedWaxupAccessoryModals.graphql';
import { useIsOrderEligibleForLiveDesignReview } from './GuidedWaxupScheduleLiveCall.hooks';
import { GuidedWaxupScheduleLiveCallInitialModal } from './GuidedWaxupScheduleLiveCallInitialModal';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { addBusinessDays } from '@orthly/date-fns-business-days';
import { VeneerUseOrderRevisionItemsDesign_FragmentFragmentDoc, getFragmentData } from '@orthly/graphql-inline-react';
import { LabsGqlDesignOrderDoctorReviewStatus } from '@orthly/graphql-schema';
import { useScreenIsMobileOrVerticalTablet } from '@orthly/ui';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';

export const GuidedWaxupScheduleLiveCall: React.VFC<{
    orderId: string;
    revisionId: string;
}> = ({ orderId, revisionId }) => {
    const [modal, setModal] = React.useState<GuidedWaxupScheduleModal>('closed');

    // We normally allow 2 days past DDP due date for the customer to review the DDP before a BPO agent will auto approve it.
    // If the customer is pausing the order, that time is also paused.
    // Thus, the time they will have to approve the DDP _after_ the meeting is equal to how much time they have left _now_.
    // ie, if they have 1 day left at the time of placing the pause, they will have 1 day left when the pause is removed.
    const { order, designRevisionFragments } = useGuidedWaxupContext();
    const designRevisions = getFragmentData(
        VeneerUseOrderRevisionItemsDesign_FragmentFragmentDoc,
        designRevisionFragments,
    );
    const numRejections = _.sumBy(designRevisions, revision =>
        revision.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Rejected ? 1 : 0,
    );
    const designPreviewETA = order.practice_dates.digital_design_preview_estimated_completion_date
        ? new Date(order.practice_dates.digital_design_preview_estimated_completion_date)
        : new Date();
    const reviewDue = addBusinessDays(designPreviewETA, 2);
    const numberOfDaysUntilAutoApproveDdp = dayjs(reviewDue).diff(dayjs(), 'day');

    const isMobile = useScreenIsMobileOrVerticalTablet();

    const isEnabled = useIsOrderEligibleForLiveDesignReview();

    React.useEffect(() => {
        if (isEnabled) {
            BrowserAnalyticsClientFactory.Instance?.track('Practice - Portal - Live Design Review - Available', {
                $groups: { order: orderId },
                numRejections,
            });
        }
    }, [isEnabled, orderId, numRejections]);

    if (!isEnabled) {
        return null;
    }

    return (
        <>
            <GuidedWaxupScheduleLiveCallInitialModal
                open={modal === 'initial'}
                openModal={() => {
                    BrowserAnalyticsClientFactory.Instance?.track(
                        'Practice - Portal - Live Design Review - Initiated Scheduling Flow',
                        {
                            $groups: { order: order.id },
                        },
                    );
                    setModal('initial');
                }}
                openAbandomentModal={() => setModal('abandonment')}
                openCalendlyModal={() => {
                    BrowserAnalyticsClientFactory.Instance?.track(
                        'Practice - Portal - Live Design Review - Opened Calendly Scheduler',
                        {
                            $groups: { order: order.id },
                        },
                    );
                    setModal('calendly');
                }}
                numberOfDaysUntilAutoApproveDdp={numberOfDaysUntilAutoApproveDdp}
                isMobile={isMobile}
            />
            <GuidedWaxupAccessoryModals
                orderId={orderId}
                revisionId={revisionId}
                isMobile={isMobile}
                setModal={setModal}
                modal={modal}
            />
        </>
    );
};
