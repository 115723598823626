import { EditPoolDialog } from '../EditDialogs/EditPoolDialog';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlCapacityStationPoolDto } from '@orthly/graphql-schema';
import { useRootActionCommand } from '@orthly/ui';
import { EditIcon, IconButton, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

const EditPool_Mutation = graphql(`
    mutation EditPool_Mutation($updateCapacityStationPoolArgs: UpdateCapacityStationPoolArgs!) {
        updateCapacityStationPool(updateCapacityStationPoolArgs: $updateCapacityStationPoolArgs) {
            ...CapacityStationPoolDTO
        }
    }
`);

export const EditPoolRowActions: React.VFC<{
    row: LabsGqlCapacityStationPoolDto;
    refetchPools: () => Promise<unknown>;
}> = ({ row, refetchPools }) => {
    const editPoolMutation = useMutation(EditPool_Mutation);
    const {
        submit: editPool,
        submitting: editingPool,
        open: openEditPoolDialog,
        setOpen: setOpenEditPoolDialog,
    } = useRootActionCommand(editPoolMutation, {
        onSuccess: async () => {
            await refetchPools();
        },
        successMessage: 'Capacity Station Pool edited!',
    });

    return (
        <>
            <Tooltip title={'Edit'}>
                <IconButton onClick={() => setOpenEditPoolDialog(true)}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <EditPoolDialog
                open={openEditPoolDialog}
                setOpen={setOpenEditPoolDialog}
                submit={editPool}
                submitting={editingPool}
                pool={row}
            />
        </>
    );
};
