import { PALATE_ORDER_LIST_ITEM_HEIGHT, PalateOrderListItem } from './PalateOrderListItem.graphql';
import type { PalateLiastViewDataItem } from './usePalateListViewData.graphql';
import { Grid } from '@orthly/ui-primitives';
import type { VirtualListHookResult } from '@orthly/veneer';
import { VirtualList } from '@orthly/veneer';
import React from 'react';

function useToolbarHeight() {
    const [toolbarHeight, setToolbarHeight] = React.useState<number>(250);
    const [container, setContainer] = React.useState<HTMLDivElement | null>(null);

    React.useEffect(() => {
        if (container) {
            setToolbarHeight(container.getBoundingClientRect().top);
        }
    }, [container]);

    return { toolbarHeight, containerRef: setContainer };
}

interface PalateOrdersListProps {
    useItemData: (startIndex: number, stopIndex: number) => VirtualListHookResult<PalateLiastViewDataItem>;
}

export const PalateOrdersList: React.VFC<PalateOrdersListProps> = ({ useItemData }) => {
    const { toolbarHeight, containerRef } = useToolbarHeight();

    return (
        <Grid container ref={containerRef} style={{ height: '100%' }}>
            <VirtualList
                listItemHeight={PALATE_ORDER_LIST_ITEM_HEIGHT}
                useItemData={useItemData}
                ListItem={PalateOrderListItem}
                toolbarHeight={toolbarHeight}
                showLoadBlocker={true}
                showErrorSnackbar={true}
            />
        </Grid>
    );
};
