import { RouterTabs } from '../../components/RouterTabs';
import { IteroAuthentication } from './IteroAuthentication';
import { StandaloneImplantPartCalculator } from './StandaloneImplantPartCalculator';
import React from 'react';

export const ToolsRoot: React.FC = () => {
    return (
        <RouterTabs
            defaultPath={'implant-part-calculator'}
            items={[
                {
                    path: 'implant-part-calculator',
                    label: 'Implant Parts Calculator',
                    Component: StandaloneImplantPartCalculator,
                },
                {
                    path: 'itero-auth',
                    label: 'Itero Admin Authentication',
                    Component: IteroAuthentication,
                },
            ]}
        />
    );
};
