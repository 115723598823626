import type { EditCapacityDialogProps } from '../CapacityStationsRoot.graphql';
import { CreateProductStationUnitMappingForm } from '../Forms/CreateProductStationUnitMappingForm';
import type { CapacityStationDtoFragment } from '@orthly/graphql-inline-react';
import { RootActionDialog } from '@orthly/ui';
import React from 'react';

type CreateProductStationUnitMappingProps = EditCapacityDialogProps & {
    stations: CapacityStationDtoFragment[];
    loadingStations: boolean;
};

export const CreateProductStationUnitMappingDialog: React.VFC<CreateProductStationUnitMappingProps> = (
    props: CreateProductStationUnitMappingProps,
) => {
    const { submitting, open, setOpen, submit, stations, loadingStations } = props;
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Create Product Station Unit Mapping'}
            content={
                <CreateProductStationUnitMappingForm
                    loading={submitting || loadingStations}
                    stations={stations}
                    onSubmit={values => {
                        submit({
                            createProductStationUnitMappingArgs: values,
                        }).catch(console.error);
                    }}
                />
            }
            hideButton={true}
        />
    );
};
