import type { StationRow } from '../CapacityStationsRoot.graphql';
import { EditStationRowActions } from './EditStationRowActions.graphql';
import type { ApolloQueryResult } from '@apollo/client';
import type { AllCapacityStationsQuery } from '@orthly/graphql-inline-react';
import MUITable from '@orthly/mui-table';
import { Tooltip } from '@orthly/ui-primitives';
import React from 'react';

interface StationsTableProps {
    stationRows: StationRow[];
    loadingStations: boolean;
    setOpenAddStationDialog: (open: boolean) => void;
    refetchStations: () => Promise<ApolloQueryResult<AllCapacityStationsQuery>>;
}

export const CapacityStationTable: React.VFC<StationsTableProps> = (args: StationsTableProps) => {
    const { stationRows, loadingStations, setOpenAddStationDialog, refetchStations } = args;
    return (
        <MUITable<StationRow>
            title={`Capacity Stations`}
            data={stationRows}
            loading={loadingStations}
            displayOptions={{
                download: true,
                filter: true,
                sort: true,
                filterValues: true,
                fixedSearch: true,
            }}
            actions={{
                global: [
                    {
                        icon: 'add',
                        position: 'toolbar',
                        onClick: () => setOpenAddStationDialog(true),
                        tooltip: 'Add rule',
                    },
                    {
                        icon: 'refresh',
                        position: 'toolbar',
                        onClick: () => refetchStations().catch(console.error),
                        tooltip: 'Refresh',
                    },
                ],
            }}
            columns={[
                {
                    name: 'ID',
                    render: 'id',
                    type: 'string',
                    hidden: true,
                    defaultSort: 'asc',
                },
                {
                    name: 'Name',
                    field: 'name',
                    render: ({ name }) => (
                        <Tooltip title={name}>
                            <div>{name.length > 100 ? `${name.slice(0, 75)}...` : name}</div>
                        </Tooltip>
                    ),
                },
                {
                    name: 'Organization Name',
                    render: 'organization_name',
                    type: 'string',
                },
                {
                    name: 'Organization ID',
                    render: 'organization_id',
                    type: 'string',
                },
                {
                    name: 'Unit type',
                    render: 'unit_type',
                    type: 'string',
                },
                {
                    title: 'Actions',
                    name: 'actions',
                    render: row => <EditStationRowActions row={row} refetchStations={refetchStations} />,
                    type: 'boolean',
                },
            ]}
        />
    );
};
