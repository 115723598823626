import type { LabsGqlUpdateCapacityStationArgs } from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';
import React from 'react';

type EditStationFormFields = Pick<LabsGqlUpdateCapacityStationArgs, 'name'>;

interface StationFormProps {
    onSubmit: (result: EditStationFormFields) => void;
    name: string;
}

export const EditStationForm: React.VFC<StationFormProps> = ({ onSubmit, name }) => {
    return (
        <QuickForm<EditStationFormFields>
            dirtySubmitOnly
            onSubmit={onSubmit}
            initialValues={{ name }}
            fields={{
                name: {
                    label: 'Name',
                    type: 'text',
                },
            }}
        />
    );
};
