import { ButtonWithProgress } from './ButtonWithProgress';
import type { DesignTrainingOrderFragment } from '@orthly/graphql-inline-react';
import { useOrder } from '@orthly/graphql-react';
import type { LabsGqlFinishingInBrowserPayload } from '@orthly/graphql-schema';
import { Button, type ButtonProps } from '@orthly/ui-primitives';
import { useDesignOrderRevision, DesignEditorRoot } from '@orthly/veneer';
import React from 'react';

function useSubmitCallbacks(orderId: string, trainingDesignId: string) {
    const submitFinishedDesign = async (args: { payload: LabsGqlFinishingInBrowserPayload }) => {
        throw new Error(`Not implemented, ${args}, ${orderId}, ${trainingDesignId}`);
    };
    const submitReviewDecision = async (args: { approved: boolean; reviewDurationMs: number }) => {
        throw new Error(`Not implemented, ${args}, ${orderId}, ${trainingDesignId}`);
    };

    return {
        submitFinishedDesign,
        submitReviewDecision,
    };
}

export const DesignTrainingStartDesignEditorButton: React.VFC<{
    trainingOrder: DesignTrainingOrderFragment;
    onComplete: () => void;
    children?: React.ReactNode;
    prefetch?: boolean;
    ButtonComponent?: React.ComponentType<ButtonProps>;
    buttonVariant?: ButtonProps['variant'];
}> = props => {
    const {
        trainingOrder,
        onComplete,
        children,
        prefetch = false,
        ButtonComponent = Button,
        buttonVariant = 'primary',
    } = props;
    const [open, setOpen] = React.useState<boolean>(false);
    const skip = !prefetch && !open;

    const { order: labOrderDataRaw } = useOrder(trainingOrder.orderId, { skip });
    const labOrderData = labOrderDataRaw && {
        ...labOrderDataRaw,
        fulfillment_workflow: { ...labOrderDataRaw.fulfillment_workflow, active_task: null },
        patient: { ...labOrderDataRaw.patient, first_name: `Training #${trainingOrder.orderNumber}`, last_name: '' },
    };

    const { data: designRevisionData } = useDesignOrderRevision(trainingOrder.designRevisionId ?? '', { skip });
    const design = designRevisionData?.getDesignOrderDesignRevisionById;

    const submitCallbacks = useSubmitCallbacks(trainingOrder.orderId, trainingOrder.id);

    return (
        <>
            <ButtonWithProgress
                ButtonComponent={ButtonComponent}
                variant={buttonVariant}
                loading={open && (!labOrderData || !design)}
                onClick={() => {
                    setOpen(true);
                }}
            >
                {children}
            </ButtonWithProgress>
            {labOrderData && design && (
                <DesignEditorRoot
                    order={labOrderData}
                    design={design}
                    open={open}
                    callbacks={{
                        ...submitCallbacks,
                        setWindowOpen: setOpen,
                        onCancel: () => {},
                        onComplete,
                    }}
                    initiallyOpen={false}
                    isTrainingOrder={true}
                />
            )}
        </>
    );
};
