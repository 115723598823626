import type { LabsGqlUpdateProductStationUnitMappingArgs } from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';
import React from 'react';

type EditProductStationUnitMappingFormFields = Pick<LabsGqlUpdateProductStationUnitMappingArgs, 'units'>;

interface ProductStationUnitMappingFormProps {
    onSubmit: (result: EditProductStationUnitMappingFormFields) => void;
    units: number;
}

export const EditProductStationUnitMappingForm: React.VFC<ProductStationUnitMappingFormProps> = ({
    onSubmit,
    units,
}) => {
    return (
        <QuickForm<EditProductStationUnitMappingFormFields>
            dirtySubmitOnly
            onSubmit={onSubmit}
            initialValues={{ units }}
            fields={{
                units: {
                    label: 'Units',
                    type: 'number',
                },
            }}
        />
    );
};
