import { PracticeFullScreenDialog } from '@orthly/dentin';
import { FlossPalette, Icon, RootActionDialog } from '@orthly/ui';
import { Box, Button, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const StyledRootActionDialog = styled(RootActionDialog)({
    '.MuiPaper-root': {
        maxWidth: '796px',
    },
});

const MobileWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

const MobileHeader = styled(Box)({
    display: 'grid',
    gridTemplateColumns: '1fr min-content',
    alignItems: 'center',
    minHeight: '70px',
    backgroundColor: FlossPalette.TAN,
    borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    position: 'relative',
    padding: '12px 16px',
});

const MobileCloseButton = styled(Button)({
    background: FlossPalette.TAN,
    height: '48px',
    width: '48px',
});

interface InnerContentProps {
    onDesignRejectionAction: () => Promise<void>;
    openAbandonmentModal: () => void;
    openCalendlyModal: () => void;
    onModalClose: () => void;
}

const InnerContent: React.VFC<InnerContentProps> = ({
    onDesignRejectionAction,
    openAbandonmentModal,
    openCalendlyModal,
    onModalClose,
}) => {
    return (
        <Box style={{ display: 'grid', gap: '8px' }}>
            <Button variant={'secondary'} onClick={openCalendlyModal}>
                Schedule LDR
            </Button>
            <Button variant={'secondary'}>Request Video</Button>
            <Button variant={'secondary'} onClick={openAbandonmentModal}>
                Close
            </Button>
            <Button
                variant={'primary'}
                onClick={async () => {
                    onModalClose();
                    await onDesignRejectionAction();
                }}
            >
                Reject Design As Is
            </Button>
        </Box>
    );
};

interface GuidedWaxupSelectDesignReviewOptionModalProps {
    onDesignRejectionAction: () => Promise<void>;
    openAbandonmentModal: () => void;
    openCalendlyModal: () => void;
    openModal: () => void;
    onModalClose: () => void;
    /**
     * While this field could be ascertained within the component, we pass it in
     * to make it easier to test variants in Storybook and other areas.
     */
    isMobile: boolean;
}

export const GuidedWaxupSelectDesignReviewOptionModal: React.VFC<GuidedWaxupSelectDesignReviewOptionModalProps> = ({
    onDesignRejectionAction,
    openAbandonmentModal,
    openCalendlyModal,
    openModal,
    onModalClose,
    isMobile,
}) => {
    if (isMobile) {
        return (
            <PracticeFullScreenDialog open>
                <MobileWrapper>
                    <MobileHeader>
                        <Text variant={'h6'} medium>
                            Connect with an expert
                        </Text>
                        <MobileCloseButton variant={'secondary'} onClick={openAbandonmentModal}>
                            <Icon icon={'XIcon'} />
                        </MobileCloseButton>
                    </MobileHeader>
                    <InnerContent
                        onDesignRejectionAction={onDesignRejectionAction}
                        openAbandonmentModal={openAbandonmentModal}
                        openCalendlyModal={openCalendlyModal}
                        onModalClose={onModalClose}
                    />
                </MobileWrapper>
            </PracticeFullScreenDialog>
        );
    }

    return (
        <StyledRootActionDialog
            loading={false}
            open
            showCloseButton
            setOpen={open => {
                // If the user attempts to close, we open the abandonment modal
                if (!open) {
                    openAbandonmentModal();
                } else {
                    // Otherwise, we re-open the modal.
                    openModal();
                }
            }}
            title={'Connect with an expert'}
            content={
                <InnerContent
                    onDesignRejectionAction={onDesignRejectionAction}
                    openAbandonmentModal={openAbandonmentModal}
                    openCalendlyModal={openCalendlyModal}
                    onModalClose={onModalClose}
                />
            }
            CustomButton={() => null}
        />
    );
};
