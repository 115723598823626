import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LEHI_MANUFACTURER_ID, DANDY_TEXAS_MANUFACTURER_ID } from '@orthly/shared-types';
import { QuickForm, useRootActionCommand, LoadBlocker } from '@orthly/ui';
import { Text, Link } from '@orthly/ui-primitives';
import React from 'react';
import { z } from 'zod';

const CreateFabricationBulkInternal_Mutation = graphql(`
    mutation CreateFabricationBulkInternal($orderIds: [String!]!, $labId: String!) {
        createFabricationBulkInternal(labId: $labId, orderIds: $orderIds)
    }
`);

const uuidArraySchema = z.array(z.string().uuid()).min(1);

const jsonStringArraySchema = z.string().superRefine((arg, ctx) => {
    try {
        const value = JSON.parse(arg);
        if (!Array.isArray(value)) {
            return ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'Not a JSON array' });
        }
        // Get all invalid IDs to surface for user
        const invalidUUids = value.flatMap(uuidStr => {
            if (!z.string().uuid().safeParse(uuidStr).success) {
                return uuidStr;
            }
            return [];
        });
        if (invalidUUids.length > 0) {
            return ctx.addIssue({
                code: 'invalid_string',
                message: `Invalid UUIDs in IDs: ${invalidUUids.join(', ')}`,
                validation: 'uuid',
            });
        }
    } catch (e: any) {
        console.error(e, e.message);
        ctx.addIssue({ code: z.ZodIssueCode.custom, message: `Not valid JSON: ${e.message ?? ''}` });
    }
});

function getOrderIdCount(orderIdsStr?: string): number {
    try {
        if (!orderIdsStr) {
            return 0;
        }
        return uuidArraySchema.parse(JSON.parse(orderIdsStr)).length;
    } catch (_e) {
        return 0;
    }
}

export const ManualLabAcceptOrderPage: React.VFC = () => {
    const submitMtn = useMutation(CreateFabricationBulkInternal_Mutation);
    const [orderIdCount, setOrderIdCount] = React.useState<number>(0);
    const { submit, submitting } = useRootActionCommand(submitMtn, {
        successMessage: 'Started bulk accept workflow',
        onSuccess: () => setOrderIdCount(0),
    });
    return (
        <LoadBlocker blocking={submitting}>
            <div style={{ padding: 18, width: '100%' }}>
                <div style={{ width: '100%', paddingBottom: 8 }}>
                    <Text variant={'h4'} style={{ paddingBottom: 8 }}>
                        Manual Intake
                    </Text>
                    <Link
                        href={`https://app.hex.tech/99fa47a2-e436-4bc7-9258-7cfb103b9003/app/e1ac4628-cb30-486a-ae33-c083e7abbf74/latest?tab=manual-intake`}
                        target={'_blank'}
                    >
                        Hex Tool for pulling order IDs
                    </Link>
                </div>
                <QuickForm<{ orderIdsStr: string; labId: string }>
                    submitButtonTitle={`Accept ${orderIdCount} orders`}
                    disabled={submitting}
                    fields={{
                        labId: {
                            type: 'select',
                            label: 'Lab',
                            options: [
                                { label: 'Lehi', value: LEHI_MANUFACTURER_ID },
                                { label: 'Dandy Texas', value: DANDY_TEXAS_MANUFACTURER_ID },
                            ],
                        },
                        // the array of IDs as a json string
                        orderIdsStr: {
                            type: 'text',
                            label: 'Order Ids - Copy and paste from Hex',
                            fieldProps: { multiline: true, rows: 2 },
                            validation: jsonStringArraySchema,
                        },
                    }}
                    onChange={formValues => {
                        setOrderIdCount(getOrderIdCount(formValues.orderIdsStr));
                    }}
                    initialValues={{ labId: LEHI_MANUFACTURER_ID }}
                    onSubmit={async (form, actions) => {
                        const orderIds = uuidArraySchema.parse(JSON.parse(form.orderIdsStr));
                        const labName = form.labId === LEHI_MANUFACTURER_ID ? 'Lehi' : 'Dandy Texas';
                        if (window.confirm(`Accept ${orderIds.length} at ${labName}?`)) {
                            await submit({ orderIds, labId: form.labId });
                            actions.resetForm({ labId: LEHI_MANUFACTURER_ID, orderIdsStr: '' });
                        }
                    }}
                />
            </div>
        </LoadBlocker>
    );
};
