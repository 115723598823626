import type { EditCapacityDialogProps } from '../CapacityStationsRoot.graphql';
import { EditProductStationUnitMappingForm } from '../Forms/EditProductStationUnitMappingForm';
import type { LabsGqlProductStationUnitMappingDto } from '@orthly/graphql-schema';
import { RootActionDialog } from '@orthly/ui';
import React from 'react';

type EditProductStationUnitMappingProps = EditCapacityDialogProps & {
    mapping: LabsGqlProductStationUnitMappingDto;
};

export const EditProductStationUnitMappingDialog: React.VFC<EditProductStationUnitMappingProps> = (
    props: EditProductStationUnitMappingProps,
) => {
    const { submitting, open, setOpen, submit, mapping } = props;
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Edit Product Station Unit Mapping'}
            content={
                <EditProductStationUnitMappingForm
                    units={mapping.units}
                    onSubmit={values => {
                        submit({
                            updateProductStationUnitMappingArgs: {
                                ...values,
                                id: mapping.id,
                            },
                        }).catch(console.error);
                    }}
                />
            }
            hideButton={true}
        />
    );
};
