import { RuleConditionPreview } from '../../../../../../components/RuleTable/RuleConditionPreview';
import type { RuleRow } from '../CapacityStationsRoot.graphql';
import MUITable from '@orthly/mui-table';
import { Tooltip } from '@orthly/ui-primitives';
import React from 'react';

export interface RulesTableProps {
    ruleRows: RuleRow[];
    loadingRules: boolean;
    setOpenAddRuleDialog: (open: boolean) => void;
    refetchRules: () => Promise<unknown>;
}

export const CapacityStationRuleTable: React.VFC<RulesTableProps> = (args: RulesTableProps) => {
    const { ruleRows, loadingRules, setOpenAddRuleDialog, refetchRules } = args;
    return (
        <MUITable<RuleRow>
            title={`Capacity Station Rules`}
            data={ruleRows}
            loading={loadingRules}
            displayOptions={{
                download: true,
                filter: true,
                sort: true,
                filterValues: true,
                fixedSearch: true,
            }}
            actions={{
                global: [
                    {
                        icon: 'add',
                        position: 'toolbar',
                        onClick: () => setOpenAddRuleDialog(true),
                        tooltip: 'Add rule',
                    },
                    {
                        icon: 'refresh',
                        position: 'toolbar',
                        onClick: () => refetchRules().catch(console.error),
                        tooltip: 'Refresh',
                    },
                ],
            }}
            columns={[
                {
                    name: 'ID',
                    render: 'id',
                    type: 'string',
                    hidden: true,
                    defaultSort: 'asc',
                },
                {
                    name: 'Name',
                    field: 'name',
                    render: ({ name }) => (
                        <Tooltip title={name}>
                            <div>{name.length > 100 ? `${name.slice(0, 75)}...` : name}</div>
                        </Tooltip>
                    ),
                },
                {
                    name: 'Station ID',
                    render: 'capacity_station_id',
                    type: 'string',
                },
                {
                    name: 'Station Name',
                    render: 'station_name',
                    type: 'string',
                },
                {
                    name: 'Work order type',
                    render: 'work_order_type',
                    type: 'string',
                },
                {
                    name: 'Default Unit Limit',
                    render: 'default_unit_limit',
                    type: 'numeric',
                },
                {
                    name: 'Default Unit Granularity',
                    render: 'default_unit_granularity',
                    type: 'string',
                },
                {
                    name: 'Matching Logic',
                    field: 'matching_logic_json',
                    render: row =>
                        row.matching_logic_json ? (
                            <RuleConditionPreview ruleType={'item'} rawJSON={row.matching_logic_json} />
                        ) : (
                            '(none)'
                        ),
                },
                {
                    name: 'Labtrac Product Codes',
                    field: 'lab_product_codes',
                    render: row => (row.lab_product_codes ? row.lab_product_codes.join(', ') : '(none)'),
                },
            ]}
        />
    );
};
