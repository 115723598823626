import { ORDER_LIST_ITEM_HEIGHT } from '../../../../components/OrderListItem/OrderListItemLayout';
import { OrdersListToolbar } from '../../../../components/OrdersListToolbar/OrdersListToolbar';
import { UNSAVED_VIEW_ID } from '../../../../redux/utils/SavedViewActionConstants';
import { OrdersListViewListItem } from '../../components/OrdersListView/OrdersListViewListItem.graphql';
import { useOrdersListViewData } from '../../components/OrdersListView/useOrdersListViewData.graphql';
import { useOrdersListViewOrderIds } from '../../components/OrdersListView/useOrdersListViewOrderIds';
import { useOrdersOverviewState, useOrdersSearchQueryDisabled } from '../../state/OrdersOverview.selectors';
import { LoadBlocker } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import { OrderListNoResultsItem, VirtualList } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const OrdersListViewLayout: React.FC = ({ children }) => {
    const { data, loading, error } = useOrdersListViewOrderIds();
    const searchQueryDisabled = useOrdersSearchQueryDisabled();
    const showToolbarLoader = loading || searchQueryDisabled;
    const loadBlockerEnabled = showToolbarLoader && data.length === 0;

    const noOrdersMessage = useOrdersOverviewState(s => {
        if (s.screen === 'custom' && !!s.view) {
            return s.view.id === UNSAVED_VIEW_ID
                ? `No orders found for unsaved query`
                : `No orders found for saved view ${s.view.title}`;
        }
        return `No orders with status ${_.startCase(s.screen)}`;
    });

    return (
        <Grid container style={{ height: '100vh', overflow: 'auto', alignContent: 'flex-start' }}>
            <OrdersListToolbar loading={showToolbarLoader} mode={'orders'} />
            <LoadBlocker loader={'dandy'} blocking={loadBlockerEnabled}>
                {data.length === 0 && !loading && !error && <OrderListNoResultsItem message={noOrdersMessage} />}
                {error && <OrderListNoResultsItem message={`Error: ${error.message}`} variant={'error'} />}
                {children}
            </LoadBlocker>
        </Grid>
    );
};

export const OrdersListView: React.VFC = () => {
    return (
        <OrdersListViewLayout>
            <VirtualList
                listItemHeight={ORDER_LIST_ITEM_HEIGHT}
                useItemData={useOrdersListViewData}
                ListItem={OrdersListViewListItem}
            />
        </OrdersListViewLayout>
    );
};
