import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { PracticeScreen } from '@orthly/dentin';
import { RootActionDialog } from '@orthly/ui';
import { Button, FlossPalette, Text, stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

const useStyles = stylesFactory(() => ({
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '8px',
    },
    dialogButtons: {
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        margin: '15px 5px 5px 5px',
    },
}));

export const ExitGuidedReviewWarningModal: React.VFC = () => {
    const classes = useStyles();
    const { order, isWarningDialogModalOpen, setIsWarningDialogModalOpen } = useGuidedWaxupContext();
    const navigate = useNavigate();
    return (
        <RootActionDialog
            loading={false}
            open={isWarningDialogModalOpen}
            setOpen={setIsWarningDialogModalOpen}
            CustomButton={() => null}
            title={'Are you sure?'}
            content={
                <Grid container>
                    <Grid item>
                        <Text variant={'body1'} color={'GRAY'}>
                            You will exit the review process, and your changes will be discarded.
                        </Text>
                    </Grid>
                    <Grid item container className={classes.buttonContainer}>
                        <Grid item>
                            <Button
                                variant={'ghost-warning'}
                                className={classes.dialogButtons}
                                onClick={() => {
                                    setIsWarningDialogModalOpen(false);
                                    navigate(`/${PracticeScreen.orders}/${order.id}`, { replace: true });
                                }}
                            >
                                Leave Review
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={'primary'}
                                onClick={() => setIsWarningDialogModalOpen(false)}
                                className={classes.dialogButtons}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
        />
    );
};
