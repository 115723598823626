import { useFeatureFlag } from '../../Providers/LaunchDarkly';
import { useExperiment } from '../../Providers/Statsig';
import { isGuidedPreset, useGetSelectedWaxup, useSubmitGuidedWaxupReview } from './GuidedWaxup.util';
import { GuidedWaxupNavArea } from './components/GuidedWaxupNavArea';
import {
    GuidedWaxupAccessoryModals,
    type GuidedWaxupScheduleModal,
} from './components/LiveDesignReview/GuidedWaxupAccessoryModals.graphql';
import { useGuidedWaxupPresets } from './hooks/useGuidedWaxupPresets';
import { CurrentWaxupDesktopScreen } from './screens/CurrentWaxupDesktopScreen';
import { CurrentWaxupMobileScreen } from './screens/CurrentWaxupMobileScreen';
import { HistoricalWaxupDesktopScreen } from './screens/HistoricalWaxupDesktopScreen';
import { HistoricalWaxupMobileScreen } from './screens/HistoricalWaxupMobileScreen';
import { useGuidedWaxupContext } from './state/GuidedWaxupContext';
import { useGuidedWaxupSelector, useWaxupIsRejected } from './state/GuidedWaxupState';
import { PracticeScreen } from '@orthly/dentin';
import { getFragmentData } from '@orthly/graphql-inline-react';
import { VeneerUseOrderRevisionItemsDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import {
    LabsGqlDesignOrderDoctorReviewStatus,
    LabsGqlGuidedWaxupPresetStatus,
    LabsGqlGuidedWaxupPresetType,
} from '@orthly/graphql-schema';
import { useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

const HistoricalWaxupsScreens: React.VFC = () => {
    const { selectedTab } = useGuidedWaxupContext();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const guidedPreset = isGuidedPreset(selectedTab);

    const selectedWaxup = useGetSelectedWaxup();
    const isApprovedWaxup = selectedWaxup?.status === LabsGqlDesignOrderDoctorReviewStatus.Approved;

    // Always show the nav area if the selected revision was a rejection, but if vieweing an approved waxup,
    // we hide the nav area when viewing the general view preset since there's no rejection note to view
    const showNavArea = !isApprovedWaxup || guidedPreset;

    return isMobile ? (
        <>
            <HistoricalWaxupMobileScreen
                selectedDesignRevisionNotes={selectedWaxup?.notes ?? undefined}
                isApprovedWaxup={isApprovedWaxup}
            />
            {showNavArea && <GuidedWaxupNavArea />}
        </>
    ) : (
        <HistoricalWaxupDesktopScreen
            selectedDesignRevisionNotes={selectedWaxup?.notes ?? undefined}
            isApprovedWaxup={isApprovedWaxup}
        />
    );
};

const CurrentWaxupScreens: React.VFC = () => {
    const navigate = useNavigate();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const { refetch, order, selectedDesignRevisionId, designRevisionFragments } = useGuidedWaxupContext();
    const waxupRejected = useWaxupIsRejected();
    const presets = useGuidedWaxupSelector(s => s.presets);
    const generalRejectionNote =
        useGuidedWaxupSelector(s => s.presets[LabsGqlGuidedWaxupPresetType.GeneralView]?.notes) ?? '';

    const [modal, setModal] = React.useState<GuidedWaxupScheduleModal>('closed');

    const designRevisions = getFragmentData(
        VeneerUseOrderRevisionItemsDesign_FragmentFragmentDoc,
        designRevisionFragments,
    );
    const hasRejection = _.some(
        designRevisions,
        revision => revision.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Rejected,
    );

    const { value: enableDesignReviewVideo } = useFeatureFlag('enableDesignReviewVideo');
    const showLdrEntrypoint = useExperiment('ldr_experiment').get('show_ldr_entrypoint');

    const { submit } = useSubmitGuidedWaxupReview(async () => {
        await refetch();
        navigate(`/${PracticeScreen.orders}/${order.id}`, { replace: true });
    });

    const submitData = async () => {
        await submit({
            order_id: order.id,
            rejection: waxupRejected
                ? {
                      notes: generalRejectionNote,
                      presets: Object.entries(presets).map(([type, info]) => ({
                          preset_type: type as LabsGqlGuidedWaxupPresetType,
                          preset_status:
                              type === LabsGqlGuidedWaxupPresetType.GeneralView
                                  ? info.status ?? LabsGqlGuidedWaxupPresetStatus.Rejected
                                  : info.status ?? LabsGqlGuidedWaxupPresetStatus.Skipped,
                          annotated_image_urls: info.annotatedImageUrls ?? [],
                          structured_notes: info.structured_notes?.map(category => ({ category })) ?? [],
                          notes: info.notes ?? '',
                      })),
                  }
                : null,
        });
    };

    const onSubmit = async () => {
        if (enableDesignReviewVideo && showLdrEntrypoint && waxupRejected && hasRejection) {
            return setModal('options');
        }
        await submitData();
    };

    return (
        <>
            {isMobile ? (
                <>
                    <CurrentWaxupMobileScreen />
                    <GuidedWaxupNavArea submit={onSubmit} />
                </>
            ) : (
                <CurrentWaxupDesktopScreen submit={onSubmit} />
            )}
            {selectedDesignRevisionId && (
                <GuidedWaxupAccessoryModals
                    orderId={order.id}
                    revisionId={selectedDesignRevisionId}
                    modal={modal}
                    setModal={setModal}
                    isMobile={isMobile}
                    onDesignRejectionAction={submitData}
                />
            )}
        </>
    );
};

export const GuidedWaxupScreenWrapper: React.VFC = () => {
    const { design, order, selectedDesignRevisionAlreadyReviewed, selectedDesignFragment } = useGuidedWaxupContext();
    useGuidedWaxupPresets(order, design);

    if (!selectedDesignFragment) {
        return null;
    }

    if (selectedDesignRevisionAlreadyReviewed) {
        return <HistoricalWaxupsScreens />;
    }

    return <CurrentWaxupScreens />;
};
