import { PracticeFullScreenDialog } from '@orthly/dentin';
import { Box, Button, type ButtonVariant, FlossPalette, Icon, Slide, Text } from '@orthly/ui-primitives';
import React from 'react';

/**
 *
 * This is a portal that renders a "full screen" dialog on small/mobile viewports.
 * You can view this container in storybook here:
 * https://storybook-dev.dandyserv.net/main/veneer/index.html?path=/story/previousstructuredrejectionnotesmobile--previous-structured-rejection-notes-mobile
 *
 */

const Header: React.FC<{ onBack: () => any; subtitle?: string }> = ({ children, onBack, subtitle }) => (
    <Box
        sx={{
            display: 'grid',
            alignItems: 'center',
            minHeight: '70px',
            backgroundColor: FlossPalette.TAN,
            borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
            position: 'relative',
        }}
    >
        <Box sx={{ position: 'absolute' }}>
            <Button
                variant={'secondary'}
                sx={{
                    left: '10px',
                    padding: '8px',
                    minWidth: 0,
                    background: FlossPalette.TAN,
                    border: 'none',
                }}
                onClick={onBack}
            >
                <Icon icon={'ChevronLeft'} />
            </Button>
        </Box>
        <Box sx={{ justifySelf: 'center', justifyItems: 'center' }}>
            <Text variant={'body2'} medium>
                {children}
            </Text>
            {subtitle && (
                <Text variant={'caption'} color={'DARK_GRAY'}>
                    {subtitle}
                </Text>
            )}
        </Box>
    </Box>
);

const Body: React.FC = ({ children }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '15px', overflow: 'auto', flexGrow: 1 }}>
        {children}
    </Box>
);

const Footer: React.FC<{
    onCancel?: () => any;
    onClose: () => any;
    closeLabel: string;
    closeDisabled: boolean;
    closeLabelVariant?: ButtonVariant;
}> = ({ children, onCancel, onClose, closeLabel, closeLabelVariant, closeDisabled }) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            borderTop: `2px solid ${FlossPalette.STROKE_LIGHT}`,
            backgroundColor: FlossPalette.WHITE,
            padding: '15px',
        }}
    >
        {children}
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                gap: '15px',
            }}
        >
            {onCancel && (
                <Button variant={'secondary-gray'} sx={{ width: '100%', textAlign: 'center' }} onClick={onCancel}>
                    Cancel
                </Button>
            )}
            <Button
                variant={closeLabelVariant ?? 'primary'}
                sx={{ width: '100%', textAlign: 'center' }}
                onClick={onClose}
                disabled={closeDisabled}
            >
                {closeLabel}
            </Button>
        </Box>
    </Box>
);

export const MobileRejectionNotesContainer: React.FC<{
    isOpen: boolean;
    onBack: () => any;
    onCancel?: () => any;
    onClose: () => any;
    title: string;
    subtitle?: string;
    closeLabel: string;
    closeLabelVariant?: ButtonVariant;
    closeDisabled?: boolean;
    footerPreface?: React.ReactNode;
}> = ({
    children,
    isOpen,
    onBack,
    onCancel,
    onClose,
    title,
    subtitle,
    closeLabel,
    closeDisabled = false,
    footerPreface,
    closeLabelVariant,
}) =>
    isOpen ? (
        <PracticeFullScreenDialog>
            <Slide in direction={'left'}>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Header onBack={onBack} subtitle={subtitle}>
                        {title}
                    </Header>
                    <Body>{children}</Body>
                    <Footer
                        closeLabel={closeLabel}
                        onCancel={onCancel}
                        onClose={onClose}
                        closeDisabled={closeDisabled}
                        closeLabelVariant={closeLabelVariant}
                    >
                        {footerPreface}
                    </Footer>
                </Box>
            </Slide>
        </PracticeFullScreenDialog>
    ) : null;
