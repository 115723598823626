import { RouterUtils } from '../../utils/router/RouterUtils';
import { OrganizationField } from './OrganizationsTable';
import { CreatePracticeAction } from './actions/CreatePracticeAction';
import type { LabsGqlOrganizationWithDescendantsFragment } from '@orthly/graphql-operations';
import { useListOrganizationsWithAncestorsSummaryQuery } from '@orthly/graphql-react';
import { MUITable, downloadAsCsv } from '@orthly/mui-table';
import { IOrganizationType } from '@orthly/retainer-common';
import { Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface PracticesTableRow {
    id: string;
    name: string;
    organization: string[];
    created_at: string;
}

interface PracticesTableCsvRow {
    id: string;
    name: string;
    organization: string;
    created_at: string;
}

function tableRowsToCsvRows(tableRows: PracticesTableRow[]): PracticesTableCsvRow[] {
    return tableRows.map(row => ({
        ...row,
        organization: row.organization.join(' > '),
    }));
}

interface PracticesTableToolbarRightProps {
    parentOrg?: LabsGqlOrganizationWithDescendantsFragment;
    onSubmit: () => Promise<void>;
}

const PracticesTableToolbarRight: React.FC<PracticesTableToolbarRightProps> = props => {
    const { parentOrg, onSubmit } = props;
    return (
        <Grid style={{ overflowX: 'auto', padding: '10px 0 ' }}>
            <Grid
                style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: 'max-content',
                    gap: 4,
                }}
            >
                <Grid item>
                    <CreatePracticeAction parentOrg={parentOrg} onSubmit={onSubmit} />
                </Grid>
            </Grid>
        </Grid>
    );
};

interface PracticesTableProps {
    parentOrg?: LabsGqlOrganizationWithDescendantsFragment;
    refetchParentOrg?: () => Promise<void>;
}

export const PracticesTable: React.FC<PracticesTableProps> = props => {
    const { parentOrg, refetchParentOrg } = props;
    const basePath = `${RouterUtils.pathForScreen('accounts')}/practices`;
    const history = useHistory();
    const {
        data: practicesData,
        loading: practicesLoading,
        refetch: refetchPractices,
    } = useListOrganizationsWithAncestorsSummaryQuery({
        variables: {
            filter: parentOrg
                ? { ids: parentOrg.descendants.filter(o => o.type === IOrganizationType.practice).map(o => o.id) }
                : null,
        },
    });
    const rows: PracticesTableRow[] = (practicesData?.listOrganizationsWithAncestorsSummary ?? []).map(org => ({
        ..._.pick(org, ['id', 'name']),
        organization: org.ancestors.map(ancestor => ancestor.name).reverse(),
        created_at: moment(org.created_at).format('MM/DD/YYYY'),
    }));
    const [refetchingAll, setRefetchingAll] = React.useState<boolean>(false);
    const refetchAll = React.useCallback(() => {
        setRefetchingAll(true);
        void Promise.all([!practicesLoading ? refetchPractices().catch(console.error) : Promise.resolve()]).finally(
            () => setRefetchingAll(false),
        );
    }, [practicesLoading, refetchPractices]);
    return (
        <Grid container style={{ width: '100%' }}>
            <Grid item xs={12}>
                <MUITable<PracticesTableRow>
                    title={parentOrg ? `${parentOrg.legal_name} - Practices` : 'Practices'}
                    loading={practicesLoading || refetchingAll}
                    toolbarOptions={{
                        CustomRight: () => (
                            <PracticesTableToolbarRight
                                parentOrg={parentOrg}
                                onSubmit={async () => {
                                    if (parentOrg && refetchParentOrg) {
                                        await refetchParentOrg();
                                        return;
                                    }
                                    refetchAll();
                                }}
                            />
                        ),
                    }}
                    displayOptions={{ filter: true, fixedSearch: true }}
                    eventHooks={{ onRowClick: row => history.push(`${basePath}/${row.id}`) }}
                    columns={[
                        { name: 'id', render: row => row.id, hidden: true },
                        {
                            name: 'Name',
                            field: row => row.name,
                            render: row => <strong>{row.name}</strong>,
                            defaultSort: 'asc',
                        },
                        {
                            name: 'Organization',
                            field: row => row.organization.join(' '),
                            render: row => <OrganizationField organization={row.organization} />,
                        },
                        { name: 'Created Date', render: row => row.created_at, defaultSort: 'desc' },
                    ]}
                    actions={{
                        global: [
                            {
                                icon: 'refresh',
                                position: 'toolbar',
                                onClick: refetchAll,
                                disabled: practicesLoading || refetchingAll,
                            },
                            {
                                tooltip: 'Export',
                                icon: 'save_alt',
                                position: 'toolbar',
                                onClick: async () => {
                                    downloadAsCsv(tableRowsToCsvRows(rows), 'Practices Export');
                                },
                            },
                        ],
                    }}
                    data={rows}
                />
            </Grid>
        </Grid>
    );
};
