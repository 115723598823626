import type { EditCapacityDialogProps } from '../CapacityStationsRoot.graphql';
import { CapacityStationRuleForm } from '../Forms/CapacityStationRuleForm.graphql';
import type { CapacityStationDtoFragment } from '@orthly/graphql-inline-react';
import { LabsGqlCapacityLimitGranularity } from '@orthly/graphql-schema';
import { RootActionDialog } from '@orthly/ui';
import React from 'react';

type EditCapacityStationProps = EditCapacityDialogProps & {
    stations: CapacityStationDtoFragment[];
    loadingStations: boolean;
};

export const EditCapacityStationRuleDialog: React.VFC<EditCapacityStationProps> = (props: EditCapacityStationProps) => {
    const { submitting, open, setOpen, submit, stations, loadingStations } = props;
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Create Capacity Station Rule'}
            content={
                <CapacityStationRuleForm
                    loading={submitting || loadingStations}
                    stations={stations}
                    onSubmit={values => {
                        submit({
                            createCapacityStationRuleArgs: {
                                ...values,
                                default_unit_granularity: LabsGqlCapacityLimitGranularity.Day,
                            },
                        }).catch(console.error);
                    }}
                />
            }
            hideButton={true}
        />
    );
};
