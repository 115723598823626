import { useMutation } from '@apollo/client';
import { graphql, type CreateCadSmileLibraryWithInitialRevisionMutation } from '@orthly/graphql-inline-react';

const Mutation = graphql(`
    mutation CreateCadSmileLibraryWithInitialRevision(
        $libraryId: String!
        $name: String!
        $previewImagePath: String!
        $revisionId: String!
        $templates: [CadSmileLibraryCreateWithRevisionToothTemplateDto!]!
    ) {
        createCadSmileLibraryWithInitialRevision(
            libraryId: $libraryId
            name: $name
            previewImagePath: $previewImagePath
            revisionId: $revisionId
            templates: $templates
        ) {
            archived
            id
            name
            revisions {
                createdAt
                id
                previewImagePath
                templates {
                    dcmPath
                    id
                    unn
                }
            }
        }
    }
`);

export type MutationResult =
    CreateCadSmileLibraryWithInitialRevisionMutation['createCadSmileLibraryWithInitialRevision'];
export type MutationArgs = {
    libraryId: string;
    revisionId: string;
    name: string;
    previewImagePath: string;
    templates: {
        dcmPath: string;
        unn: number;
    }[];
};

export function useCreateSmileLibraryMutation() {
    const [createSmileLibrary, { loading, error, data }] = useMutation<MutationResult, MutationArgs>(Mutation, {
        fetchPolicy: 'no-cache',
    });
    return {
        createSmileLibrary,
        loading,
        error,
        data,
    };
}
