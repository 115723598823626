import { usePartnerAndLabNames } from '../../../../utils/usePartnerAndLabNames';
import { getOrderIdsFromIndexes } from './getOrderIdsFromIndexes';
import { useOrdersListViewEntries } from './useOrdersListViewEntries.graphql';
import { useOrdersListViewOrderIds } from './useOrdersListViewOrderIds';
import type { AdminOrdersListViewDataOrderAdminListViewEntry_FragmentFragment } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import type { VirtualListHookResult } from '@orthly/veneer';
import { VIRTUAL_LIST_LOADING } from '@orthly/veneer';

const AdminOrdersListViewDataOrderAdminListViewEntry_Fragment = graphql(`
    fragment AdminOrdersListViewDataOrderAdminListViewEntry_Fragment on OrderAdminListViewEntry {
        id
        practice_id
        manufacturer_id
        ...AdminOrdersListViewListItem_Fragment
        ...AdminOrderDetailSidebarListItem_Fragment
    }
`);

interface OrderListViewDataItem {
    order: AdminOrdersListViewDataOrderAdminListViewEntry_FragmentFragment;
    practiceName: string;
    manufacturerName: string;
}
/**
 * Used for the main order list view components in the ops portal.
 *
 * Sub-apps like Palate should use unique implementations if rendered content differs.
 */
export function useOrdersListViewData(
    startIndex: number,
    stopIndex: number,
): VirtualListHookResult<OrderListViewDataItem> {
    const { labNamesById, partnerNamesById } = usePartnerAndLabNames();

    const { data: idsData, loading: idsLoading, error: idsError } = useOrdersListViewOrderIds();

    const visbleEntries = getOrderIdsFromIndexes(idsData, startIndex, stopIndex);

    const { orders, loading: ordersLoading, error: ordersError } = useOrdersListViewEntries(visbleEntries);

    const listItems = orders.map(rawOrder => {
        if (!rawOrder) {
            return undefined;
        }

        const order = getFragmentData(AdminOrdersListViewDataOrderAdminListViewEntry_Fragment, rawOrder);
        return {
            order,
            key: order.id,
            practiceName: partnerNamesById[order.practice_id] ?? 'Loading...',
            manufacturerName: labNamesById[order.manufacturer_id] ?? 'Loading...',
        };
    });

    return {
        listItems,
        itemsLoading: ordersLoading,
        error: idsError || ordersError,
        listItemCount: idsLoading && idsData.length === 0 ? VIRTUAL_LIST_LOADING : idsData.length,
    };
}
