import type { ScanEntry } from '@orthly/forceps';
import { deSuffixFilename } from '@orthly/forceps';
import type { LabsGqlPatientScanFileFragment } from '@orthly/graphql-operations';
import type { LabsGqlCartItemV2DtoFragment } from '@orthly/graphql-operations';
import type { ProductUnitType, ICartItemV2DTO } from '@orthly/items';
import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';
import dayjs from 'dayjs';
import _ from 'lodash';

export class AttachScansUtils {
    static formatScanDate(date: string) {
        return dayjs(date).format('MM/DD, h:mma');
    }

    static formatScanFileName(fileName: string | null) {
        return Format.lowerCaseAllWordsExceptFirstLetter(_.startCase(deSuffixFilename(fileName ?? '')));
    }

    static isDiagnosticScan(scan: LabsGqlPatientScanFileFragment) {
        const relevantItems = AttachScansUtils.getNonUnknownNonWellnessScanItems(scan.cart_items_v2);
        // due to some weirdness in the wellness scan -> scan_export conversion, the cart items will have an unknown item
        if (relevantItems.length === 0) {
            return true;
        }
        return false;
    }

    static getScanTitle(scan: LabsGqlPatientScanFileFragment) {
        const relevantItems = AttachScansUtils.getNonUnknownNonWellnessScanItems(scan.cart_items_v2);
        // due to some weirdness in the wellness scan -> scan_export conversion, the cart items will have an unknown item
        if (relevantItems.length === 0) {
            return 'Diagnostic scan';
        }

        const itemGroups = _.groupBy(relevantItems, scan => scan.sku);

        return Format.joinWithAnd(
            Object.values(itemGroups).map(items => CartItemV2Utils.getItemGroupDisplayName(items)),
        );
    }

    static getScanSKUs(scan: LabsGqlPatientScanFileFragment): ProductUnitType[] {
        const relevantItems = AttachScansUtils.getNonUnknownNonWellnessScanItems(scan.cart_items_v2);
        if (relevantItems.length === 0) {
            return [LabOrderItemSKUType.WellnessScan];
        }

        return relevantItems.map(item => CartItemV2Utils.getProductUnitType(item));
    }

    private static getNonUnknownNonWellnessScanItems(items: LabsGqlCartItemV2DtoFragment[]): ICartItemV2DTO[] {
        return CartItemV2Utils.parseItems(items).filter(
            i => i.sku !== LabOrderItemSKUType.Unknown && i.sku !== LabOrderItemSKUType.WellnessScan,
        );
    }

    static getSTLNameLookupMap(scans: ScanEntry[]): Map<string, string> {
        const nameLookup = new Map();
        let [numUpper, numLower, numBites] = [0, 0, 0];

        scans.forEach(scan => {
            if (!scan.file_name) {
                return;
            }

            const transformedFileName = deSuffixFilename(scan.file_name).toLowerCase();

            if (scan.file_name.includes('Upper')) {
                nameLookup.set(`upper${numUpper}`, transformedFileName);
                numUpper += 1;
            } else if (scan.file_name.includes('Lower')) {
                nameLookup.set(`lower${numLower}`, transformedFileName);
                numLower += 1;
            } else if (scan.file_name.includes('Bite')) {
                nameLookup.set(`bitescan${numBites}`, transformedFileName);
                numBites += 1;
            }
        });

        return nameLookup;
    }
}
