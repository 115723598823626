import type { EditCapacityDialogProps } from '../CapacityStationsRoot.graphql';
import { EditCapacityStationPoolForm } from '../Forms/EditCapacityStationPoolForm';
import type { LabsGqlCapacityStationPoolDto } from '@orthly/graphql-schema';
import { RootActionDialog } from '@orthly/ui';
import React from 'react';

type EditPoolProps = EditCapacityDialogProps & {
    pool: LabsGqlCapacityStationPoolDto;
};

export const EditPoolDialog: React.VFC<EditPoolProps> = (props: EditPoolProps) => {
    const { submitting, open, setOpen, submit, pool } = props;
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Edit Pool'}
            content={
                <EditCapacityStationPoolForm
                    unit_limit={pool.unit_limit}
                    onSubmit={values => {
                        submit({
                            updateCapacityStationPoolArgs: {
                                ...values,
                                id: pool.id,
                            },
                        }).catch(console.error);
                    }}
                />
            }
            hideButton={true}
        />
    );
};
