import { useIteroCompany } from './iteroCompany.graphql';
import type {
    LabsGqlOrganizationDtoFragment,
    LabsGqlUpdateOrganizationSettingsMutationVariables,
    LabsGqlScannerDtoFragment,
    LabsGqlPracticeSupportFragment,
    LabsGqlUpdateStripeLinkingMutationVariables as UpdateStripeLinkingVars,
} from '@orthly/graphql-operations';
import {
    useGetOrgQuery,
    useUpdateOrganizationSettingsMutation,
    useGetScannersByPracticeQuery,
    useGetOrCreatePracticeSupportQuery,
    useListOrgsWithoutRelationsQuery,
    usePracticeOrdersCountQuery,
    useConvertPracticeToParentMutation,
    useUpdateStripeLinkingMutation,
} from '@orthly/graphql-react';
import {
    LabsGqlExternalAccountRelationshipType,
    LabsGqlOrganizationType,
    LabsGqlTaggableEntityType,
} from '@orthly/graphql-schema';
import { useHasCapability } from '@orthly/session-client';
import { LoadBlocker, QuickForm, useChangeSubmissionFn, RootActionDialog, QuickFormValidations } from '@orthly/ui';
import { Text, Button, FlossPalette, stylesFactory, Grid } from '@orthly/ui-primitives';
import { TagsAutocomplete, ScannerSelectionPanel } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    settingsBlockRoot: {
        boxSizing: 'border-box',
        backgroundColor: 'rgb(247,247,249)',
        padding: '8px 16px',
        backgroundOrigin: 'padding-box',
        marginBottom: 16,
    },
    settingsTitleWrapper: {
        marginBottom: 8,
    },
}));

interface PracticeSettingsProps {
    practiceId: string;
}

interface PracticeSettingsFormVars {
    name: string;
    parent_id?: string;
    phone_number: string;
    salesforce_account: string;
    pandadoc_contract_id: string;
    stripe_customer: string;
    itero_company_id: string;
}

function getOrganizationSettings(
    organization: LabsGqlOrganizationDtoFragment,
    iteroCompanyId: string | undefined,
    _scanners: LabsGqlScannerDtoFragment[],
    _support?: LabsGqlPracticeSupportFragment,
): PracticeSettingsFormVars {
    const externalAccount = (type: LabsGqlExternalAccountRelationshipType): string | undefined => {
        const entry = organization.external_accounts.find(account => account.type === type);
        return entry?.id;
    };
    return {
        name: organization.legal_name,
        parent_id: organization.parent_id || undefined,
        phone_number: organization.phone_number ?? '',
        salesforce_account: externalAccount(LabsGqlExternalAccountRelationshipType.SalesforceAccount) ?? '',
        pandadoc_contract_id: externalAccount(LabsGqlExternalAccountRelationshipType.PandadocContractId) ?? '',
        stripe_customer: externalAccount(LabsGqlExternalAccountRelationshipType.StripeCustomer) ?? '',
        itero_company_id: iteroCompanyId ?? '',
    };
}

interface PracticeConvertToOrganizationModalProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    onSubmit: () => Promise<void>;
    loading: boolean;
}

const PracticeConvertToOrganizationModal: React.FC<PracticeConvertToOrganizationModalProps> = props => {
    const { open, setOpen, onSubmit, loading } = props;
    return (
        <RootActionDialog
            title={"You're about to convert this practice"}
            open={open}
            setOpen={setOpen}
            loading={loading}
            buttonText={``}
            CustomButton={() => null}
            showCloseButton
            content={
                <div>
                    <div>
                        Are you sure you want to convert this practice to an organization? All users with access to this
                        practice will now have access to the converted organization.
                    </div>
                    <div style={{ float: 'right', paddingTop: '20px' }}>
                        <Button variant={'secondary'} onClick={() => setOpen(false)} style={{ marginRight: '10px' }}>
                            Cancel
                        </Button>
                        <Button
                            variant={'primary'}
                            onClick={() => {
                                void onSubmit();
                                setOpen(false);
                            }}
                        >
                            Yes, convert practice
                        </Button>
                    </div>
                </div>
            }
        />
    );
};

interface PracticeConvertToOrganizationProps {
    practiceId: string;
}

const PracticeConvertToOrganization: React.FC<PracticeConvertToOrganizationProps> = props => {
    const [submitMtn] = useConvertPracticeToParentMutation();
    const mtnSubmitter = (practice_id: string) => submitMtn({ variables: { practice_id } });
    const { submit } = useChangeSubmissionFn<any, [string]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Practice converted to organization!', {}],
    });
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    if (modalOpen) {
        return (
            <PracticeConvertToOrganizationModal
                open={modalOpen}
                setOpen={setModalOpen}
                onSubmit={() => submit(props.practiceId)}
                loading={false}
            />
        );
    }
    return (
        <Button
            variant={'text'}
            style={{ color: FlossPalette.STAR_GRASS_ACTIVE }}
            onClick={() => {
                setModalOpen(true);
            }}
        >
            Convert to organization
        </Button>
    );
};

interface SettingsBlockProps {
    title?: string;
}

const SettingsBlock: React.FC<SettingsBlockProps> = ({ title, children }) => {
    const classes = useStyles();

    return (
        <Grid className={classes.settingsBlockRoot} container direction={'column'}>
            {title && (
                <Grid item className={classes.settingsTitleWrapper}>
                    <Text variant={'caption'} style={{ color: 'rgb(139,139,140)' }}>
                        {title}
                    </Text>
                </Grid>
            )}
            <Grid item>{children}</Grid>
        </Grid>
    );
};

type UpdateOrganizationSettingsVars = LabsGqlUpdateOrganizationSettingsMutationVariables['data'];

export const PracticeSettings: React.FC<PracticeSettingsProps> = props => {
    const showConvertToOrgButton = useHasCapability('practice', 'practice.convert_to_organization');
    const { data, loading, refetch } = useGetOrgQuery({
        variables: { id: props.practiceId },
    });
    const { data: parentOrgsData, loading: parentOrgsLoading } = useListOrgsWithoutRelationsQuery({
        variables: { filter: { type: LabsGqlOrganizationType.Parent } },
    });
    const { data: scannersData, loading: scannersLoading } = useGetScannersByPracticeQuery({
        variables: { practiceId: props.practiceId },
    });
    const { data: ordersCountData, loading: ordersCountLoading } = usePracticeOrdersCountQuery({
        variables: { practice_id: props.practiceId },
    });
    const { data: supportData, loading: supportLoading } = useGetOrCreatePracticeSupportQuery({
        variables: { practice_id: props.practiceId },
    });

    const [submitMtn] = useUpdateOrganizationSettingsMutation();
    const mtnSubmitter = (data: UpdateOrganizationSettingsVars) => submitMtn({ variables: { data } });
    const { submit } = useChangeSubmissionFn<any, [UpdateOrganizationSettingsVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Practice settings updated!', {}],
    });

    const [submitStripeIdMtn] = useUpdateStripeLinkingMutation();
    const stripeIdMtnSubmitter = (variables: UpdateStripeLinkingVars) => submitStripeIdMtn({ variables });
    const { submit: submitStripeId } = useChangeSubmissionFn<any, [UpdateStripeLinkingVars]>(stripeIdMtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Practice Stripe ID updated!', {}],
    });

    const {
        iteroCompanyId,
        updateIteroCompany,
        loading: iteroCompanyLoading,
    } = useIteroCompany({ practiceId: props.practiceId });

    const organization = data?.getOrganization;
    const scanners = scannersData?.getScannersByPractice;
    const support = supportData?.getOrCreatePracticeSupport;
    if (!organization || !scanners || !support) {
        return null;
    }
    const organizationSettings = getOrganizationSettings(organization, iteroCompanyId, scanners, support);

    return (
        <Grid container justifyContent={'space-evenly'} style={{ paddingTop: '20px' }}>
            <Grid item xs={4}>
                <LoadBlocker
                    blocking={
                        loading ||
                        parentOrgsLoading ||
                        scannersLoading ||
                        supportLoading ||
                        ordersCountLoading ||
                        iteroCompanyLoading
                    }
                >
                    <QuickForm<PracticeSettingsFormVars>
                        disabled={!organization || !scanners || !support}
                        readOnly={!organization || !scanners || !support}
                        fields={{
                            name: { type: 'text', label: 'Practice name' },
                            parent_id: {
                                type: 'select',
                                label: 'Parent organization',
                                options: (parentOrgsData?.listOrganizations ?? []).map(org => ({
                                    value: org.id,
                                    label: org.legal_name,
                                })),
                                optional: true,
                            },
                            salesforce_account: { type: 'text', label: 'Salesforce ID', optional: true },
                            phone_number: {
                                type: 'text',
                                mask: '+19999999999',
                                validation: QuickFormValidations.phone,
                                optional: true,
                                fieldProps: { variant: 'standard' },
                            },
                            pandadoc_contract_id: { type: 'text', label: 'Pandadoc ID', optional: true },
                            stripe_customer: { type: 'text', label: 'Stripe ID', optional: true },
                            itero_company_id: { type: 'text', label: 'Itero Company ID', optional: true },
                        }}
                        initialValues={organizationSettings}
                        dirtySubmitOnly={true}
                        onSubmit={async result => {
                            const stripeResult = result.stripe_customer;
                            if (organizationSettings.stripe_customer !== stripeResult && stripeResult) {
                                // Stripe ID update needs to be handled separately because both labs-server and retainer
                                // need to update their data
                                await submitStripeId({
                                    partner_id: organization.id,
                                    stripe_id: stripeResult,
                                });
                            }
                            if (iteroCompanyId !== result.itero_company_id) {
                                await updateIteroCompany(result.itero_company_id);
                            }
                            await submit({
                                id: organization.id,
                                name: result.name,
                                phone_number: result.phone_number ?? '',
                                parentId: result.parent_id || null,
                                // The mutation will figure out which of these are actual changes
                                externalAccountUpdates: [
                                    {
                                        type: LabsGqlExternalAccountRelationshipType.SalesforceAccount,
                                        id: result.salesforce_account,
                                    },
                                    {
                                        type: LabsGqlExternalAccountRelationshipType.PandadocContractId,
                                        id: result.pandadoc_contract_id,
                                    },
                                ],
                            });
                            await refetch();
                        }}
                    />
                </LoadBlocker>
            </Grid>
            <Grid item xs={4}>
                <SettingsBlock title={'Tags'}>
                    <TagsAutocomplete
                        entityId={props.practiceId}
                        entityType={LabsGqlTaggableEntityType.Practice}
                        rootStyle={{ width: '100%' }}
                    />
                </SettingsBlock>

                <SettingsBlock title={'Scanners'}>
                    <ScannerSelectionPanel practiceId={props.practiceId} />
                </SettingsBlock>

                {ordersCountData?.practiceOrdersCount === 0 && (
                    <SettingsBlock>
                        {showConvertToOrgButton && <PracticeConvertToOrganization practiceId={props.practiceId} />}
                    </SettingsBlock>
                )}
            </Grid>
        </Grid>
    );
};
