import type { TaskButtonProps } from './TaskButton.util';
import { TaskButtonBase } from './TaskButtonBase';
import { useStartFulfillmentTaskMutation } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { useChangeSubmissionFn, LoadBlocker } from '@orthly/ui';
import { Icon } from '@orthly/ui-primitives';
import React from 'react';

export const StartTaskButton: React.VFC<TaskButtonProps> = ({ task, refetch, buttonProps }) => {
    const last_user_id = task.assigned_user?.id ?? null;
    const myself_id = useSession()?.user.id;
    const [rawStartTask] = useStartFulfillmentTaskMutation();
    const { submit: startTask, submitting: startingTask } = useChangeSubmissionFn(rawStartTask, {
        successMessage: () => [`Task started`, {}],
        onError: () => refetch(),
        onSuccess: () => refetch(),
    });

    const maybeConfirmReassignment = () => {
        if (!task.assigned_user || last_user_id === myself_id) {
            return true;
        }
        const { first_name, last_name } = task.assigned_user;
        return window.confirm(
            `This task is assigned to ${first_name} ${last_name}, are you sure you want to reassign it to yourself?`,
        );
    };

    return (
        <TaskButtonBase
            tooltip={last_user_id === myself_id ? `Start task` : `Assign to me and start`}
            disabled={!!task.has_started || !!task.has_closeout}
            onClick={() =>
                maybeConfirmReassignment() &&
                startTask({ variables: { last_user_id, order_id: task.order_id, task_id: task.id } })
            }
            {...buttonProps}
        >
            <LoadBlocker blocking={startingTask}>
                <Icon icon={'PlayCircleFilledIcon'} />
            </LoadBlocker>
        </TaskButtonBase>
    );
};
