import { stylesFactory, FlossPalette } from '@orthly/ui-primitives';

export const CONTAINER_WIDTH = 376;
export const TAB_CONTAINER_HEIGHT = 112;
export const TAB_CONTAINER_MARGIN = 16;
export const LINK_CONTAINER_HEIGHT = 48;

export const useRootStyles = stylesFactory(() => ({
    container: {
        background: FlossPalette.WHITE,
        boxShadow: '0px 0px 12px 3px rgba(0, 0, 0, 0.03), inset 0px -1px 0px rgba(0, 0, 0, 0.05)',
        '& .MuiAutocomplete-popper': {
            left: 0,
            paddingTop: 56,
            width: '100% !important',
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none',
        },
        '& li': {
            boxShadow: `0px 1px 0px ${FlossPalette.DARK_TAN}`,
        },
        '& .MuiAutocomplete-listbox': {
            height: '100% !important',
        },
        '& .MuiInputBase-root': {
            '&:hover': {
                '&::before': {
                    border: 'none',
                },
            },
            '&.Mui-focused': {
                boxShadow: `0px 0px 0px 4px ${FlossPalette.STAR_GRASS_HOVER}`,
                '&::after': {
                    borderBottom: 'none',
                },
            },
            '&.MuiInput-underline': {
                '&::after': {
                    transition: 'none',
                },
            },
        },
    },
}));
