import { useFirebaseStorage } from '../../context';
import { getFirebaseDownloadUrl } from '../../hooks';
import type { CadSmileLibraryList_FragmentFragment } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import { StackX } from '@orthly/ui';
import { Text } from '@orthly/ui-primitives';
import {
    CheckIcon,
    CloseIcon,
    DownloadIcon,
    EditIcon,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@orthly/ui-primitives';
import React from 'react';

export const CadSmileLibraryList_Fragment = graphql(`
    fragment CadSmileLibraryList_Fragment on CadSmileLibraryDto {
        id
        archived
        name
        revisions {
            createdAt
            id
            previewImagePath
        }
    }
`);

const SmileLibraryListRow: React.FC<{ rowData: CadSmileLibraryList_FragmentFragment }> = ({ rowData }) => {
    const [previewImageUrl, setPreviewImageUrl] = React.useState<string | undefined>();
    const [imageLoadingStatus, setImageLoadingStatus] = React.useState<string>('Loading');
    const firebase = useFirebaseStorage();

    React.useEffect(() => {
        const getPreviewImageUrl = async () => {
            const revision = rowData.revisions
                ?.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
                .at(-1);

            if (!revision) {
                setImageLoadingStatus('Error!');
                return;
            }
            setPreviewImageUrl(await getFirebaseDownloadUrl(firebase, revision.previewImagePath));
        };
        void getPreviewImageUrl();
    }, [firebase, rowData.revisions]);

    return (
        <TableRow key={rowData.name}>
            <TableCell>{rowData.name}</TableCell>
            <TableCell>
                {previewImageUrl ? (
                    <StackX sx={{ justifyContent: 'center' }}>
                        <img src={previewImageUrl} alt={'Smile Library Preview Image'} style={{ maxHeight: '75px' }} />
                    </StackX>
                ) : (
                    <Text>{imageLoadingStatus}</Text>
                )}
                <TableCell />
            </TableCell>
            <TableCell>
                <StackX sx={{ justifyContent: 'center' }}>{rowData.archived ? <CheckIcon /> : <CloseIcon />}</StackX>
            </TableCell>
            <TableCell>
                <StackX style={{ justifyContent: 'flex-end' }}>
                    <Tooltip title={rowData.archived ? `Unarchive Smile Library` : 'Archive Smile Library'}>
                        <Switch checked={rowData.archived} />
                    </Tooltip>
                    <Tooltip title={'Edit Smile Library'}>
                        <IconButton>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Download Smile Library'}>
                        <IconButton>
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                </StackX>
            </TableCell>
        </TableRow>
    );
};

export const SmileLibraryList: React.FC<{ data: CadSmileLibraryList_FragmentFragment[] | undefined }> = ({ data }) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableCell>Name</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>Preview</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>Archived</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>Actions</TableCell>
                </TableHead>
                <TableBody>
                    {data && data.map(rowData => <SmileLibraryListRow key={rowData.name} rowData={rowData} />)}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
