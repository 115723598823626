import { AnalyticsClient } from '../../../../utils/analyticsClient';
import { OpenIncognitoDialog } from '../../components/OpenIncognitoDialog';
import { usePalateState } from '../../state/Palate.reducer';
import { calculateSKU } from '@orthly/dentin';
import { MultiProductImageWrapper } from '@orthly/dentin';
import { useScans } from '@orthly/graphql-react';
import type { ProductUnitType } from '@orthly/items';
import { isValidProductUnitType } from '@orthly/items';
import { LoadBlocker } from '@orthly/ui';
import { Text, Grid, styled, FlossPalette } from '@orthly/ui-primitives';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

const IncompleteOrderContainer = styled(Grid)({
    padding: '8px 32px 8px',
    cursor: 'pointer',
    borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
    '&:hover': {
        backgroundColor: FlossPalette.TAN,
    },
});

interface PalateIncompleteOrderDetailsProps {
    id: string;
    patientName: string;
    date: string;
    products: ProductUnitType[];
    practiceUrl: string;
    practiceId: string;
}

const PalateIncompleteOrderDetails: React.FC<PalateIncompleteOrderDetailsProps> = ({
    id,
    patientName,
    date,
    products,
    practiceUrl,
    practiceId,
}) => {
    const [open, setOpen] = React.useState(false);
    const impersonateUrl = practiceUrl.replace('?', `/lab/submit?scanId=${id}&`);

    return (
        <>
            <OpenIncognitoDialog
                open={open}
                setOpen={setOpen}
                practiceUrl={impersonateUrl}
                title={'Open this incomplete order'}
            />
            <IncompleteOrderContainer
                container
                wrap={'nowrap'}
                alignItems={'center'}
                onClick={() => {
                    AnalyticsClient.track('Palate - Practice Impersonate Opened', { practiceId, impersonateUrl });
                    setOpen(true);
                }}
            >
                <Grid item style={{ paddingRight: 8 }}>
                    <MultiProductImageWrapper products={products} direction={'column'} maxNumOrbs={3} />
                </Grid>
                <Grid item style={{ marginTop: -4 }}>
                    <Text variant={'caption'} medium>
                        {patientName}'s{' '}
                        <Text variant={'caption'} component={'span'}>
                            {products[0] ?? 'Other'}
                        </Text>
                    </Text>
                    <Grid container>
                        <Text variant={'caption'} color={'DARK_GRAY'}>
                            Since {moment(date).format('DD MMM YYYY')}
                        </Text>
                    </Grid>
                </Grid>
            </IncompleteOrderContainer>
        </>
    );
};

function useIncompleteOrders(practiceId: string) {
    const orderFilters = usePalateState(s => s.orderFilters);
    const patientFilter = orderFilters?.find(filter => filter.filter_id === 'by_patient')?.comparison_value;

    return useScans({
        fetchPolicy: 'cache-first',
        variables: {
            practice_id: practiceId,
            patient_names: patientFilter ?? [],
        },
    });
}

interface PalateIncompleteOrdersListProps {
    practiceUrl: string;
    practiceId: string;
}

export const PalateIncompleteOrdersList: React.VFC<PalateIncompleteOrdersListProps> = ({ practiceUrl, practiceId }) => {
    const { scans, loading: loadingScans } = useIncompleteOrders(practiceId);

    return (
        <LoadBlocker blocking={loadingScans} ContainerProps={{ style: { overflow: 'auto', height: '100%' } }}>
            <Grid container>
                {scans.length > 0 ? (
                    <Grid container>
                        {_.orderBy(scans, s => new Date(s.created_at).valueOf(), 'desc').map(scan => (
                            <PalateIncompleteOrderDetails
                                key={scan.id}
                                id={scan.id}
                                patientName={`${scan.patient_first_name} ${scan.patient_last_name}`}
                                date={scan.created_at}
                                products={_.uniq(
                                    scan.restorations.map(r => {
                                        const sku = calculateSKU(r);
                                        return isValidProductUnitType(sku) ? sku : 'Unknown';
                                    }),
                                )}
                                practiceUrl={practiceUrl}
                                practiceId={practiceId}
                            />
                        ))}
                    </Grid>
                ) : (
                    <Text sx={{ padding: '24px 32px 8px' }} variant={'body2'} medium>
                        No incomplete orders
                    </Text>
                )}
            </Grid>
        </LoadBlocker>
    );
};
