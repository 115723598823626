/* eslint-disable no-nested-ternary */
import { AnalyticsClient } from '../../../../utils/analyticsClient';
import type { AutomationListItemData } from '../../../Automations/screens/AutomationsList/AutomationsListFilterToolbar';
import type { ReviewOrder } from './PalatePracticeTimeline.types';
import SalesforceIcon from './Salesforce.png';
import { calculateSKU } from '@orthly/dentin';
import type {
    LabsGqlOrderTimelineItemFragment,
    LabsGqlPracticeTimelineItemFragment,
    LabsGqlSalesforceCaseFragment,
} from '@orthly/graphql-operations';
import { LabsGqlOrderItemSkuType } from '@orthly/graphql-schema';
import { SalesforceUtil } from '@orthly/shared-types';
import { DandyDIcon, RootActionDialog } from '@orthly/ui';
import { FlossPalette, stylesFactory, Text, Grid, Link, Icon, WarningIcon } from '@orthly/ui-primitives';
import type { TimelineActor } from '@orthly/veneer';
import { generateTimelineActorText } from '@orthly/veneer';
import moment from 'moment';
import React from 'react';

const INTERACTION_WIDTH = 344;
const INTERACTION_SHOW_MORE_WIDTH = 304;

const useStyles = stylesFactory(() => ({
    interaction: {
        alignItems: 'flex-start',
        padding: '8px 32px',
    },
    interactionTitle: {
        width: INTERACTION_WIDTH,
        justifyContent: 'space-between',
    },
    interactionIcon: {
        marginRight: 8,
        display: 'flex',
        width: 24,
        height: 24,
        backgroundColor: FlossPalette.TAN,
        justifyContent: 'center',
        borderRadius: 8,
    },
    interactionDetails: {
        lineHeight: '16px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    moreButtonLabel: {
        fontSize: 12,
        lineHeight: '16px',
        '&.MuiButton-label': {
            color: FlossPalette.STAR_GRASS,
        },
    },
    moreButtonLabelRoot: {
        fontSize: 12,
        lineHeight: '16px',
        '&.MuiButton-root': {
            color: FlossPalette.STAR_GRASS,
        },
    },
}));

type IconType = 'salesforce' | 'practice_event' | 'lab_event' | 'review_event';

type TimelineItem = LabsGqlPracticeTimelineItemFragment | LabsGqlOrderTimelineItemFragment;

type InteractionLink = {
    label: string;
    url: string;
};

interface InteractionDetailsProps {
    label?: string | null;
    value?: string | null;
}

interface PracticeInteractionDetailsProps {
    details: InteractionDetailsProps[];
    icon: IconType;
    title: string;
    interactionTime?: string | null;
    interactionLink?: InteractionLink | null;
    practiceId: string;
}

function useTimelineItemDetails(item: TimelineItem, emptyLabelOverride?: string) {
    const details: InteractionDetailsProps[] = item.subtitle.map(subtitle => {
        const [label, value] = subtitle.split(':');

        if (!!label && !value) {
            return { label: emptyLabelOverride, value: label };
        }

        return { label, value };
    });

    if (!!item.actor) {
        const actorText = generateTimelineActorText(item.actor, true);
        if (!!actorText) {
            const [label, value] = actorText.split(':');
            details.push({ label, value });
        }
    }

    return details;
}

function useShowMoreButton() {
    const ref = React.useRef<HTMLDivElement | null>(null);
    const [showMoreButton, setShowMoreButton] = React.useState(false);
    React.useEffect(() => {
        if (ref.current && ref.current.clientWidth < ref.current.scrollWidth) {
            setShowMoreButton(true);
        }
    }, [ref]);
    return { ref, showMoreButton };
}

const InteractionDetails: React.FC<InteractionDetailsProps> = props => {
    const { label, value } = props;
    const classes = useStyles();
    const { ref, showMoreButton } = useShowMoreButton();
    const [open, setOpen] = React.useState(false);

    const buttonClasses = {
        root: classes.moreButtonLabelRoot,
    };

    return (
        <Grid container>
            <Grid
                item
                ref={ref}
                className={classes.interactionDetails}
                style={{ width: showMoreButton ? INTERACTION_SHOW_MORE_WIDTH : INTERACTION_WIDTH }}
            >
                {label && (
                    <Text variant={'caption'} color={'DARK_GRAY'}>
                        {`${label}: `}
                    </Text>
                )}
                <Text variant={'caption'}>{value}</Text>
            </Grid>
            {showMoreButton && (
                <RootActionDialog
                    loading={false}
                    open={open}
                    setOpen={setOpen}
                    title={label}
                    titleProps={{ style: { borderBottom: 'none' } }}
                    content={
                        <Text variant={'body2'} color={'DARK_GRAY'}>
                            {value}
                        </Text>
                    }
                    contentProps={{ style: { borderTop: 'none' } }}
                    buttonText={'more'}
                    buttonProps={{
                        variant: 'text',
                        fullWidth: false,
                        classes: buttonClasses,
                        style: {
                            padding: '2px 4px 0px',
                            border: 0,
                            minWidth: 'unset',
                        },
                    }}
                    titleTextProps={{ style: { fontWeight: 500, fontSize: 32, lineHeight: '40px' } }}
                    showCloseButton
                    closeIconProps={{ color: 'primary' }}
                />
            )}
        </Grid>
    );
};

const PracticeInteractionDetails: React.FC<PracticeInteractionDetailsProps> = props => {
    const classes = useStyles();
    const { details, icon, title, interactionTime, interactionLink, practiceId } = props;

    return (
        <Grid container wrap={'nowrap'} className={classes.interaction}>
            <Grid container direction={'column'}>
                <Grid container style={{ alignItems: 'center' }}>
                    <Grid item className={classes.interactionIcon} style={{ alignItems: 'center' }}>
                        {icon === 'salesforce' ? (
                            <img src={SalesforceIcon} alt={icon} />
                        ) : icon === 'practice_event' ? (
                            <DandyDIcon style={{ fontSize: 20, color: FlossPalette.GRAY }} />
                        ) : icon === 'lab_event' ? (
                            <WarningIcon style={{ fontSize: 16, color: FlossPalette.GRAY }} />
                        ) : icon === 'review_event' ? (
                            <Icon icon={'StarRate'} style={{ fontSize: 20, color: FlossPalette.GRAY }} />
                        ) : null}
                    </Grid>
                    <Grid container className={classes.interactionTitle}>
                        <Text variant={'caption'} medium>
                            {title}
                        </Text>
                        <Text variant={'caption'} color={'DARK_GRAY'}>
                            {interactionTime}
                        </Text>
                    </Grid>
                </Grid>
                <Grid container style={{ paddingLeft: 32 }}>
                    {details.map((detail, idx) => (
                        <InteractionDetails key={idx} label={detail.label} value={detail.value} />
                    ))}
                    {interactionLink && (
                        <Link
                            href={interactionLink.url}
                            target={'_blank'}
                            underline={'none'}
                            onClick={() =>
                                AnalyticsClient.track('Palate - Link Clicked', {
                                    practiceId,
                                    url: interactionLink.url,
                                })
                            }
                        >
                            <Text variant={'caption'} color={'STAR_GRASS'} medium>
                                {interactionLink.label}
                            </Text>
                        </Link>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export const SalesforceEscalationDetails: React.FC<{
    escalation: LabsGqlSalesforceCaseFragment;
    practiceId: string;
}> = props => {
    const { escalation, practiceId } = props;
    const escalationCreatedTime = escalation.CreatedDate ? moment(escalation.CreatedDate).format('h:mmA') : null;
    const title = (!!escalation.Subject ? escalation.Subject : escalation.Type) || `Escalation ${escalation.Id}`;

    const details: InteractionDetailsProps[] = [];
    if (!!escalation.Case_Type_Description__c) {
        details.push({ label: 'Description', value: escalation.Case_Type_Description__c });
    }
    if (!!escalation.Resolution_Type__c) {
        details.push({ label: 'Resolution type', value: escalation.Resolution_Type__c });
    }
    if (!!escalation.Resolution_Notes__c) {
        details.push({ label: 'Resolution note', value: escalation.Resolution_Notes__c });
    }
    if (!!escalation.Owner?.Name) {
        details.push({ label: 'Escalated by', value: escalation.Owner.Name });
    }
    const interactionLink = { label: 'Open in Salesforce', url: SalesforceUtil.salesforceCaseUrl(escalation.Id) };

    return (
        <PracticeInteractionDetails
            details={details}
            icon={'salesforce'}
            title={title}
            interactionTime={escalationCreatedTime}
            interactionLink={interactionLink}
            practiceId={practiceId}
        />
    );
};

export const PracticeTimelineItemDetails: React.FC<{
    item: LabsGqlPracticeTimelineItemFragment;
    practiceId: string;
}> = props => {
    const { item, practiceId } = props;
    const itemTime = moment(item.date).format('h:mmA');
    const title = item.title;
    const interactionLink = !!item.title_link ? { url: item.title_link.href, label: item.title_link.text } : undefined;
    const details = useTimelineItemDetails(item);

    return (
        <PracticeInteractionDetails
            details={details}
            icon={'practice_event'}
            title={title}
            interactionTime={itemTime}
            interactionLink={interactionLink}
            practiceId={practiceId}
        />
    );
};

export const PracticeAutomationDetails: React.FC<{
    automation: AutomationListItemData;
    practiceId: string;
}> = props => {
    const { automation, practiceId } = props;
    const interactionTime = moment(automation.created_at).format('h:mmA');
    const title = 'Automation added';

    const details: InteractionDetailsProps[] = [];
    details.push({ label: 'Title', value: automation.name });
    details.push({ label: 'Description', value: automation.description });

    const actor: TimelineActor = {
        actor: 'user',
        actor_id: automation.creator?.id ?? null,
        user_email: automation.creator?.email ?? null,
        user_display_text: null,
    };
    const actorText = generateTimelineActorText(actor, true);
    if (!!actorText) {
        const [label, value] = actorText.split(':');
        details.push({ label, value });
    }

    return (
        <PracticeInteractionDetails
            details={details}
            icon={'lab_event'}
            title={title}
            interactionTime={interactionTime}
            practiceId={practiceId}
        />
    );
};

export const ReviewSubmissionDetails: React.FC<{ reviewOrder: ReviewOrder; practiceId: string }> = props => {
    const { reviewOrder, practiceId } = props;
    const { review, order } = reviewOrder;

    const reviewTime = moment(review.created_at).format('h:mmA');
    const skus = order.scan_export.elements.map(e => calculateSKU(e)) ?? [];
    const title = `Review placed for ${order.patient.first_name} ${order.patient.last_name}'s ${(skus[0] &&
    skus[0] !== LabsGqlOrderItemSkuType.Unknown
        ? skus[0]
        : LabsGqlOrderItemSkuType.Other
    ).toLowerCase()}`;

    const details = review.items.flatMap(item => {
        return item.tags.map(tag => {
            const [label, value] = tag.title.split(':');
            if (!!label && !value) {
                return { value: label };
            }
            return { label, value: value?.toLowerCase() };
        });
    });

    const interactionLink = {
        label: 'Open in Ops',
        url: `orders/${order.id}`,
    };

    return (
        <PracticeInteractionDetails
            details={details}
            icon={'review_event'}
            title={title}
            interactionTime={reviewTime}
            interactionLink={interactionLink}
            practiceId={practiceId}
        />
    );
};
