import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import { LabsGqlLabPriceRuleType } from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';

export const RetainerAdditionalArchForm: React.FC<LabPriceFormProps> = ({ submit, price, disabled }) => {
    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.PracticeRetainerAdditionalArch>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.PracticeRetainerAdditionalArch>({
                material: {
                    type: 'select',
                    label: 'Material',
                    options: ['Clear Retainer (Essix)', 'Hawley Retainer', 'Lingual Fixed Retainer'],
                },
                default_practice_price: {
                    type: 'number',
                    label: 'Default Price',
                    optional: true,
                },
            })}
            initialValues={
                price
                    ? {
                          name: price.name,
                          material:
                              price.rule.__typename === 'LabPricePracticeRetainerAdditionalArchRuleDTO'
                                  ? price.rule.material
                                  : null,
                          default_practice_price:
                              typeof price.default_practice_price_cents === 'number'
                                  ? price.default_practice_price_cents / 100
                                  : null,
                      }
                    : {}
            }
            onSubmit={async result => {
                const { name, default_practice_price } = result;
                const default_practice_price_cents =
                    typeof default_practice_price === 'number' ? default_practice_price * 100 : null;
                await submit({
                    name,
                    default_practice_price_cents,
                    rule: {
                        type: LabsGqlLabPriceRuleType.PracticeRetainerAdditionalArch,
                        material: result.material || null,
                    },
                });
            }}
            disabled={disabled}
        />
    );
};
