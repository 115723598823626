import type { MutationArgs } from './SmileLibraryCreationForm.graphql';
import { useCreateSmileLibraryMutation } from './SmileLibraryCreationForm.graphql';
import { type ExtractedSmileLibrary, extractSmileLibrariesFromDmeFile } from './SmileLibraryCreationForm.utils';
import type { SmileLibraryPreviewRendererResult } from './SmileLibraryPreviewRenderer';
import { apolloErrorMessage } from '@orthly/ui';
import { useSnackbar } from 'notistack';
import React from 'react';

export function useSmileLibraryCreationForm() {
    const { enqueueSnackbar } = useSnackbar();
    const { createSmileLibrary, loading, error, data } = useCreateSmileLibraryMutation();
    const [currentlySubmittingSmileLibrary, setCurrentlySubmittingSmileLibrary] = React.useState<boolean>(false);
    const [currentlyRenderingSmileLibraryPreview, setCurrentlyRenderingSmileLibraryPreview] =
        React.useState<boolean>(false);
    const [dmeArchive, setDmeArchive] = React.useState<File | undefined>(undefined);
    const [selectionError, setSelectionError] = React.useState<string | undefined>(undefined);
    const [extractingSmileLibrariesFromDme, setExtractingSmileLibrariesFromDme] = React.useState<boolean>(false);
    const [extractedSmileLibraries, setExtractedSmileLibraries] = React.useState<ExtractedSmileLibrary[] | undefined>(
        undefined,
    );
    const [selectedSmileLibrary, setSelectedSmileLibrary] = React.useState<ExtractedSmileLibrary | undefined>(
        undefined,
    );

    const [previewImageRenderCache, setPreviewImageRenderCache] = React.useState<
        Map<ExtractedSmileLibrary, SmileLibraryPreviewRendererResult | undefined>
    >(new Map());

    const updateImageRenderCacheCallback = React.useCallback(
        (smileLibrary: ExtractedSmileLibrary, cacheEntry: SmileLibraryPreviewRendererResult) => {
            previewImageRenderCache.set(smileLibrary, cacheEntry);
            setCurrentlyRenderingSmileLibraryPreview(false);
        },
        [previewImageRenderCache, setCurrentlyRenderingSmileLibraryPreview],
    );

    const processDmeCallback = React.useCallback(
        async (files: File[]) => {
            setExtractedSmileLibraries([]);
            setExtractingSmileLibrariesFromDme(true);
            try {
                const file = files[0];
                if (!file || !file.name.toLowerCase().endsWith('.dme')) {
                    throw new Error('The provided file is not a valid DME archive!');
                }
                const extractedSmileLibraries = await extractSmileLibrariesFromDmeFile(file);
                setExtractedSmileLibraries(extractedSmileLibraries);
                setExtractingSmileLibrariesFromDme(false);
                setDmeArchive(file);
            } catch (err) {
                if (err instanceof Error) {
                    enqueueSnackbar(err.message, { variant: 'error' });
                } else {
                    enqueueSnackbar('An unknown error occurred.', { variant: 'error' });
                }
            }
            setExtractingSmileLibrariesFromDme(false);
        },
        [enqueueSnackbar],
    );

    const createSmileLibraryCallback = React.useCallback(
        async (args: MutationArgs) => {
            try {
                const response = await createSmileLibrary({ variables: args });
                enqueueSnackbar('Smile library created!', { variant: 'success' });
                return response.data;
            } catch (err) {
                if (err instanceof Error) {
                    const message = apolloErrorMessage(err);
                    enqueueSnackbar(message, { variant: 'error' });
                } else {
                    enqueueSnackbar('Error during smile library creation.');
                }
            }
        },
        [createSmileLibrary, enqueueSnackbar],
    );

    return {
        createSmileLibraryCallback,
        loading,
        error,
        data,
        selectionError,
        setSelectionError,
        enqueueSnackbar,
        dmeArchive,
        setDmeArchive,
        extractedSmileLibraries,
        extractingSmileLibrariesFromDme,
        setExtractingSmileLibrariesFromDme,
        processDmeCallback,
        selectedSmileLibrary,
        setSelectedSmileLibrary,
        currentlySubmittingSmileLibrary,
        setCurrentlySubmittingSmileLibrary,
        currentlyRenderingSmileLibraryPreview,
        setCurrentlyRenderingSmileLibraryPreview,
        updateImageRenderCacheCallback,
        previewImageRenderCache,
        setPreviewImageRenderCache,
    };
}
