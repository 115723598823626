import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import { useListDisplayRuleOptsQuery } from '@orthly/graphql-react';
import { LabsGqlLabPriceRuleType } from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';
import React from 'react';

export const PracticeUnitForm: React.FC<LabPriceFormProps> = ({ submit, price, disabled }) => {
    const { data: displayOpts } = useListDisplayRuleOptsQuery({ fetchPolicy: 'cache-first' });

    const [isRetainer, setIsRetainer] = React.useState(
        price?.rule.__typename === 'LabPricePracticeUnitRuleDTO' && price.rule.unit_type === 'Retainer',
    );
    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.PracticeUnit>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.PracticeUnit>({
                unit_type: {
                    type: 'select',
                    label: 'Unit Type',
                    options: displayOpts?.listDisplayRuleOptions.unit_types ?? [],
                },
                unit_material: {
                    type: 'select',
                    label: 'Material',
                    options: displayOpts?.listDisplayRuleOptions.material_types ?? [],
                    optional: true,
                },
                default_practice_price: {
                    type: 'number',
                    label: 'Default Price',
                    optional: true,
                    hidden: !isRetainer,
                },
            })}
            initialValues={
                price
                    ? {
                          name: price.name,
                          unit_type:
                              price.rule.__typename === 'LabPricePracticeUnitRuleDTO' ? price.rule.unit_type : null,
                          unit_material:
                              price.rule.__typename === 'LabPricePracticeUnitRuleDTO' ? price.rule.unit_material : null,
                          default_practice_price:
                              typeof price.default_practice_price_cents === 'number'
                                  ? price.default_practice_price_cents / 100
                                  : null,
                      }
                    : {}
            }
            onSubmit={async result => {
                const default_practice_price_cents =
                    typeof result.default_practice_price === 'number' ? result.default_practice_price * 100 : null;
                await submit({
                    name: result.name,
                    default_practice_price_cents,
                    rule: {
                        type: LabsGqlLabPriceRuleType.PracticeUnit,
                        unit_type: result.unit_type || null,
                        unit_material: result.unit_material || null,
                    },
                });
            }}
            onChange={result => {
                setIsRetainer(result.unit_type === 'Retainer');
            }}
            disabled={disabled}
        />
    );
};
