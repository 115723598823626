import type { EditCapacityDialogProps } from '../CapacityStationsRoot.graphql';
import type { LabsGqlProductStationUnitMappingDto } from '@orthly/graphql-schema';
import { RootActionDialog } from '@orthly/ui';
import { Button, Grid } from '@orthly/ui-primitives';
import React from 'react';

type DeleteProductStationUnitMappingProps = EditCapacityDialogProps & {
    mapping: LabsGqlProductStationUnitMappingDto;
};

export const DeleteProductStationUnitMappingDialog: React.VFC<DeleteProductStationUnitMappingProps> = (
    props: DeleteProductStationUnitMappingProps,
) => {
    const { submitting, open, setOpen, submit, mapping } = props;
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Delete Mapping'}
            content={
                <Grid container style={{ padding: 20 }}>
                    <Grid item xs={12} style={{ paddingBottom: 10 }}>
                        Are you sure you want to delete this mapping?
                    </Grid>
                    <Grid item xs={12} container justifyContent={'flex-end'}>
                        <Button variant={'primary'} onClick={() => submit({ id: mapping.id })}>
                            Yes, Delete
                        </Button>
                        <Button variant={'secondary'} onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            }
            hideButton={true}
        />
    );
};
