import { usePalateState } from '../../state/Palate.reducer';
import { PalateOrdersList } from './PalateOrdersList';
import { usePalateListViewData } from './usePalateListViewData.graphql';
import { LabsGqlFilterComparator, LabsGqlLabOrderSortKey, LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { OrderFilterIdEnum } from '@orthly/shared-types';
import React from 'react';

/** This pattern is really not great but it's the cleanest way to curry the practiceId into the useItemData function */
function getUseItemData(practiceId: string) {
    return function useItemData(startIndex: number, stopIndex: number) {
        const allOrderFilters = usePalateState(s => s.orderFilters);
        const orderFilters = allOrderFilters?.filter(f => f.filter_id !== 'status');

        return usePalateListViewData(startIndex, stopIndex, {
            sort: { key: LabsGqlLabOrderSortKey.DeliveryDate, asc: false },
            filter: {
                status: LabsGqlLabOrderStatus.Delivered,
            },
            criteria: [
                {
                    filter_id: OrderFilterIdEnum.by_partner,
                    comparison_value: practiceId,
                    comparator: LabsGqlFilterComparator.Equals,
                },
                ...(orderFilters ?? []),
            ],
        });
    };
}

interface PalateCompleteOrdersListProps {
    practiceId: string;
}

export const PalateCompleteOrdersList: React.VFC<PalateCompleteOrdersListProps> = ({ practiceId }) => {
    return <PalateOrdersList useItemData={getUseItemData(practiceId)} />;
};
