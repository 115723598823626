import { RouterTabs } from '../../components/RouterTabs';
import { RouterUtils } from '../../utils/router/RouterUtils';
import { OrganizationDetailsRoot } from './OrganizationDetails/OrganizationDetailsRoot';
import { PaginatedParentsTable } from './OrganizationPaginatedTables/PaginatedParentsTable';
import { PaginatedPracticesTable } from './OrganizationPaginatedTables/PaginatedPracticesTable';
import { OrganizationsTable } from './OrganizationsTable';
import { PracticeDetailsRoot } from './PracticeDetails/PracticeDetailsRoot.graphql';
import { PracticesTable } from './PracticesTable';
import { ScanbodyOrdersTable } from './ScanbodyOrdersTable';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

const OrganizationsRoot: React.FC = () => {
    const basePath = `${RouterUtils.pathForScreen('accounts')}/organizations`;
    const { value: enablePaginatedOrgTableInOpsPortal = false } = useFeatureFlag('enablePaginatedOrgTableInOpsPortal');
    return (
        <Switch>
            <Route path={`${basePath}/:organizationId`} component={OrganizationDetailsRoot} />
            <Route path={`${basePath}`}>
                {enablePaginatedOrgTableInOpsPortal ? <PaginatedParentsTable /> : <OrganizationsTable />}
            </Route>
            <Route>
                <Redirect to={basePath} />
            </Route>
        </Switch>
    );
};

const PracticesRoot: React.FC = () => {
    const basePath = `${RouterUtils.pathForScreen('accounts')}/practices`;
    const { value: enablePaginatedOrgTableInOpsPortal = false } = useFeatureFlag('enablePaginatedOrgTableInOpsPortal');
    return (
        <Switch>
            <Route path={`${basePath}/:practiceId`} component={PracticeDetailsRoot} />
            <Route path={`${basePath}`}>
                {enablePaginatedOrgTableInOpsPortal ? <PaginatedPracticesTable /> : <PracticesTable />}
            </Route>
            <Route>
                <Redirect to={basePath} />
            </Route>
        </Switch>
    );
};

export const AccountsRoot: React.VFC = () => {
    const { value: enablePaginatedOrgTableInOpsPortal = false } = useFeatureFlag('enablePaginatedOrgTableInOpsPortal');
    const practiceLabel = enablePaginatedOrgTableInOpsPortal ? 'Practice Orgs' : 'Practices';
    const parentOrgLabel = enablePaginatedOrgTableInOpsPortal ? 'Parent Orgs' : 'Organizations';
    return (
        <RouterTabs
            defaultPath={'practices'}
            items={[
                { path: 'practices', label: practiceLabel, Component: PracticesRoot },
                { path: 'organizations', label: parentOrgLabel, Component: OrganizationsRoot },
                { path: 'scanbodies', label: 'Scan body Orders', Component: ScanbodyOrdersTable },
            ]}
        />
    );
};
