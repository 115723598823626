import { LabOrderItemConditionField } from '../../../../components/LabOrderItemConditionEditor';
import { RoutingRuleUtils } from '../../../../components/RoutingRule.util';
import { useQuery } from '@apollo/client';
import { graphql, type CapacityStationDtoFragment } from '@orthly/graphql-inline-react';
import type { LabsGqlCreateCapacityStationRuleArgs } from '@orthly/graphql-schema';
import { LabsGqlWorkOrderTypeEnum } from '@orthly/graphql-schema';
import { LoadBlocker, QuickForm } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

const CapacityStationRuleFormListLabtracProductCodes_Query = graphql(`
    query CapacityStationRuleFormListLabtracProductCodes_Query {
        listLabtracProductCodes
    }
`);

type CapacityStationRuleFormFields = Pick<
    LabsGqlCreateCapacityStationRuleArgs,
    | 'name'
    | 'capacity_station_id'
    | 'work_order_type'
    | 'matching_logic_json'
    | 'default_unit_limit'
    | 'lab_product_codes'
>;

const INITIAL_VALUES: CapacityStationRuleFormFields = {
    name: ``,
    capacity_station_id: ``,
    work_order_type: LabsGqlWorkOrderTypeEnum.Design,
    default_unit_limit: 0,
};

interface CapacityStationRuleFormProps {
    loading: boolean;
    stations: CapacityStationDtoFragment[];
    onSubmit: (result: CapacityStationRuleFormFields) => void;
    initialValues?: CapacityStationRuleFormFields;
}

export const CapacityStationRuleForm: React.VFC<CapacityStationRuleFormProps> = ({
    loading,
    stations,
    onSubmit,
    initialValues,
}) => {
    const { data: labtracProductCodes, loading: labtracProductCodesLoading } = useQuery(
        CapacityStationRuleFormListLabtracProductCodes_Query,
    );

    return (
        <LoadBlocker blocking={loading || labtracProductCodesLoading}>
            <QuickForm<CapacityStationRuleFormFields>
                onSubmit={onSubmit}
                initialValues={initialValues ?? INITIAL_VALUES}
                fields={{
                    name: {
                        label: 'Name',
                        type: 'text',
                    },
                    capacity_station_id: {
                        label: 'Station',
                        type: 'select',
                        options: _.sortBy(stations, m => m.name.trim().toLowerCase()).map(({ id, name }) => ({
                            value: id,
                            label: name,
                        })),
                    },
                    work_order_type: {
                        label: 'Work order type',
                        type: 'select',
                        options: Object.values(LabsGqlWorkOrderTypeEnum),
                    },
                    matching_logic_json: {
                        label: 'Condition',
                        type: 'custom',
                        component: LabOrderItemConditionField,
                        validation: RoutingRuleUtils.ItemConditionValidation,
                        optional: true,
                    },
                    lab_product_codes: {
                        label: 'Labtrac Product Codes',
                        type: 'multiselect',
                        options: labtracProductCodes?.listLabtracProductCodes ?? [],
                        optional: true,
                    },
                    default_unit_limit: {
                        label: 'Default Unit Limit',
                        type: 'number',
                    },
                }}
            />
        </LoadBlocker>
    );
};
