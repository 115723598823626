import { AssignmentAvatar } from './AssignmentAvatar';
import { AssignmentSelectMenu } from './AssignmentSelectMenu';
import type { TaskButtonTask } from './TaskButton.util';
import { TaskButtonBase } from './TaskButtonBase';
import { useAnchor } from './useAnchor';
import { useApolloClient } from '@apollo/client';
import type { LabsGqlAssignFulfillmentTaskMutationVariables } from '@orthly/graphql-operations';
import { AssignFulfillmentTaskDocument } from '@orthly/graphql-react';
import { LoadBlocker, useChangeSubmissionFn, RootActionDialog, apolloErrorMessage } from '@orthly/ui';
import { FlossPalette, Button, Grid, MenuItem, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

type TaskErr = { ok: false; id: string; message: string };
type TaskErrResult = { user_id: string | null; errors: TaskErr[] };

interface AssignTasksBulkButtonProps {
    tasks: TaskButtonTask[];
    setSelectedTaskIds: (ids: string[]) => void;
    refetch: () => Promise<unknown>;
}

export const AssignTasksBulkButton: React.VFC<AssignTasksBulkButtonProps> = ({
    tasks,
    refetch,
    setSelectedTaskIds,
}) => {
    const [failedTasks, setFailedTasks] = React.useState<TaskErrResult | undefined>(undefined);
    const client = useApolloClient();
    const assignBulk = React.useCallback(
        async (user_id: string | null, override: boolean): Promise<string | undefined> => {
            const operationsRaw = tasks.map<LabsGqlAssignFulfillmentTaskMutationVariables | undefined>(task => {
                if ((task.assigned_user?.id ?? null) === user_id || task.has_closeout) {
                    return undefined;
                }
                const last_user_id = task.assigned_user?.id ?? null;
                const data = {
                    last_assigned_user_id: last_user_id,
                    assigned_user_id: user_id,
                    order_id: task.order_id,
                    task_id: task.id,
                    override_validation: override,
                };
                return { data };
            });
            const neededOperations = _.compact(operationsRaw);
            const baseActionVerb = user_id ? 'Assign' : 'Unassign';
            if (
                neededOperations.length === 0 ||
                !window.confirm(`${baseActionVerb} ${neededOperations.length} tasks?`)
            ) {
                return undefined;
            }
            type Result = { ok: true } | TaskErr;
            const result = await Promise.all(
                neededOperations.map(async (variables): Promise<Result> => {
                    return client
                        .mutate({ variables, mutation: AssignFulfillmentTaskDocument })
                        .then((): Result => ({ ok: true }))
                        .catch((e): Result => {
                            return {
                                ok: false,
                                id: variables.data.task_id,
                                message: apolloErrorMessage(e, ''),
                            };
                        });
                }),
            );
            const errors = result.filter((r): r is TaskErr => !r.ok);
            setSelectedTaskIds(errors.map(err => err.id));
            if (errors.length > 0) {
                setFailedTasks({ user_id, errors });
                return undefined;
            }
            return `${baseActionVerb}ed ${result.length - errors.length} tasks.`;
        },
        [tasks, client, setSelectedTaskIds],
    );
    const [anchor, setAnchor] = useAnchor();
    type Res = string | undefined; // just needed this for formatter to not break linter
    type Params = [string | null, boolean];
    const { submit: assignTask, submitting: assigningTask } = useChangeSubmissionFn<Res, Params>(assignBulk, {
        successMessage: res => [res, {}],
        onError: () => refetch(),
        onSuccess: async () => {
            await refetch();
            setAnchor(null);
        },
    });
    const { soleAssignedUser, taskAssignees } = React.useMemo(() => {
        const taskAssignees = tasks.flatMap(t => (t.assigned_user ? [t.assigned_user] : []));
        const uniqAssignedUsers = _.uniqBy(taskAssignees, a => a.id);
        const soleAssignedUser =
            uniqAssignedUsers.length === 1 && taskAssignees.length === tasks.length ? taskAssignees[0] ?? null : null;
        return { soleAssignedUser, taskAssignees };
    }, [tasks]);
    return (
        <Grid item onClick={ev => ev.stopPropagation()}>
            <RootActionDialog
                loading={assigningTask}
                open={!!failedTasks}
                setOpen={() => setFailedTasks(undefined)}
                title={`Some tasks failed`}
                hideButton={true}
                content={
                    <Grid container direction={'column'} style={{ gap: '8px' }}>
                        <Text variant={'body2'}>
                            {failedTasks?.errors?.length} tasks failed to assign. Click below to retry, and override
                            safety checks.
                        </Text>
                        <Grid style={{ overflowY: 'auto' }}>
                            {failedTasks?.errors.map(taskInfo => {
                                const task = tasks.find(task => task.id === taskInfo.id);
                                if (!task) {
                                    return null;
                                }

                                return (
                                    <Grid
                                        key={task.id}
                                        container
                                        direction={'column'}
                                        style={{ borderBottom: '1px solid black' }}
                                    >
                                        <Text variant={'body1'}>
                                            <a target={'_blank'} rel={'noreferrer'} href={`/orders/${task.order_id}`}>
                                                {task.type}
                                            </a>
                                        </Text>
                                        <Text variant={'caption'}>{taskInfo.message}</Text>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Grid container>
                            <Button
                                variant={'primary'}
                                onClick={async () => {
                                    await assignTask(failedTasks?.user_id ?? null, true);
                                    setFailedTasks(undefined);
                                }}
                            >
                                Retry Failed Tasks
                            </Button>
                        </Grid>
                    </Grid>
                }
            />
            <TaskButtonBase
                tooltip={`${taskAssignees.length > 0 ? 'Re-assign' : 'Assign'} ${tasks.length} tasks`}
                onClick={ev => setAnchor(ev.currentTarget)}
                disabled={tasks.length === 0}
            >
                <LoadBlocker blocking={assigningTask}>
                    <AssignmentAvatar user={soleAssignedUser} disabled={tasks.length === 0} />
                </LoadBlocker>
            </TaskButtonBase>
            <AssignmentSelectMenu
                anchor={anchor}
                onClose={() => setAnchor(null)}
                assignedUser={soleAssignedUser}
                assignTo={user => assignTask(user?.id ?? null, false)}
                clearAssignmentItem={
                    taskAssignees.length === 0 || soleAssignedUser ? undefined : (
                        <MenuItem
                            key={'clear-assignments'}
                            style={{ borderBottom: `1px solid ${FlossPalette.DARK_TAN}` }}
                            onClick={() => assignTask(null, false)}
                        >
                            Clear Assignment{tasks.length > 1 ? 's' : ''}
                        </MenuItem>
                    )
                }
            />
        </Grid>
    );
};
