import type { MaterialsFileEntry } from '@orthly/forceps';
import { DcmManager, DmeParser } from '@orthly/forceps';
import type { ToothNumber } from '@orthly/items';
import { ToothNumberSchema } from '@orthly/items';
import JSZip from 'jszip';
import { basename } from 'path-browserify';

export class ExtractedSmileLibrary {
    constructor(
        public materialsFileEntry: MaterialsFileEntry,
        public templates: ExtractedSmileLibraryToothTemplate[],
    ) {}

    get identifier(): string {
        return this.materialsFileEntry.name ?? this.materialsFileEntry.id;
    }
}

export class ExtractedSmileLibraryToothTemplate {
    constructor(
        public templatePath: string,
        public xmlString: string,
        public dcm: DcmManager,
        public unn: ToothNumber,
    ) {}
}

export enum SmileLibraryExtractionErrorMessage {
    InvalidFileName = 'The provided file is not a valid DME archive!',
    CouldNotParse = 'Could not parse DME contents!',
    NoSmileLibraries = 'DME does not contain any smile libraries!',
    BadToothTemplate = 'Bad tooth template in the DME file.',
    BadToothTemplateFileName = 'Could not determine UNN for tooth template file ',
}

export async function extractSmileLibrariesFromDmeFile(file: File): Promise<ExtractedSmileLibrary[]> {
    if (!file || !file.name.toLowerCase().endsWith('.dme')) {
        throw new Error(SmileLibraryExtractionErrorMessage.InvalidFileName);
    }
    const buffer: Uint8Array = new Uint8Array(await file.arrayBuffer());
    const parser = new DmeParser(buffer);
    const dmeContents = await parser.parse();
    if (!dmeContents) {
        throw new Error(SmileLibraryExtractionErrorMessage.CouldNotParse);
    }

    const extractedSmileLibraries: ExtractedSmileLibrary[] = dmeContents
        .filter(m => m.section === 'SmileLibraryList')
        .map(m => new ExtractedSmileLibrary(m, []));

    if (extractedSmileLibraries.length === 0) {
        throw new Error(SmileLibraryExtractionErrorMessage.NoSmileLibraries);
    }

    const zipArchive = await JSZip.loadAsync(buffer);
    await Promise.all(
        Object.keys(zipArchive.files).map(async templatePath => {
            const zippedFile = zipArchive.files[templatePath];
            if (!zippedFile || !templatePath.toLowerCase().endsWith('.dcm')) {
                return;
            }

            const extractedSmileLibrary = extractedSmileLibraries
                .filter(
                    e =>
                        e.materialsFileEntry.libPath &&
                        templatePath.startsWith(e.materialsFileEntry.libPath.replaceAll('\\', '/')),
                )
                .at(0);

            if (!extractedSmileLibrary) {
                return;
            }

            const xmlString = await zippedFile.async('string');
            const dcm = DcmManager.tryBuildDcmManager(xmlString);
            if (!dcm) {
                throw new Error(SmileLibraryExtractionErrorMessage.BadToothTemplate);
            }

            const regex = /^.*unn([1-9]|[1-3][0-9]).dcm$/i;
            const match = templatePath.match(regex);
            const group = match?.at(1);
            const unn = ToothNumberSchema.safeParse(group !== undefined ? parseInt(group) : undefined);

            if (!unn.success) {
                const templateName = basename(templatePath);
                const errorMessage = SmileLibraryExtractionErrorMessage.BadToothTemplateFileName + templateName;
                throw new Error(errorMessage);
            }

            extractedSmileLibrary.templates.push({
                templatePath,
                xmlString,
                dcm,
                unn: unn.data,
            });
        }),
    );
    return extractedSmileLibraries;
}
