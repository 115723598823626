import { useScrollToControl } from '../../state/GuidedWaxupState';
import { Button, styled, Icon, type ButtonProps } from '@orthly/ui-primitives';
import React from 'react';

const StyledButton = styled(Button)(({ theme }) => ({
    minWidth: 'fit-content',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    position: 'absolute',

    '&&': {
        padding: '0.25rem 1rem',
        height: 'auto',
    },

    '& > svg': {
        fontSize: '0.9rem',
    },

    '& > .default-text': {
        fontSize: '0.75rem',
        marginRight: theme.spacing(1),
    },

    [`& > .revealed-text`]: {
        fontSize: '0.75rem',
        marginRight: theme.spacing(1),
        display: 'none',
    },

    '&:hover': {
        [`& > .default-text`]: {
            display: 'none',
        },
        [`& > .revealed-text`]: {
            display: 'inline',
        },
    },
}));

export const ScrollDownButton: React.VFC<
    {
        revealedText: string;
    } & Omit<ButtonProps, 'variant'>
> = ({ revealedText, ...props }) => {
    const { at_bottom, go_down } = useScrollToControl();

    return !at_bottom ? (
        <StyledButton variant={'primary'} onClick={go_down} {...props}>
            <span className={'default-text'}>{'Scroll'}</span>
            <span className={'revealed-text'}>{revealedText}</span>
            <Icon icon={'ArrowDownIcon'} />
        </StyledButton>
    ) : null;
};
