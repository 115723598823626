import type { MappingRow } from '../ProductStationUnitMappingsRoot.graphql';
import { EditMappingRowActions } from './EditMappingRowActions.graphql';
import MUITable from '@orthly/mui-table';
import React from 'react';

export interface MappingsTableProps {
    mappingRows: MappingRow[];
    loadingMappings: boolean;
    setOpenAddMappingDialog: (open: boolean) => void;
    refetchMappings: () => Promise<unknown>;
}

export const ProductStationUnitMappingTable: React.VFC<MappingsTableProps> = (args: MappingsTableProps) => {
    const { mappingRows, loadingMappings, setOpenAddMappingDialog, refetchMappings } = args;

    return (
        <MUITable<MappingRow>
            title={`Product Station Unit Mappings`}
            data={mappingRows}
            loading={loadingMappings}
            displayOptions={{
                download: true,
                filter: true,
                sort: true,
                filterValues: true,
                fixedSearch: true,
            }}
            actions={{
                global: [
                    {
                        icon: 'add',
                        position: 'toolbar',
                        onClick: () => setOpenAddMappingDialog(true),
                        tooltip: 'Add Product Station Unit Mapping',
                    },
                    {
                        icon: 'refresh',
                        position: 'toolbar',
                        onClick: () => refetchMappings().catch(console.error),
                        tooltip: 'Refresh',
                    },
                ],
            }}
            columns={[
                {
                    name: 'ID',
                    render: 'id',
                    type: 'string',
                    hidden: true,
                    defaultSort: 'asc',
                },
                {
                    name: 'Station ID',
                    render: 'station_id',
                    type: 'string',
                },
                {
                    name: 'Station Name',
                    render: 'station_name',
                    type: 'string',
                },
                {
                    name: 'Product Header',
                    render: 'product_header',
                    type: 'string',
                },
                {
                    name: 'Units',
                    render: 'units',
                    type: 'numeric',
                },
                {
                    name: 'Unit Type',
                    render: 'station_unit_type',
                    type: 'string',
                },
                {
                    title: 'Actions',
                    name: 'actions',
                    render: row => <EditMappingRowActions row={row} refetchMappings={refetchMappings} />,
                    type: 'boolean',
                },
            ]}
        />
    );
};
