import { SimpleDropzone, uploadFilesToGCS, useFirebaseMultiFileUpload } from '../FirebaseUpload';
import { useSmileLibraryCreationForm } from './SmileLibraryCreationForm.hooks';
import { SmileLibraryPreviewRenderer } from './SmileLibraryPreviewRenderer';
import type { ApolloQueryResult } from '@apollo/client';
import type { CadSmileLibraryList_QueryQuery } from '@orthly/graphql-inline-react';
import { DesignStorageConfigs, getFullStoragePath } from '@orthly/shared-types';
import { LoadBlocker, OrthlyBrowserConfig, RootActionDialog, StackX, StackY } from '@orthly/ui';
import type { SelectChangeEvent } from '@orthly/ui-primitives';
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@orthly/ui-primitives';
import { join, basename } from 'path-browserify';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

interface SmileLibraryCreationFormProps {
    existingNames: Set<string>;
    onSuccess: () => Promise<ApolloQueryResult<CadSmileLibraryList_QueryQuery>>;
}

export const SmileLibraryCreationForm: React.FC<SmileLibraryCreationFormProps> = ({
    existingNames,
    onSuccess,
    children,
}) => {
    const [open, setOpen] = React.useState(false);
    const {
        createSmileLibraryCallback,
        selectionError,
        setSelectionError,
        processDmeCallback,
        extractingSmileLibrariesFromDme,
        extractedSmileLibraries,
        selectedSmileLibrary,
        dmeArchive,
        setDmeArchive,
        setSelectedSmileLibrary,
        currentlySubmittingSmileLibrary,
        setCurrentlySubmittingSmileLibrary,
        currentlyRenderingSmileLibraryPreview,
        setCurrentlyRenderingSmileLibraryPreview,
        updateImageRenderCacheCallback,
        enqueueSnackbar,
        previewImageRenderCache,
        setPreviewImageRenderCache,
    } = useSmileLibraryCreationForm();

    const storagePathConfig = getFullStoragePath(OrthlyBrowserConfig.env, DesignStorageConfigs.smileLibraries);
    const [uploadFn] = useFirebaseMultiFileUpload(storagePathConfig, []);

    React.useEffect(() => {
        if (!open) {
            setDmeArchive(undefined);
            setSelectedSmileLibrary(undefined);
            setPreviewImageRenderCache(new Map());
        }
    }, [open, setDmeArchive, setPreviewImageRenderCache, setSelectedSmileLibrary]);

    const smileLibraryPreviewImage = selectedSmileLibrary
        ? previewImageRenderCache.get(selectedSmileLibrary)
        : undefined;

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!selectedSmileLibrary || currentlySubmittingSmileLibrary || !smileLibraryPreviewImage) {
            return;
        }

        setCurrentlySubmittingSmileLibrary(true);

        const libraryId = uuidv4();
        const revisionId = uuidv4();
        try {
            const filesToSubmit: File[] = [];
            const templatesToSubmit: { dcmPath: string; unn: number }[] = [];
            const previewImagePath = join(storagePathConfig.path, libraryId, revisionId, 'preview.png');
            const previewImageFile = new File(
                [smileLibraryPreviewImage.imageFileData],
                join(libraryId, revisionId, 'preview.png'),
            );
            filesToSubmit.push(previewImageFile);

            for (const template of selectedSmileLibrary.templates) {
                const dcmStorageConfigRelativePath = join(libraryId, revisionId, basename(template.templatePath));
                filesToSubmit.push(new File([template.xmlString], dcmStorageConfigRelativePath));
                templatesToSubmit.push({
                    dcmPath: join(storagePathConfig.path, dcmStorageConfigRelativePath),
                    unn: template.unn,
                });
            }

            await uploadFilesToGCS({ uploadFn, filesToSubmit });
            await createSmileLibraryCallback({
                libraryId,
                revisionId,
                previewImagePath,
                name: selectedSmileLibrary.identifier,
                templates: templatesToSubmit,
            });
            await onSuccess();
            setOpen(false);
        } catch (_err) {
            enqueueSnackbar('Error encountered while submitting smile library!', { variant: 'error' });
        }
        setCurrentlySubmittingSmileLibrary(false);
    };

    const handleSmileLibrarySelectionChange = (event: SelectChangeEvent) => {
        const smileLibrary = extractedSmileLibraries?.filter(e => e.identifier === event.target.value).at(0);
        if (!smileLibrary) {
            return;
        }

        if (existingNames.has(smileLibrary.identifier)) {
            setSelectionError('Invalid selection - smile library already exists.');
        } else {
            setSelectionError(undefined);
        }

        const previewImage = previewImageRenderCache.get(smileLibrary);
        if (!previewImage) {
            setCurrentlyRenderingSmileLibraryPreview(true);
            const renderer = new SmileLibraryPreviewRenderer(smileLibrary);
            renderer.render(updateImageRenderCacheCallback);
        }
        setSelectedSmileLibrary(smileLibrary);
    };

    return (
        <StackX sx={{ justifyContent: 'right' }}>
            {children}
            <StackY>
                <RootActionDialog
                    title={'Create Smile Library'}
                    open={open}
                    setOpen={setOpen}
                    loading={currentlySubmittingSmileLibrary}
                    buttonText={'Add Smile Library'}
                    content={
                        <LoadBlocker
                            blocking={extractingSmileLibrariesFromDme || currentlyRenderingSmileLibraryPreview}
                        >
                            <SimpleDropzone
                                preUploadText={`Upload a DME file containing a Smile Library`}
                                wrapperStyle={{ minHeight: 40, marginTop: 0, padding: 0 }}
                                options={{ onDropAccepted: processDmeCallback, multiple: false }}
                            />
                            {dmeArchive && (
                                <StackY onSubmit={handleSubmit} sx={{ flex: 'auto', border: 'none' }}>
                                    {smileLibraryPreviewImage && (
                                        <img
                                            src={smileLibraryPreviewImage.imageURL}
                                            alt={'Smile Library Preview Image'}
                                        />
                                    )}

                                    <FormControl fullWidth>
                                        <InputLabel>Select Library From DME</InputLabel>
                                        <Select
                                            value={selectedSmileLibrary?.materialsFileEntry.name}
                                            onChange={handleSmileLibrarySelectionChange}
                                        >
                                            {extractedSmileLibraries?.map(e => (
                                                <MenuItem key={e.identifier} value={e.identifier}>
                                                    {e.identifier}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {selectionError && <FormHelperText>{selectionError}</FormHelperText>}
                                    <StackX>
                                        <Button
                                            disabled={
                                                !!selectionError ||
                                                !selectedSmileLibrary ||
                                                currentlySubmittingSmileLibrary
                                            }
                                            type={'submit'}
                                            variant={'primary'}
                                            sx={{ flex: 'auto' }}
                                            onClick={handleSubmit}
                                        >
                                            Submit
                                        </Button>
                                    </StackX>
                                </StackY>
                            )}
                        </LoadBlocker>
                    }
                />
            </StackY>
        </StackX>
    );
};
