import type { PoolRow } from '../CapacityStationPoolsRoot.graphql';
import { EditPoolRowActions } from './EditPoolRowActions.graphql';
import MUITable from '@orthly/mui-table';
import { Tooltip } from '@orthly/ui-primitives';
import React from 'react';

export interface PoolsTableProps {
    poolRows: PoolRow[];
    loadingPools: boolean;
    refetchPools: () => Promise<unknown>;
}

export const CapacityStationPoolTable: React.VFC<PoolsTableProps> = (args: PoolsTableProps) => {
    const { poolRows, loadingPools, refetchPools } = args;
    return (
        <MUITable<PoolRow>
            title={`Capacity Station Pools`}
            data={poolRows}
            loading={loadingPools}
            displayOptions={{
                download: true,
                filter: true,
                sort: true,
                filterValues: true,
                fixedSearch: true,
            }}
            actions={{
                global: [
                    {
                        icon: 'refresh',
                        position: 'toolbar',
                        onClick: () => refetchPools().catch(console.error),
                        tooltip: 'Refresh',
                    },
                ],
            }}
            columns={[
                {
                    name: 'ID',
                    render: 'id',
                    type: 'string',
                    hidden: true,
                    defaultSort: 'asc',
                },
                {
                    name: 'Rule Name',
                    field: 'rule_name',
                    render: ({ rule_name }) => (
                        <Tooltip title={rule_name}>
                            <div>
                                {rule_name !== undefined && rule_name.length > 100
                                    ? `${rule_name.slice(0, 75)}...`
                                    : rule_name}
                            </div>
                        </Tooltip>
                    ),
                },
                {
                    name: 'Station Name',
                    render: 'station_name',
                    type: 'string',
                },
                {
                    name: 'Start Date',
                    render: 'start_date',
                    type: 'datetime',
                },
                {
                    name: 'End Date',
                    render: 'end_date',
                    type: 'datetime',
                },
                {
                    name: 'Unit Limit',
                    render: 'unit_limit',
                    type: 'numeric',
                },
                {
                    title: 'Actions',
                    name: 'actions',
                    render: row => <EditPoolRowActions row={row} refetchPools={refetchPools} />,
                    type: 'boolean',
                },
            ]}
        />
    );
};
