import { RouterTabs } from '../../../../components/RouterTabs';
import { CapacityStationPoolsRoot } from './CapacityStations/CapacityStationPoolsRoot.graphql';
import { CapacityStationRulesRoot } from './CapacityStations/CapacityStationRulesRoot.graphql';
import { CapacityStationsRoot } from './CapacityStations/CapacityStationsRoot.graphql';
import { ProductStationUnitMappingsRoot } from './CapacityStations/ProductStationUnitMappingsRoot.graphql';
import { ManualLabAcceptOrderPage } from './ProductionOrder/ManualLabAccept.graphql';

export const ProductionSchedulerRoot: React.FC = () => {
    return (
        <RouterTabs
            items={[
                { path: 'capacity-stations', label: 'Capacity Stations', Component: CapacityStationsRoot },
                {
                    path: 'capacity-station-rules',
                    label: 'Capacity Station Rules',
                    Component: CapacityStationRulesRoot,
                },
                {
                    path: 'product-station-unit-mappings',
                    label: 'Product Station Unit Mappings',
                    Component: ProductStationUnitMappingsRoot,
                },
                {
                    path: 'capacity-station-pools',
                    label: 'Capacity Station Pools',
                    Component: CapacityStationPoolsRoot,
                },
                {
                    path: 'manual-level-loading',
                    label: 'Manual Intake',
                    Component: ManualLabAcceptOrderPage,
                },
            ]}
        />
    );
};
