import { useMutation, useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useChangeSubmissionFn } from '@orthly/ui';

const GetIteroCompanyQuery = graphql(`
    query GetIteroCompany($practiceId: String!) {
        getIteroCompanyByPracticeId(practice_id: $practiceId) {
            id
            itero_company_id
        }
    }
`);

const UpdateIteroCompanyMutation = graphql(`
    mutation UpdateOrCreateIteroCompany($iteroCompanyId: String!, $practiceId: String!) {
        updateOrCreateIteroCompany(itero_company_id: $iteroCompanyId, practice_id: $practiceId) {
            id
            itero_company_id
        }
    }
`);

export const useIteroCompany = ({ practiceId }: { practiceId: string }) => {
    const { data, loading } = useQuery(GetIteroCompanyQuery, { variables: { practiceId } });
    const [updateIteroCompany] = useMutation(UpdateIteroCompanyMutation);
    const { submit } = useChangeSubmissionFn(
        (iteroCompanyId: string) => updateIteroCompany({ variables: { practiceId, iteroCompanyId } }),
        { successMessage: () => ['Itero Company updated', {}] },
    );

    return {
        iteroCompanyId: data?.getIteroCompanyByPracticeId?.itero_company_id,
        updateIteroCompany: submit,
        loading,
    };
};
