import { getOrderIdsFromIndexes } from '../../../Orders/components/OrdersListView/getOrderIdsFromIndexes';
import { useOrderIdsForListView } from '../../../Orders/components/OrdersListView/useOrderIdsForListView.graphql';
import { useOrdersListViewEntries } from '../../../Orders/components/OrdersListView/useOrdersListViewEntries.graphql';
import type {
    AdminOrderIdsForListView_QueryQueryVariables,
    AdminPalateListViewDataOrderAdminListViewEntry_FragmentFragment,
} from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { VIRTUAL_LIST_LOADING, type VirtualListHookResult } from '@orthly/veneer';

const AdminPalateListViewDataOrderAdminListViewEntry_Fragment = graphql(`
    fragment AdminPalateListViewDataOrderAdminListViewEntry_Fragment on OrderAdminListViewEntry {
        id
        ...AdminPalateOrderListItem_Fragment
    }
`);

export interface PalateLiastViewDataItem {
    order: AdminPalateListViewDataOrderAdminListViewEntry_FragmentFragment;
}

export function usePalateListViewData(
    startIndex: number,
    stopIndex: number,
    variables: AdminOrderIdsForListView_QueryQueryVariables = {},
): VirtualListHookResult<PalateLiastViewDataItem> {
    const { data: idsData, loading: idsLoading, error: idsError } = useOrderIdsForListView(variables);

    const visibleEntries = getOrderIdsFromIndexes(idsData, startIndex, stopIndex);

    const { orders, loading: ordersLoading, error: ordersError } = useOrdersListViewEntries(visibleEntries);

    const listItems = orders.map(rawOrder => {
        if (!rawOrder) {
            return undefined;
        }

        const order = getFragmentData(AdminPalateListViewDataOrderAdminListViewEntry_Fragment, rawOrder);
        return {
            order,
            key: order.id,
        };
    });

    return {
        listItems,
        itemsLoading: ordersLoading,
        error: idsError || ordersError,
        listItemCount: idsLoading && idsData.length === 0 ? VIRTUAL_LIST_LOADING : idsData.length,
    };
}
