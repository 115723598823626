import { NotificationLogsTableContent } from './NotificationLogsTableContent';
import { useGetNotificationLogsWithEvents } from './graphql/useGetNotificationLogsWithEvents.graphql';
import { Tab, Tabs } from '@orthly/ui-primitives';
import React from 'react';

interface NotificationLogsTableProps {
    doctorPreferencesId?: string;
}

export const NotificationLogsTable: React.FC<NotificationLogsTableProps> = ({ doctorPreferencesId }) => {
    const [page, setPage] = React.useState(0); // Material-UI's page starts from 0
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [activeTab, setActiveTab] = React.useState<string>('all');

    const {
        entities: data,
        totalCount: totalRows,
        loading,
        refetch,
    } = useGetNotificationLogsWithEvents(page, rowsPerPage, activeTab, doctorPreferencesId);

    React.useEffect(() => {
        const fetchData = async () => {
            await refetch();
        };

        void fetchData();
    }, [page, rowsPerPage, refetch]);

    const tabs = [
        { name: 'All', value: 'all' },
        { name: 'Email', value: 'email' },
        { name: 'SMS', value: 'sms' },
    ];

    return (
        <>
            <Tabs indicatorColor={'secondary'} textColor={'inherit'} value={activeTab}>
                {tabs.map(tab => (
                    <Tab
                        key={tab.value}
                        value={tab.value}
                        onClick={() => {
                            setPage(0);
                            setActiveTab(tab.value);
                        }}
                        label={tab.name}
                    />
                ))}
            </Tabs>
            <NotificationLogsTableContent
                loading={loading}
                data={data}
                totalRows={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
            />
        </>
    );
};
