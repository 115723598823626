import { CapacityStationPoolTable } from './CapacityStationTables/CapacityStationPoolTable';
import { useQuery } from '@apollo/client';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import type { CapacityStationPoolDtoFragment } from '@orthly/graphql-inline-react';
import { ActionCard } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

const CapacityStations_Fragment = graphql(`
    fragment CapacityStationDTO on CapacityStationDTO {
        id
        created_at
        updated_at
        organization_id
        name
        unit_type
    }
`);

const CapacityStationRules_Fragment = graphql(`
    fragment CapacityStationRuleDTO on CapacityStationRuleDTO {
        id
        created_at
        updated_at
        capacity_station_id
        name
        work_order_type
        default_unit_limit
        default_unit_granularity
        matching_logic_json
        lab_product_codes
    }
`);

const CapacityStationPools_Fragment = graphql(`
    fragment CapacityStationPoolDTO on CapacityStationPoolDTO {
        id
        created_at
        updated_at
        capacity_station_rule_id
        last_event_id
        start_date
        end_date
        unit_limit
        deleted_at
    }
`);

const AllCapacityStations_Query = graphql(`
    query AllCapacityStations {
        getAllCapacityStations {
            ...CapacityStationDTO
        }
    }
`);

const AllCapacityStationRules_Query = graphql(`
    query AllCapacityStationRules {
        getAllCapacityStationRules {
            ...CapacityStationRuleDTO
        }
    }
`);

export const AllActiveCapacityStationPools_Query = graphql(`
    query AllActiveCapacityStationPools {
        getAllActiveCapacityStationPools {
            ...CapacityStationPoolDTO
        }
    }
`);

export type PoolRow = CapacityStationPoolDtoFragment & {
    station_name?: string;
    rule_name?: string;
};

export const CapacityStationPoolsRoot: React.FC = () => {
    const { data: stations } = useQuery(AllCapacityStations_Query, {
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const stationsResult = getFragmentData(CapacityStations_Fragment, stations?.getAllCapacityStations);

    const { data: rules } = useQuery(AllCapacityStationRules_Query, {
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const rulesResult = getFragmentData(CapacityStationRules_Fragment, rules?.getAllCapacityStationRules);

    const {
        loading: loadingPools,
        data: pools,
        error: poolsError,
        refetch: refetchPools,
    } = useQuery(AllActiveCapacityStationPools_Query, {
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const poolsResult = getFragmentData(CapacityStationPools_Fragment, pools?.getAllActiveCapacityStationPools);

    const poolRows: PoolRow[] = React.useMemo(
        () =>
            (poolsResult ?? []).map(pool => {
                const rule = rulesResult?.find(({ id }) => id === pool.capacity_station_rule_id);
                const station = stationsResult?.find(({ id }) => id === rule?.capacity_station_id);
                return {
                    ...pool,
                    station_name: station?.name ?? undefined,
                    rule_name: rule?.name ?? undefined,
                };
            }),
        [poolsResult, rulesResult, stationsResult],
    );

    return (
        <Grid container>
            <Grid item xs={12}>
                <CapacityStationPoolTable poolRows={poolRows} loadingPools={loadingPools} refetchPools={refetchPools} />
            </Grid>
            {poolsError && (
                <ActionCard title={'Error retrieving capacity station pools'} subtitle={''} variant={'alert'} />
            )}
        </Grid>
    );
};
