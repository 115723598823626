import type { ExtractedSmileLibrary } from './SmileLibraryCreationForm.utils';
import { createStudioRestorativeMaterial } from '@orthly/dentin';
import * as THREE from 'three';

const DEFAULT_WIDTH = 400 as const;

export interface SmileLibraryPreviewRendererResult {
    imageURL: string;
    imageFileData: Blob;
}

export class SmileLibraryPreviewRenderer {
    private readonly meshes: THREE.Mesh<THREE.BufferGeometry, THREE.Material>[];
    private readonly camera: THREE.OrthographicCamera;
    private readonly sceneWidth: number;
    private readonly sceneHeight: number;
    private scene: THREE.Scene = new THREE.Scene();
    private renderer: THREE.WebGLRenderer;
    private disposed: boolean = false;

    constructor(
        private readonly extractedSmileLibrary: ExtractedSmileLibrary,
        private readonly renderWidth: number = DEFAULT_WIDTH,
    ) {
        this.meshes = this.createMeshes();
        this.scene = this.createScene();

        const { camera, width, height } = this.createCamera();
        this.camera = camera;
        this.sceneWidth = width;
        this.sceneHeight = height;

        this.scene.add(this.camera);
        this.renderer = this.createRenderer();
    }

    private createRenderer() {
        const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
        const aspectRatio = this.sceneHeight / this.sceneWidth;
        const renderHeight = Math.ceil(this.renderWidth * aspectRatio);
        renderer.setSize(this.renderWidth, renderHeight, true);
        return renderer;
    }

    private createMeshes(): THREE.Mesh<THREE.BufferGeometry, THREE.Material>[] {
        const material = createStudioRestorativeMaterial();
        const meshes: THREE.Mesh<THREE.BufferGeometry, THREE.Material>[] = this.extractedSmileLibrary.templates.map(
            template => {
                const dcm = template.dcm;
                const geometry = dcm.buildGeometry();
                geometry.computeVertexNormals();
                const mesh = new THREE.Mesh(geometry, material);
                return mesh;
            },
        );
        return meshes;
    }

    private createScene(): THREE.Scene {
        const scene = new THREE.Scene();
        for (const mesh of this.meshes) {
            scene.add(mesh);
        }
        return scene;
    }

    private createCamera() {
        const boundingBox = new THREE.Box3();
        for (const mesh of this.meshes) {
            mesh.geometry.computeBoundingBox();
            const bounds = mesh.geometry.boundingBox;
            if (!bounds) {
                continue;
            }
            boundingBox.union(bounds);
        }

        //Add some padding to get some marign around the smile library
        const padding = (boundingBox.max.y - boundingBox.min.y) * 0.1;
        boundingBox.expandByScalar(padding);

        const boundingBoxCenter = new THREE.Vector3();
        boundingBox.getCenter(boundingBoxCenter);

        const xExtents = boundingBox.max.x - boundingBox.min.x;
        const yExtents = boundingBox.max.y - boundingBox.min.y;
        const zExtents = boundingBox.max.z - boundingBox.min.z;

        const near = 0.1;
        const far = zExtents;
        const width = xExtents;
        const height = yExtents;
        const position = boundingBoxCenter.clone().add(new THREE.Vector3(0.0, 0.0, zExtents / 2.0));

        const camera = new THREE.OrthographicCamera(-width / 2.0, width / 2.0, height / 2.0, -height / 2.0, near, far);
        camera.position.set(position.x, position.y, position.z);
        camera.updateProjectionMatrix();

        const light = new THREE.PointLight(new THREE.Color('white'));
        light.intensity = 0.9;
        light.position.copy(position);
        this.scene.add(light);
        camera.add(light);

        return { camera, width, height };
    }

    render(
        onFinishedCallback: (
            smileLibrary: ExtractedSmileLibrary,
            cacheEntry: SmileLibraryPreviewRendererResult,
        ) => void,
    ) {
        if (this.disposed) {
            throw new Error('Attempted to compute on a disposed instance.');
        }

        if (this.renderer.getContext().isContextLost()) {
            console.warn('Renderer context was lost. Reinitializing new renderer.');
            this.renderer.dispose();

            this.renderer = this.createRenderer();
        }

        this.renderer.render(this.scene, this.camera);
        this.renderer.domElement.toBlob(imageFileData => {
            this.dispose();
            const imageURL = this.renderer.domElement.toDataURL('image/png');
            if (imageFileData) {
                onFinishedCallback(this.extractedSmileLibrary, {
                    imageURL,
                    imageFileData,
                });
            }
        });
    }

    private dispose(): void {
        this.renderer.forceContextLoss();
        this.renderer.dispose();
        this.disposed = true;
    }
}
