import type { QueryHookOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import type {
    AdminOrderIdsForListView_QueryQuery,
    AdminOrderIdsForListView_QueryQueryVariables,
} from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';

export interface OrderIdForListView {
    id: string;
    updated_at: string;
}

const AdminOrderIdsForListView_Query = graphql(`
    query AdminOrderIdsForListView_Query(
        $filter: ListOrdersFilter
        $criteria: [FilterCriteriaSubmissionInput!]
        $search: String
        $sort: ListOrdersSort
        $limit: Int
        $combinator: FilterCombinator
    ) {
        orderEntriesByListView(
            filter: $filter
            criteria: $criteria
            search: $search
            sort: $sort
            limit: $limit
            combinator: $combinator
        ) {
            id
            updated_at
        }
    }
`);

export function useOrderIdsForListView(
    variables: AdminOrderIdsForListView_QueryQueryVariables = {},
    options: Omit<
        QueryHookOptions<AdminOrderIdsForListView_QueryQuery, AdminOrderIdsForListView_QueryQueryVariables>,
        'fetchPolicy' | 'nextFetchPolicy' | 'variables'
    > = {},
) {
    const { data, loading, refetch, error } = useQuery(AdminOrderIdsForListView_Query, {
        variables,
        // this is so a change in query variables will cause us to hit the backend again
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        ...options,
    });

    const typedData: OrderIdForListView[] = data?.orderEntriesByListView ?? [];

    return { loading, refetch, error, data: typedData };
}
