import { useOpsInboxAction } from '../../state/Inbox.actions';
import { useInboxState } from '../../state/Inbox.selectors';
import { useInboxTasks } from '../../state/InboxTasksProvider';
import type { LabsGqlWorkflowTaskFragment } from '@orthly/graphql-operations';
import { stylesFactory, CheckboxPrimitive as Checkbox, Grid } from '@orthly/ui-primitives';
import { AssignTaskButton, getTaskButtonTaskFromLegacyFragment, StartTaskButton } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        justifyContent: `flex-end`,
        alignItems: 'center',
        position: `absolute`,
        right: 0,
        bottom: 0,
        width: `100%`,
        display: `flex`,
        padding: `0 6px 7px`,
    },
}));

interface InboxListItemActionsProps {
    task: LabsGqlWorkflowTaskFragment;
    buttonClass: string;
    isSidebarElement?: boolean;
}

export const InboxListItemActions: React.VFC<InboxListItemActionsProps> = props => {
    const classes = useStyles();
    const { refetch } = useInboxTasks();
    const { task, buttonClass, isSidebarElement } = props;
    const selected = useInboxState(s => s.selectedTaskIds.includes(task.id));
    const toggleSelected = useOpsInboxAction('TOGGLE_TASK_SELECTED');
    const [open, setOpen] = React.useState(false);

    const buttonProps = { className: open ? undefined : buttonClass };
    const actionProps = { task, refetch, setOpen, buttonProps };
    return (
        <Grid container className={classes.root}>
            {isSidebarElement && <AssignTaskButton {...actionProps} task={getTaskButtonTaskFromLegacyFragment(task)} />}
            <StartTaskButton {...actionProps} task={getTaskButtonTaskFromLegacyFragment(task)} />
            {!isSidebarElement && (
                <Checkbox
                    color={'secondary'}
                    style={{ margin: 0 }}
                    className={selected ? undefined : buttonClass}
                    checked={selected}
                    onChange={() => toggleSelected(task.id)}
                />
            )}
        </Grid>
    );
};
