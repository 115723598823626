import { isGuidedPreset } from '../GuidedWaxup.util';
import {
    GUIDED_WAXUP_DESKTOP_SCREEN_HEIGHT,
    PRESET_SIDEBAR_WIDTH,
    TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_COLLAPSED,
    TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_EXPANDED,
} from '../VisualConstants.util';
import { GuidedWaxupNavArea } from '../components/GuidedWaxupNavArea';
import { ScrollDownButton } from '../components/ScrollDownButton/ScrollDownButton';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useScrollToContainer } from '../state/GuidedWaxupState';
import { Box, FlossPalette, styled } from '@orthly/ui-primitives';
import React from 'react';

const Wrapper = styled(Box)(
    ({
        isSidebarExpanded,
        doesPreviousWaxupHavePresets,
    }: {
        isSidebarExpanded: boolean;
        doesPreviousWaxupHavePresets: boolean;
    }) => ({
        width: `calc(100dvw - ${
            isSidebarExpanded ? TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_EXPANDED : TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_COLLAPSED
        }px - 230px)`,
        // We add left padding to the model viewer container itself
        // so the margin here is just the width of the sidebar. If the
        // previous waxup doesn't have presets, there's no sidebar
        marginLeft: doesPreviousWaxupHavePresets ? PRESET_SIDEBAR_WIDTH : 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: GUIDED_WAXUP_DESKTOP_SCREEN_HEIGHT,
    }),
);

const NavWrapper = styled(Box)({
    paddingTop: '16px',
    borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
    position: 'relative',
});

const ScrollContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    height: '100%',
});

export const ContentContainer: React.FC<{
    doesPreviousWaxupHavePresets?: boolean;
    submit?: () => Promise<unknown>;
}> = ({ children, doesPreviousWaxupHavePresets = true, submit }) => {
    const { isSidebarExpanded, selectedDesignRevisionAlreadyReviewed, selectedDesignRevisionId, selectedTab } =
        useGuidedWaxupContext();
    const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);
    const guidedPreset = isGuidedPreset(selectedTab);
    // We only want to hide the nav area in the General view for historic designs
    const hideNavArea = !guidedPreset && selectedDesignRevisionAlreadyReviewed;

    useScrollToContainer(scrollContainerRef);

    return (
        <Wrapper
            doesPreviousWaxupHavePresets={doesPreviousWaxupHavePresets}
            isSidebarExpanded={isSidebarExpanded}
            key={selectedDesignRevisionId}
            data-testid={'model-viewer-container'}
        >
            <ScrollContainer className={'scrollbar-override'} ref={scrollContainerRef}>
                {children}
            </ScrollContainer>
            <NavWrapper>
                <ScrollDownButton
                    revealedText={
                        selectedDesignRevisionAlreadyReviewed ? 'Scroll to view more' : 'Scroll to approve or reject'
                    }
                    sx={{ right: '32px', top: '-48px' }}
                />
                {hideNavArea || <GuidedWaxupNavArea submit={submit} />}
            </NavWrapper>
        </Wrapper>
    );
};
