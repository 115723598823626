import type { OrderIdForListView } from './useOrderIdsForListView.graphql';

export function getOrderIdsFromIndexes(
    entries: OrderIdForListView[],
    startIndex: number,
    stopIndex: number,
): OrderIdForListView[] {
    const entriesStart = Math.max(0, startIndex);
    const entriesEnd = Math.max(0, stopIndex);

    return entries.slice(entriesStart, entriesEnd);
}
