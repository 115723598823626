import { AnalyticsClient } from '../../../../utils/analyticsClient';
import { MultiProductImageWrapper } from '@orthly/dentin';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { isValidProductUnitType } from '@orthly/items';
import type { GridProps } from '@orthly/ui-primitives';
import { Grid, FlossPalette, styled, Text } from '@orthly/ui-primitives';
import { ConnectedOrderDetailTrackerV2 } from '@orthly/veneer';
import React from 'react';

export const PALATE_ORDER_LIST_ITEM_HEIGHT = 148;

const AdminPalateOrderListItem_Fragment = graphql(`
    fragment AdminPalateOrderListItem_Fragment on OrderAdminListViewEntry {
        id
        practice_id
        patient_name
        items_description_short
        items_product_unit_types
        is_on_hold
        order_tracker_entries(timezone_offset_minutes: $timezone_offset_minutes) {
            ...VeneerOrderDetailTrackerV2_Fragment
        }
    }
`);

const PalateOrderListItemWrapper = styled((props: GridProps) => (
    <Grid container direction={'column'} wrap={'nowrap'} {...props} />
))({
    height: PALATE_ORDER_LIST_ITEM_HEIGHT,
    padding: '8px 24px',
    gap: 8,
    cursor: 'pointer',
    borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
    '&:hover': {
        backgroundColor: FlossPalette.TAN,
    },
});

const PalateOrderLoadingBlock = styled('div')({
    backgroundColor: FlossPalette.DARK_TAN,
    transform: 'unset',
    width: '100%',
});

const PalateOrderListItemLoading: React.VFC = () => (
    <PalateOrderListItemWrapper>
        <div style={{ width: '60%' }}>
            <PalateOrderLoadingBlock style={{ height: 24 }} />
        </div>
        <div>
            <PalateOrderLoadingBlock style={{ height: 48 }} />
        </div>
        <div style={{ width: '25%', marginLeft: 'auto' }}>
            <PalateOrderLoadingBlock style={{ height: 36 }} />
        </div>
    </PalateOrderListItemWrapper>
);

interface PalateOrderListItemContentProps {
    order: FragmentType<typeof AdminPalateOrderListItem_Fragment>;
}

const PalateOrderListItemContent: React.VFC<PalateOrderListItemContentProps> = ({ order }) => {
    const {
        id,
        practice_id,
        patient_name,
        items_description_short,
        items_product_unit_types,
        is_on_hold,
        order_tracker_entries,
    } = getFragmentData(AdminPalateOrderListItem_Fragment, order);

    return (
        <PalateOrderListItemWrapper
            onClick={() => {
                const orderLink = `orders/${id}`;
                AnalyticsClient.track('Palate - Link Clicked', { url: orderLink, practiceId: practice_id });
                window.open(orderLink);
            }}
        >
            <Grid item container style={{ gap: 12 }}>
                <Grid item>
                    <Text variant={'caption'} medium>
                        {patient_name}'s{' '}
                        <Text variant={'caption'} component={'span'}>
                            {items_description_short}
                        </Text>
                    </Text>
                </Grid>
                <Grid item>
                    <MultiProductImageWrapper
                        products={items_product_unit_types.filter(isValidProductUnitType)}
                        maxNumOrbs={3}
                        size={24}
                    />
                </Grid>
            </Grid>
            <Grid item>
                <ConnectedOrderDetailTrackerV2
                    orderTrackerEntries={order_tracker_entries}
                    isPractice={false}
                    isLab={false}
                    showDesignPreviewETA={false}
                    isOnHold={is_on_hold}
                    isMobileOverride={false}
                />
            </Grid>
        </PalateOrderListItemWrapper>
    );
};

interface PalateOrderListItemProps {
    listItem?: PalateOrderListItemContentProps;
}

export const PalateOrderListItem: React.VFC<PalateOrderListItemProps> = React.memo<PalateOrderListItemProps>(props =>
    props.listItem ? <PalateOrderListItemContent {...props.listItem} /> : <PalateOrderListItemLoading />,
);
