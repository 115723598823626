/* eslint-disable no-nested-ternary */
import { useAdminUiAction } from '../../redux/ui/ui.actions';
import { AnalyticsClient } from '../../utils/analyticsClient';
import { PalateFilter } from './PalateFilter';
import type {
    PracticeTabsProps,
    PracticeLinkProps,
    PracticeLinksFooterProps,
    PracticeTabDisplayProps,
    PracticeTabsContainerProps,
    PalatePracticeRootProps,
} from './PalatePracticeRoot.types';
import { PracticeTab } from './PalatePracticeRoot.types';
import { OpenIncognitoDialog } from './components/OpenIncognitoDialog';
import { PalatePracticeInfo } from './screens/PracticeInfo/PalatePracticeInfo';
import { PalatePracticeOrders } from './screens/PracticeOrders/PalatePracticeOrders';
import { PalatePracticeTimeline } from './screens/PracticeTimeline/PalatePracticeTimeline';
import { useRootStyles, LINK_CONTAINER_HEIGHT, TAB_CONTAINER_HEIGHT, TAB_CONTAINER_MARGIN } from './usePalateStyles';
import type { LabsGqlPracticeDetailsFragment } from '@orthly/graphql-operations';
import { useGetImpersonationTokenQuery } from '@orthly/graphql-react';
import { SalesforceUtil } from '@orthly/shared-types';
import { LoadBlocker } from '@orthly/ui';
import {
    FlossPalette,
    Text,
    stylesFactory,
    Grid,
    IconButton,
    Link,
    Tab,
    Tabs,
    CallMadeIcon,
    Icon,
} from '@orthly/ui-primitives';
import clsx from 'clsx';
import moment from 'moment-timezone';
import React from 'react';

const useStyles = stylesFactory(() => ({
    detailsContainer: {
        padding: '16px 32px 0px',
        alignItems: 'center',
        height: 'fit-content',
        position: 'fixed',
        zIndex: 1000,
        background: FlossPalette.DARK_TAN,
    },
    tabsContainer: { paddingBottom: 48, position: 'relative' },
    tabs: { height: 'fit-content', padding: 0, fontSize: 12, lineHeight: '16px' },
    linksContainer: {
        background: FlossPalette.TAN,
        height: LINK_CONTAINER_HEIGHT,
        position: 'fixed',
        bottom: 0,
        padding: '16px 32px',
        alignItems: 'center',
        zIndex: 1000,
    },
    links: { display: 'flex', cursor: 'pointer' },
    linkIcon: { fontSize: 12, margin: 2 },
}));

const ReturnButton: React.FC<{ onReset: () => void }> = props => {
    const { onReset } = props;

    return (
        <Grid item>
            <IconButton onClick={() => onReset()} size={'small'}>
                <Icon icon={'ChevronLeft'} style={{ color: FlossPalette.GRAY }} />
            </IconButton>
        </Grid>
    );
};

const PracticeDetailsHeader: React.FC<{ practiceDetails: LabsGqlPracticeDetailsFragment }> = ({ practiceDetails }) => {
    const bulletPoint = <span style={{ padding: '0 8px', color: FlossPalette.GRAY }}>&bull;</span>;

    return (
        <Grid container style={{ marginLeft: 8 }}>
            <Grid container>
                <Text variant={'body2'} medium>
                    {practiceDetails.practice_name}
                </Text>
            </Grid>
            <Grid container>
                <Text variant={'caption'} color={'DARK_GRAY'}>
                    {practiceDetails.timezone && `${moment().tz(practiceDetails.timezone).format('z h:mmA')}`}
                    {practiceDetails.timezone && bulletPoint}
                    {practiceDetails.city &&
                        practiceDetails.state &&
                        `${practiceDetails.city}, ${practiceDetails.state}`}
                    {practiceDetails.chairside_stage && bulletPoint}
                    {practiceDetails.chairside_stage && `Practice ${practiceDetails.chairside_stage}`}
                </Text>
            </Grid>
        </Grid>
    );
};

const PracticeTabs: React.FC<PracticeTabsProps> = props => {
    const { tab, setTab, practiceId } = props;
    const classes = useStyles();
    // track time on tabs
    const [tabChangedStartTime, setTabChangedStartTime] = React.useState(Date.now());

    return (
        <Grid container style={{ width: 376, justifyContent: 'space-between' }} alignItems={'flex-end'}>
            <Tabs
                indicatorColor={'secondary'}
                textColor={'inherit'}
                value={tab}
                onChange={(_, val) => {
                    const changeTime = Date.now();

                    AnalyticsClient.track('Palate - Tab Changed', {
                        tab,
                        practiceId,
                        timeElapsedSeconds: (changeTime - tabChangedStartTime) / 1000,
                    });

                    setTabChangedStartTime(changeTime);
                    setTab(val);
                }}
                style={{ height: 40, minHeight: 40 }}
            >
                {Object.values(PracticeTab).map(tabName => (
                    <Tab key={tabName} value={tabName} label={tabName} className={classes.tabs} />
                ))}
            </Tabs>
            {tab !== PracticeTab.Info && (
                <Grid item>
                    <PalateFilter practiceId={practiceId} tab={tab} />
                </Grid>
            )}
        </Grid>
    );
};

const PracticeLinkLabel: React.FC<{ label: string }> = ({ label }) => {
    const classes = useStyles();
    return (
        <Grid item className={classes.links}>
            <Text variant={'caption'} color={'STAR_GRASS'} style={{ fontWeight: 600 }}>
                {label}
            </Text>
            <CallMadeIcon className={classes.linkIcon} />
        </Grid>
    );
};

const PracticeLink: React.FC<PracticeLinkProps> = props => {
    const { url, label, practiceId } = props;
    return (
        <Link
            href={url}
            target={'_blank'}
            style={{ color: FlossPalette.STAR_GRASS }}
            onClick={() => AnalyticsClient.track('Palate - Link Clicked', { url, practiceId })}
        >
            <PracticeLinkLabel label={label} />
        </Link>
    );
};

const PracticeLinksFooter: React.FC<PracticeLinksFooterProps> = props => {
    const { practiceDetails, practiceId, practiceUrl } = props;
    const classes = useStyles();
    const bulletPoint = <span style={{ padding: '0 8px', color: FlossPalette.GRAY }}>&bull;</span>;
    const [open, setOpen] = React.useState(false);

    return (
        <Grid container className={classes.linksContainer}>
            <OpenIncognitoDialog
                open={open}
                setOpen={setOpen}
                practiceUrl={practiceUrl}
                title={`Impersonate ${practiceDetails.practice_name}`}
            />
            <PracticeLink
                url={`https://ops.orthly.com/orders?by_partner=${practiceId}`}
                label={'Orders'}
                practiceId={practiceId}
            />
            {bulletPoint}
            <Link
                style={{ color: FlossPalette.STAR_GRASS }}
                onClick={() => {
                    AnalyticsClient.track('Palate - Practice Impersonate Opened', {
                        practiceId,
                        impersonateUrl: practiceUrl,
                    });
                    setOpen(true);
                }}
            >
                <PracticeLinkLabel label={'Impersonate'} />
            </Link>
            {practiceDetails.team_viewer_id && (
                <>
                    {bulletPoint}
                    <PracticeLink
                        url={`teamviewer10://control?device=${practiceDetails.team_viewer_id}`}
                        label={'Teamviewer'}
                        practiceId={practiceId}
                    />
                </>
            )}
            {practiceDetails.salesforce_account_id && (
                <>
                    {bulletPoint}
                    <PracticeLink
                        url={SalesforceUtil.salesforceAccountUrl(practiceDetails.salesforce_account_id)}
                        label={'Salesforce'}
                        practiceId={practiceId}
                    />
                </>
            )}
        </Grid>
    );
};

const PracticeTabDisplay: React.FC<PracticeTabDisplayProps> = props => {
    const { tab, practiceId, practiceDetails, tabContainerHeight, practiceUrl } = props;

    switch (tab) {
        case PracticeTab.Info:
            return <PalatePracticeInfo practiceId={practiceId} practiceDetails={practiceDetails} />;
        case PracticeTab.Orders:
            return <PalatePracticeOrders practiceId={practiceId} practiceUrl={practiceUrl} />;
        case PracticeTab.Timeline:
            return <PalatePracticeTimeline practiceId={practiceId} tabContainerHeight={tabContainerHeight} />;
    }
};

const PracticeTabsContainer: React.FC<PracticeTabsContainerProps> = props => {
    const { tab, practiceId, practiceDetails, practiceUrl } = props;
    const classes = useStyles();
    const markPalatePracticeVisited = useAdminUiAction('MARK_PALATE_PRACTICE_VISITED');
    React.useEffect(() => {
        markPalatePracticeVisited({
            id: practiceId,
            name: practiceDetails.practice_name,
        });
    }, [markPalatePracticeVisited, practiceDetails.practice_name, practiceId]);

    const tabContainerHeight =
        (practiceDetails.city && practiceDetails.state) || practiceDetails.timezone
            ? TAB_CONTAINER_HEIGHT
            : TAB_CONTAINER_HEIGHT - TAB_CONTAINER_MARGIN;

    return (
        <Grid
            container
            className={classes.tabsContainer}
            style={{ top: tabContainerHeight, height: `calc(100vh - ${tabContainerHeight}px)` }}
        >
            <PracticeTabDisplay
                tab={tab}
                practiceId={practiceId}
                practiceDetails={practiceDetails}
                tabContainerHeight={tabContainerHeight}
                practiceUrl={practiceUrl}
            />
        </Grid>
    );
};

export const PalatePracticeRoot: React.FC<PalatePracticeRootProps> = props => {
    const rootClasses = useRootStyles();
    const classes = useStyles();
    const { practiceData, onReset, loading } = props;
    const { data, organization_id } = practiceData;
    const [tab, setTab] = React.useState(PracticeTab.Info);
    const { data: impersonationData } = useGetImpersonationTokenQuery({
        variables: { organization_id, user_id: null, expires_in_seconds: null },
        fetchPolicy: `no-cache`,
        nextFetchPolicy: `no-cache`,
    });
    const practiceUrl = impersonationData?.getImpersonationToken.practiceUrl ?? '';

    return (
        <LoadBlocker blocking={loading}>
            {data ? (
                <Grid container>
                    <Grid container className={clsx(rootClasses.container, classes.detailsContainer)}>
                        <Grid container wrap={'nowrap'}>
                            <ReturnButton onReset={onReset} />
                            <PracticeDetailsHeader practiceDetails={data} />
                        </Grid>
                        <PracticeTabs tab={tab} setTab={setTab} practiceId={organization_id} />
                    </Grid>
                    <PracticeTabsContainer
                        tab={tab}
                        practiceId={organization_id}
                        practiceDetails={data}
                        practiceUrl={practiceUrl}
                    />
                    <PracticeLinksFooter
                        practiceDetails={data}
                        practiceId={organization_id}
                        practiceUrl={practiceUrl}
                    />
                </Grid>
            ) : (
                <Grid container className={rootClasses.container}>
                    <ReturnButton onReset={onReset} />
                    {loading ? 'Loading...' : 'No data found'}
                </Grid>
            )}
        </LoadBlocker>
    );
};
