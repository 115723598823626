import { useApolloClient } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import React from 'react';

export const GetAllOrganizationsCsv_Query = graphql(`
    query GetAllOrganizationsCsv($filter: GetAllOrganizationsCsvFilter) {
        getAllOrganizationsCsv(filter: $filter) {
            csv
        }
    }
`);

export const useGetAllOrganizationsCsv = () => {
    const client = useApolloClient();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<Error | null>(null);

    const getCsv = React.useCallback(
        async (filters: { id?: string; type?: LabsGqlOrganizationType[] }) => {
            setLoading(true);
            try {
                /**
                 * This _should_ be a lazy query, but we're encountering a bug where every network request gets cancelled instead.
                 * This IS resolved newer versions of Apollo Client, but we're not able to upgrade until we fix useChangeSubmissionFn
                 * Ticket to fix useChangeSubmissionFn: https://meetdandy.atlassian.net/browse/EPDPLT-4244
                 * Related official apollo bug: https://github.com/apollographql/apollo-client/issues/6133
                 */
                const { data } = await client.query({
                    query: GetAllOrganizationsCsv_Query,
                    fetchPolicy: 'network-only',
                    variables: {
                        filter: {
                            id: filters.id,
                            type: filters.type,
                        },
                    },
                });

                const csvContent = data.getAllOrganizationsCsv.csv;

                if (csvContent) {
                    // Create a Blob and trigger download
                    const blob = new Blob([csvContent], { type: 'text/csv' });
                    const url = window.URL.createObjectURL(blob);

                    // Create an <a> element and trigger a download
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'organizations.csv';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }
            } catch (err: any) {
                setError(err);
            } finally {
                setLoading(false);
            }
        },
        [client],
    );

    return {
        loading,
        error: error?.message,
        getCsv,
    };
};
