import { OrderListItemCellText } from '../../../../components/OrderListItem/OrderListItemCellText';
import {
    OrderListItemPartnerCell,
    OrderListItemItemsLabCell,
    OrderListItemTrackerCell,
} from '../../../../components/OrderListItem/OrderListItemCells';
import {
    OrderListItemLayout,
    ORDER_LIST_ITEM_HEIGHT,
    OrderListItemLoading,
} from '../../../../components/OrderListItem/OrderListItemLayout';
import { OrderListItemTitle } from '../../../../components/OrderListItem/OrderListItemTitle';
import { DesignerStatusToolbar } from '../../../../components/OrdersListToolbar/DesignerStatusToolbar';
import { OrdersListToolbar } from '../../../../components/OrdersListToolbar/OrdersListToolbar';
import { useOpenOrderDetailOps } from '../../../../utils/router/useOpenOrderDetail';
import { usePartnerAndLabNames } from '../../../../utils/usePartnerAndLabNames';
import type { InboxListItemProps } from '../../state/Inbox.selectors';
import {
    useInboxState,
    workflowTaskItemLabel,
    useInboxItemDateMessage,
    taskListItemPropsAreEqual,
} from '../../state/Inbox.selectors';
import { useInboxTasks } from '../../state/InboxTasksProvider';
import { useCompletedInboxDesignListItemDesignData } from './CompletedInboxDesignListItemFeedback.hooks.graphql';
import { InboxDesignListItem } from './InboxDesignListItem';
import { InboxListItemActions } from './InboxListItemActions';
import { OrderListItemTrackerUtils } from '@orthly/dentin';
import { WorkflowTasksByListViewDocument } from '@orthly/graphql-react';
import { LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { LoadBlocker } from '@orthly/ui';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import {
    WorkflowTasksVirtualList,
    useVirtualizedListOrders,
    useOrderToOpsListItemContent,
    AssignTaskButton,
    UpdateNotifierSignal,
    OrderListNoResultsItem,
    useUpdateNotifier,
    OrderListItemFlags,
    getTaskButtonTaskFromLegacyFragment,
} from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    rootGrid: {
        '& $button': { visibility: `hidden` },
        '&:hover $button': { visibility: `visible` },
    },
    button: {},
}));

export const InboxListItemContent = React.memo<InboxListItemProps>(props => {
    const classes = useStyles();
    const { listItemContent, task } = props;
    const { history, screen } = useInboxState(s => ({ history: s.history, screen: s.screen }));
    const { refetch, designTaskLookup } = useInboxTasks();
    const openOrder = useOpenOrderDetailOps();
    const onClick = React.useCallback(e => openOrder(task.lab_order_id, e), [openOrder, task.lab_order_id]);
    const dateMessage = useInboxItemDateMessage(task, listItemContent?.order.design_due_date ?? null);
    const updatedSinceLastView = React.useMemo(() => {
        const lastScreenView = history[screen] ?? 0;
        return new Date(task.closeout?.closed_at ?? task.created_at).valueOf() > lastScreenView;
    }, [task, history, screen]);
    const designTaskSummary = React.useMemo(() => designTaskLookup[task.lab_order_id], [designTaskLookup, task]);
    if (!listItemContent) {
        return <OrderListItemLoading columns={4} />;
    }
    const { patient, patient_order_count, doctor_name } = listItemContent.order;

    if (OrderListItemTrackerUtils.isDesignTaskForInternal(task.type)) {
        return (
            <InboxDesignListItem
                updatedSinceLastView={updatedSinceLastView}
                onClick={e => {
                    // When opening orders for prep tasks in a new tab, enable automatic pre-download.
                    const openInNewWindow = e?.metaKey || e?.ctrlKey || e?.button === 1;
                    const preDownload = task.type === LabsGqlWorkflowTaskType.DesignPrep && openInNewWindow;
                    openOrder(task.lab_order_id, e, { params: preDownload ? { preDownload } : undefined });
                }}
                listItemContent={listItemContent}
                task={task}
                refetch={refetch}
                dateMessage={dateMessage}
                activeInternalDesignTask={designTaskSummary}
            />
        );
    }

    return (
        <OrderListItemLayout
            rowTitle={`Order Id: ${listItemContent.order.id}`}
            started_at={task.started_at}
            closeout={task.closeout}
            updatedSinceLastView={updatedSinceLastView}
            onClick={onClick}
            className={classes.rootGrid}
            title={
                <>
                    <Grid container item direction={`row`} spacing={2}>
                        <Grid item style={{ flexGrow: 1 }}>
                            <OrderListItemCellText medium variant={'title'} text={workflowTaskItemLabel(task)} />
                        </Grid>
                        <Grid item style={{ padding: 4 }}>
                            <AssignTaskButton task={getTaskButtonTaskFromLegacyFragment(task)} refetch={refetch} />
                        </Grid>
                    </Grid>
                    <OrderListItemCellText variant={'subtitle'} text={dateMessage} />
                </>
            }
            middleCellOne={
                <OrderListItemTitle
                    disableSearchOnClick
                    patient_name={`${patient.first_name} ${patient.last_name}`}
                    patient_order_count={patient_order_count}
                    flagContent={
                        <OrderListItemFlags
                            flags={listItemContent.flags}
                            review_flag_reasons={listItemContent.review_flag_reasons}
                        />
                    }
                />
            }
            middleCellTwo={
                <OrderListItemPartnerCell partner_name={listItemContent.partner_name} doctor_name={doctor_name} />
            }
            middleCellThree={
                <OrderListItemItemsLabCell
                    manufacturer_name={listItemContent.manufacturer_name}
                    products={listItemContent.productsText}
                />
            }
            trackerCell={
                <OrderListItemTrackerCell
                    order={listItemContent.order}
                    actions={<InboxListItemActions buttonClass={classes.button} task={task} />}
                    trackerProps={listItemContent.trackerProps}
                />
            }
        />
    );
}, taskListItemPropsAreEqual);

export const InboxListView: React.FC = () => {
    const { orderIds, loading, tasks, invalidFiltersMessage, error } = useInboxTasks();
    const { labNamesById, partnerNamesById } = usePartnerAndLabNames();
    const designFragments = useCompletedInboxDesignListItemDesignData(tasks);

    const toListItemContent = useOrderToOpsListItemContent(labNamesById, partnerNamesById, designFragments);
    const listProps = useVirtualizedListOrders({
        orderIds,
        itemHeight: ORDER_LIST_ITEM_HEIGHT,
        idsLoading: loading,
        orderToListItemContent: toListItemContent,
    });
    const { idsLoading, ids } = listProps;

    const session = useSession();
    const userId = session?.user_id;

    useUpdateNotifier(UpdateNotifierSignal.onUpdate('WorkflowTask').refetch([WorkflowTasksByListViewDocument]));

    return (
        <Grid
            container
            style={{ height: '100vh', overflow: 'auto', alignContent: 'flex-start' }}
            alignItems={'flex-start'}
        >
            <OrdersListToolbar loading={idsLoading} mode={'inbox'} />
            {userId && <DesignerStatusToolbar userId={userId} />}
            <LoadBlocker loader={'dandy'} blocking={ids.length === 0 && idsLoading}>
                {ids.length === 0 && !idsLoading && (
                    <OrderListNoResultsItem message={invalidFiltersMessage ?? 'No tasks found'} />
                )}
                {error && <OrderListNoResultsItem message={`Error: ${error.message}`} variant={'error'} />}
                <WorkflowTasksVirtualList
                    listItemHeight={ORDER_LIST_ITEM_HEIGHT}
                    ListItem={InboxListItemContent}
                    tasks={tasks}
                    {...listProps}
                />
            </LoadBlocker>
        </Grid>
    );
};
