import {
    AdminOrdersSpotlightSearch,
    useAdminOrdersSpotlightSearchNavItem,
} from '../../components/AdminOrdersSpotlightSearch';
import { useOpsSidebarVisible } from '../../redux/ui/ui.selectors';
import { useAdminUiSelector } from '../../redux/ui/ui.state';
import type { OpsScreen } from '../../redux/ui/ui.types';
import { OpsScreens, OpsScreenEnum } from '../../redux/ui/ui.types';
import { GlobalImpersonate, useGlobalImpersonateNavItem } from '../Impersonate/GlobalImpersonate';
import { OpsScreenSessionGuardedNavigationLink } from './ScreenSessionGuards';
import { useApolloClient } from '@apollo/client';
import { clearApolloCache } from '@orthly/graphql-react';
import { RetainerTokenStorage } from '@orthly/retainer-common';
import type { NavigationEntry, NavigationEntryBottom } from '@orthly/ui';
import { Navigation } from '@orthly/ui';
import type { IconName } from '@orthly/ui-primitives';
import { useDesignerStatusNavItem, useFeatureFlag } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const IconMap: { [K in OpsScreen]?: IconName } = {
    tickets: 'ToDoIcon',
    orders: 'OrderPaperIcon',
    accounts: 'PracticeIcon',
    labs: 'LabIcon',
    config: 'AccountGearIcon',
    pricing: 'Money',
    tasks: 'AssignmentTurnedInOutlined',
    billing: 'Receipt',
    automations: 'Loop',
    routing: 'Map',
    users: 'PersonOutlineOutlined',
    design_qa: 'DesignIcon',
    training: 'BookIcon',
    team_3d: 'BeachAccess',
    tools: 'ToolOutlinedIcon',
};

function useLogoutNavItem(): NavigationEntryBottom {
    const client = useApolloClient();
    const onClick = React.useCallback(() => {
        clearApolloCache(client).catch(console.error);
        RetainerTokenStorage.removeToken();
    }, [client]);
    return { onClick, title: 'Logout', iconName: 'ExitToApp', active: false };
}

export const OpsNavigation: React.FC = () => {
    const activeScreen = useAdminUiSelector(s => s.screen);
    const sidebarOpen = useOpsSidebarVisible();
    const logoutItem = useLogoutNavItem();
    const { value: enableNewOrganizationsPage } = useFeatureFlag('enableNewOrganizationsPage');
    const { value: enableViewDesignQa } = useFeatureFlag('designQaAllowedToViewQueue');
    const { value: enableCadToolsPlayground } = useFeatureFlag('enableCadToolsPlayground');

    const designerStatusItem = useDesignerStatusNavItem();
    const impersonateItem = useGlobalImpersonateNavItem();
    const searchItem = useAdminOrdersSpotlightSearchNavItem();

    const mainItems = React.useMemo<NavigationEntry[]>(() => {
        // Screens that we never want to show up in the sidebar.
        const bannedScreens: OpsScreen[] = [
            OpsScreenEnum.palate,
            OpsScreenEnum.chairside_scans,
            OpsScreenEnum.refab_flowops,
        ];

        return OpsScreens.filter(screen => !bannedScreens.includes(screen))
            .filter(screen => enableNewOrganizationsPage || screen !== OpsScreenEnum.accounts)
            .filter(screen => enableViewDesignQa || screen !== OpsScreenEnum.design_qa)
            .filter(screen => enableCadToolsPlayground || screen !== OpsScreenEnum.team_3d)
            .map(screen => ({
                onClick: () => {},
                title: _.startCase(screen),
                active: activeScreen === screen,
                iconName: IconMap[screen],
                disabled: false,
                Container: OpsScreenSessionGuardedNavigationLink(screen),
            }));
    }, [activeScreen, enableNewOrganizationsPage, enableViewDesignQa, enableCadToolsPlayground]);
    return (
        <>
            {sidebarOpen && (
                <Navigation
                    collapsible
                    initialCollapsed={true}
                    fixedItems={[designerStatusItem]}
                    mainItems={mainItems}
                    bottomItems={[searchItem, impersonateItem, logoutItem]}
                />
            )}
            <GlobalImpersonate />
            <AdminOrdersSpotlightSearch />
        </>
    );
};
