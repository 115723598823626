import { useGetPaginatedOrganizationsLazy } from '../graphql/useGetPaginatedOrganizations.graphql';
import { Autocomplete, CircularProgress, TextField } from '@orthly/ui-primitives';
import { debounce } from 'lodash';
import React from 'react';

interface OrganizationSelectProps {
    setOrganization: (organization: { orgId: string | undefined; search?: string }) => void;
}

export const OrganizationSelect: React.FC<OrganizationSelectProps> = ({ setOrganization }) => {
    const [search, setSearch] = React.useState('');
    const [options, setOptions] = React.useState<{ id: string; name: string }[]>([]);
    const [hasMore, setHasMore] = React.useState(true);
    const currentPageRef = React.useRef(0); // Track the current page with useRef

    const { getOrganizations, data, loading } = useGetPaginatedOrganizationsLazy();

    React.useEffect(() => {
        if (data?.organizations) {
            const { organizations, total: totalCount } = data;
            setOptions(prev => (currentPageRef.current === 0 ? organizations : [...prev, ...organizations]));

            const totalLoaded = currentPageRef.current * 7 + organizations.length;
            setHasMore(totalLoaded < totalCount);
        }
    }, [data]);

    const handleSearch = debounce(async (value: string) => {
        setSearch(value);
        currentPageRef.current = 0; // Reset the page reference
        setOptions([]);
        setHasMore(true);
        await getOrganizations(0, 7, { name: value });
    }, 300);

    const handleScroll = async (event: React.UIEvent<HTMLUListElement>) => {
        const listboxNode = event.target as HTMLUListElement;
        if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight && hasMore && !loading) {
            currentPageRef.current += 1;
            await getOrganizations(currentPageRef.current, 7, { name: search });
        }
    };

    const handleChange = (_event: React.ChangeEvent<{}>, value: { id: string; name: string } | null) => {
        if (value) {
            // when a selection is made, set the organization
            setOrganization({ orgId: value.id, search: undefined });
        } else {
            // when "x" is clicked, clear the organization
            setOrganization({ orgId: undefined, search: undefined });
        }
    };

    const handleFocus = async () => {
        if (!search) {
            await getOrganizations(0, 7, { name: '' });
        }
    };

    return (
        <Autocomplete
            style={{ width: '100%' }}
            options={options}
            getOptionLabel={option => option.name}
            onInputChange={(_event, value) => handleSearch(value)}
            onChange={handleChange}
            ListboxProps={{
                onScroll: handleScroll,
                style: {
                    maxHeight: '200px',
                    overflowY: 'auto',
                },
            }}
            loading={loading}
            onFocus={handleFocus}
            renderInput={params => (
                <TextField
                    {...params}
                    label={'Search Organizations'}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};
