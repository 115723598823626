import { useLazyQuery, useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { RootActionDialog } from '@orthly/ui';
import {
    FlossPalette,
    IconButton,
    Tooltip,
    OrderPaperIcon,
    styled,
    Text,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    useScreenIsMobile,
    Skeleton,
} from '@orthly/ui-primitives';
import React from 'react';

const ITEMS_PER_PAGE = 10;

const OrderList = styled('div')({
    borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const OrderRow = styled('a')<{ isLoading: boolean }>(({ isLoading }) => ({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: isLoading ? 'auto' : 'pointer',
    '&:hover': {
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
    },
    borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
    padding: '16px 32px',
}));

const TitleText = styled(Text)({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: 'inherit',
});

const LoadingRow = styled('div')({
    display: 'flex',
    gap: '4px',
});

const PaginationControls = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0',
});

const PaginationContainer = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
});

const PracticeOrderIdsForDeliveryAddress_Query = graphql(`
    query PracticeOrderIdsForDeliveryAddress_Query($filter: ListOrdersFilter) {
        orderEntriesByListView(filter: $filter) {
            id
        }
    }
`);

const GetPracticeOrderObjectsForDeliveryAddress_Query = graphql(`
    query GetPracticeOrderObjectsForDeliveryAddress_Query($ids: [String!]!, $organizationId: String) {
        getOrderPracticeListViewEntriesByIds(ids: $ids, organizationId: $organizationId) {
            id
            patient_name
            items_description
        }
    }
`);

export const InFlightOrdersModal: React.VFC<{ partnerId: string; addressId: string }> = ({ partnerId, addressId }) => {
    const [open, setOpen] = React.useState(false);
    const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
    const [currentPage, setCurrentPage] = React.useState(0);
    const isMobile = useScreenIsMobile();

    const { loading: orderIdsLoading } = useQuery(PracticeOrderIdsForDeliveryAddress_Query, {
        variables: {
            filter: {
                mailing_address_id: addressId,
                partner_id: partnerId,
                excluded_statuses: [
                    LabsGqlLabOrderStatus.Cancelled,
                    LabsGqlLabOrderStatus.NeedsRefabrication,
                    LabsGqlLabOrderStatus.Delivered,
                ],
            },
        },
        onCompleted: data => {
            const ids = data?.orderEntriesByListView.map(order => order.id) || [];
            setSelectedIds(ids);
        },
    });

    const [fetchOrderDetails, { data: orderDetailsData, loading: orderDetailsLoading }] = useLazyQuery(
        GetPracticeOrderObjectsForDeliveryAddress_Query,
        {
            variables: {
                ids: selectedIds.slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE),
                organizationId: partnerId,
            },
        },
    );

    React.useEffect(() => {
        if (selectedIds.length > 0) {
            fetchOrderDetails();
        }
    }, [selectedIds, fetchOrderDetails]);

    const orders = orderDetailsData?.getOrderPracticeListViewEntriesByIds || [];
    const totalPages = Math.ceil(selectedIds.length / ITEMS_PER_PAGE);
    const isLoading = orderIdsLoading || orderDetailsLoading;

    return (
        <RootActionDialog
            open={open}
            setOpen={setOpen}
            onClose={() => setCurrentPage(0)}
            title={'In-Flight Orders'}
            fullScreen={isMobile}
            loading={false}
            contentProps={{
                style: { margin: 0, padding: 0 },
            }}
            CustomButton={() => (
                <Tooltip title={'View all in-flight orders using this address'}>
                    <span>
                        <IconButton onClick={() => setOpen(!open)}>
                            <OrderPaperIcon style={{ color: FlossPalette.GRAY }} />
                        </IconButton>
                    </span>
                </Tooltip>
            )}
            content={
                isLoading ? (
                    <OrderList>
                        {Array.from({ length: ITEMS_PER_PAGE }).map((_, index) => (
                            <OrderRow isLoading={isLoading} key={index}>
                                <LoadingRow>
                                    <Skeleton animation={'wave'} variant={'text'} style={{ width: '75px' }} />
                                    <Skeleton animation={'wave'} variant={'text'} style={{ width: '150px' }} />
                                </LoadingRow>
                            </OrderRow>
                        ))}
                    </OrderList>
                ) : (
                    <OrderList>
                        {orders.map(order => (
                            <OrderRow
                                isLoading={isLoading}
                                key={order.id}
                                href={`/orders/${order.id}`}
                                target={'_blank'}
                                rel={'noopener noreferrer'}
                            >
                                <TitleText variant={'body2'}>
                                    <strong>{order.patient_name} </strong>
                                    {order.items_description}
                                </TitleText>
                            </OrderRow>
                        ))}
                    </OrderList>
                )
            }
            actions={
                totalPages > 1 && (
                    <PaginationContainer>
                        <PaginationControls>
                            <IconButton
                                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))}
                                disabled={currentPage === 0}
                            >
                                <KeyboardArrowLeft />
                            </IconButton>
                            <Text variant={'body2'} style={{ margin: '0 16px' }}>
                                Page {currentPage + 1} of {totalPages}
                            </Text>
                            <IconButton
                                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages - 1))}
                                disabled={currentPage >= totalPages - 1}
                            >
                                <KeyboardArrowRight />
                            </IconButton>
                        </PaginationControls>
                    </PaginationContainer>
                )
            }
        />
    );
};
