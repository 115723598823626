import type { ScanImageUrls } from './PatientScansPdfGenerator';
import type { ScanSnapshotModels } from '@orthly/dentin';
import { CameraPoseType, ScanSnapshotComputer } from '@orthly/dentin';

// Initializing these as empty strings to play nice with pdfmake.
const INITIAL_SCAN_IMAGE_URLS: ScanImageUrls = {
    front: '',
    right: '',
    left: '',
    upper: '',
    lower: '',
};

export const getScanSnapshots = (models: ScanSnapshotModels) => {
    const result: ScanImageUrls = { ...INITIAL_SCAN_IMAGE_URLS };

    const poses = [
        CameraPoseType.Front,
        CameraPoseType.Left,
        CameraPoseType.Right,
        ...(models.upperModel ? [CameraPoseType.Upper] : []),
        ...(models.lowerModel ? [CameraPoseType.Lower] : []),
    ];

    poses.forEach(pose => {
        const computer = new ScanSnapshotComputer(pose, models);
        const url = computer.compute();

        switch (pose) {
            case CameraPoseType.Upper:
                result.upper = url;
                break;
            case CameraPoseType.Lower:
                result.lower = url;
                break;
            case CameraPoseType.Front:
                result.front = url;
                break;
            case CameraPoseType.Left:
                result.left = url;
                break;
            case CameraPoseType.Right:
                result.right = url;
                break;
        }

        computer.dispose();
    });

    return result;
};
