import PainterWrapper from '../../../Painter/PainterWrapper';
import { OrderDetailTabView, useTabValue } from '../../state/useTabValue';
import { OrderDetailComparePage } from './components/OrderCompare/OrderDetailComparePage';
import { OrderDetailDesignTabPage } from './components/OrderDetailBody/CAD/OrderDesignBodyRoot';
import { OrderDetailFinishingTabPage } from './components/OrderDetailBody/CAD/OrderFinishingBodyRoot.graphql';
import { OrderDetailDefaultTabPage } from './components/OrderDetailBody/OrderDetailBodyRoot.graphql';
import { OrderDetailProvider } from './state/OrderDetailProvider.graphql';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { UuidUtils } from '@orthly/runtime-utils';
import React from 'react';
import { useHistory } from 'react-router-dom';

const OrderDetailScanTabPage = React.lazy(() => import('./components/OrderDetailBody/CAD/ScanDetailForOrderBodyRoot'));
const OrderDesignEditorBodyRoot = React.lazy(
    () => import('./components/OrderDetailBody/CAD/OrderDesignEditorBodyRoot'),
);
const OrderDetailsExperimentalRoot = React.lazy(
    () => import('./content/OrderDetailsExperimental/OrderDetailsExperimental'),
);

const AdminOrderDetailRootLabSalesOrderIdByOrderNumber_Query = graphql(`
    query AdminOrderDetailRootLabSalesOrderIdByOrderNumber_Query($order_number: String!) {
        getLabSalesOrderIdByOrderNumber(order_number: $order_number) {
            id
        }
    }
`);

const OrderDetailRootOrderNumberRedirect: React.VFC<{ orderNumber: string }> = ({ orderNumber }) => {
    const history = useHistory();

    const { data } = useQuery(AdminOrderDetailRootLabSalesOrderIdByOrderNumber_Query, {
        variables: { order_number: orderNumber },
    });

    const orderId = data?.getLabSalesOrderIdByOrderNumber?.id;

    React.useEffect(() => {
        if (orderId) {
            const basePath = history.location.pathname.split('/').slice(0, -1).join('/');
            history.replace(`${basePath}/${orderId}`);
        }
    }, [history, orderId]);

    return null;
};

const OrderDetailContentRoot: React.VFC = () => {
    const [tabValue] = useTabValue();
    switch (tabValue) {
        case OrderDetailTabView.order:
            return <OrderDetailDefaultTabPage />;
        case OrderDetailTabView.orderDev:
            return <OrderDetailsExperimentalRoot />;
        case OrderDetailTabView.scan:
            return <OrderDetailScanTabPage />;
        case OrderDetailTabView.editor:
            return <OrderDesignEditorBodyRoot />;
        case OrderDetailTabView.design:
            return <OrderDetailDesignTabPage />;
        case OrderDetailTabView.finishing:
            return <OrderDetailFinishingTabPage />;

        case OrderDetailTabView.compare:
            return <OrderDetailComparePage />;
        case OrderDetailTabView.shade:
            return <PainterWrapper />;

        default:
            return <OrderDetailDefaultTabPage />;
    }
};

interface OrderDetailRootProps {
    idOrOrderNumber: string;
    additionalRefetch?: () => Promise<any>;
}

export const OrderDetailRoot: React.VFC<OrderDetailRootProps> = ({ idOrOrderNumber, additionalRefetch }) => {
    // if the idOrOrderNumber is an order number, we need to look up the id and redirect to this same page with that id in the url instead
    if (!UuidUtils.isUUID(idOrOrderNumber)) {
        return <OrderDetailRootOrderNumberRedirect orderNumber={idOrOrderNumber} />;
    }

    return (
        <OrderDetailProvider id={idOrOrderNumber} additionalRefetch={additionalRefetch}>
            <OrderDetailContentRoot />
        </OrderDetailProvider>
    );
};
