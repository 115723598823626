import { PracticeFullScreenDialog } from '../../Layout/PracticeFullScreenDialog';
import {
    useFilterSelectedReasonCodeIdsByCategoryForCurrentItem,
    useRefabFlowSelector,
    useSelectOtherNotesByReasonCodeIdForCurrentItem,
    useSelectedReasonCodeIdsForCurrentItem,
    useSetOtherNotesByReasonCodeIdForCurrentItem,
    useSetReasonCodeForCurrentItem,
    useUnsetReasonCodesForCurrentItem,
} from '../state/refab-flow-state';
import type { LabsGqlReasonCodeOptionFragment } from '@orthly/graphql-operations';
import type { Maybe } from '@orthly/graphql-schema';
import { ChevronRight, SimpleCheckbox, SimpleInput, SimpleMultiSelect } from '@orthly/ui';
import {
    Button,
    FlossPalette,
    Text,
    stylesFactory,
    useScreenIsMobile,
    Grid,
    Slide,
    CloseIcon,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import _ from 'lodash';
import React from 'react';

const topMargin = 56;

const useStyles = stylesFactory(theme => ({
    criterionContainer: {
        backgroundColor: FlossPalette.TAN,
        width: `100%`,
        marginBottom: 16,
        borderRadius: 16,
        border: `1px solid rgba(0, 0, 0, 0.08)`,
        display: `flex`,
        flexFlow: `column`,
        alignItems: `stretch`,
        justifyContent: `center`,
        padding: `32px 16px 32px 16px`,
        userSelect: `none`,
        [theme.breakpoints.down(`xs`)]: {
            padding: 8,
            marginBottom: 8,
        },
    },
    criterionOptions: {
        [theme.breakpoints.down('sm')]: {
            display: `flex`,
            flexFlow: `row`,
            alignItems: `center`,
            justifyContent: `space-between`,
            gap: 6,
            padding: '0px 16px',
        },
    },
    itemSelectRows: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: FlossPalette.TAN,
            alignItems: `center`,
            borderRadius: 16,
            justifyContent: `space-between`,
            marginBottom: 8,
            height: 56,
            border: `1px solid ${FlossPalette.DARK_TAN}`,
            padding: '0px 8px',
        },
    },
    navAreaMobile: {
        width: '100%',
        // total height is 78 + 2 = 80
        height: 78,
        borderTop: `2px solid ${FlossPalette.TAN}`,
        position: 'absolute',
        backgroundColor: FlossPalette.WHITE,
        padding: 16,
    },
    complete: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
            border: `2px solid ${FlossPalette.PRIMARY_FOREGROUND}`,
            borderRadius: 16,
        },
    },
    otherInputRoot: ({ hasError }: { hasError: boolean }) => ({
        backgroundColor: hasError ? `${FlossPalette.ATTENTION_BACKGROUND} !important` : `${FlossPalette.WHITE}`,
    }),
    otherLabel: ({ hasError }: { hasError: boolean }) => ({
        color: hasError ? `${FlossPalette.GRAY} !important` : undefined,
        paddingRight: '24px',
    }),
    otherHeader: {
        margin: `8px 0`,
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

export const ReasonCodeIndividualCheckbox: React.VFC<{ reasonCode: LabsGqlReasonCodeOptionFragment }> = ({
    reasonCode,
}) => {
    const selectedReasonCodeIds = useSelectedReasonCodeIdsForCurrentItem();
    const classes = useStyles({ hasError: false });
    const [checked, setChecked] = React.useState<boolean>(selectedReasonCodeIds.includes(reasonCode.id));
    const isMobile = useScreenIsMobile();
    const unsetReasonCodesForCurrentItem = useUnsetReasonCodesForCurrentItem();
    const setReasonCodeForCurrentItem = useSetReasonCodeForCurrentItem();
    return (
        <Grid container className={cx(classes.itemSelectRows, checked && classes.complete)}>
            <Grid item className={classes.criterionOptions}>
                <SimpleCheckbox
                    checked={checked}
                    setChecked={() => {
                        const newChecked = !checked;
                        setChecked(newChecked);
                        if (newChecked) {
                            setReasonCodeForCurrentItem(reasonCode);
                        } else {
                            unsetReasonCodesForCurrentItem([reasonCode.id]);
                        }
                    }}
                    label={
                        isMobile ? (
                            <Text variant={'body2'} color={'BLACK'} medium>
                                {reasonCode.title}
                            </Text>
                        ) : (
                            reasonCode.title
                        )
                    }
                />
            </Grid>
        </Grid>
    );
};

interface ReasonCodeCategoryCellProps {
    reasonCodes?: LabsGqlReasonCodeOptionFragment[];
    category: Maybe<string>;
}

const ReasonCodeCategoryCellsMobile: React.VFC<ReasonCodeCategoryCellProps> = ({ reasonCodes, category }) => {
    const selectedReasonCodeIds = useSelectedReasonCodeIdsForCurrentItem();
    const selectedIdsForCategory = useFilterSelectedReasonCodeIdsByCategoryForCurrentItem(reasonCodes, category);
    const otherReasonCodeForCategory = reasonCodes?.find(rc => rc.category === category && rc.title === 'Other');
    const [showOptionsList, setShowOptionsList] = React.useState<boolean>(false);
    const [fieldTouched, setFieldTouched] = React.useState<boolean>(false);
    const otherIsSelected = selectedReasonCodeIds.some(codeId => otherReasonCodeForCategory?.id === codeId);
    const otherNotes = useSelectOtherNotesByReasonCodeIdForCurrentItem(otherReasonCodeForCategory?.id);
    const selectedReasonCodeTitles = (reasonCodes ?? [])
        .filter(code => selectedIdsForCategory.includes(code.id))
        .map(selectedCode => selectedCode.title)
        .join(', ');
    const truncatedTitles =
        selectedReasonCodeTitles.length > 32
            ? `${selectedReasonCodeTitles.substring(0, 32)}...`
            : selectedReasonCodeTitles;
    const isComplete = selectedIdsForCategory.length > 0;
    const otherNotesSetter = useSetOtherNotesByReasonCodeIdForCurrentItem(otherReasonCodeForCategory?.id ?? '');
    const itemId = useRefabFlowSelector(s => s.screenItemId);

    const hasError = otherIsSelected && fieldTouched && !otherNotes;
    const classes = useStyles({ hasError });

    return (
        <Grid container className={cx(classes.criterionContainer, isComplete && classes.complete)}>
            <Grid
                container
                className={classes.criterionOptions}
                style={{ padding: 8, flexFlow: 'column' }}
                direction={'column'}
                onClick={() => setShowOptionsList(!showOptionsList)}
            >
                <Grid
                    item
                    container
                    justifyContent={'space-between'}
                    onClick={() => setShowOptionsList(!showOptionsList)}
                >
                    <Text
                        variant={'body2'}
                        medium
                        color={isComplete ? `PRIMARY_FOREGROUND` : 'BLACK'}
                        style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                    >
                        <strong>{category}</strong>
                        {isComplete && `: ${truncatedTitles}`}
                    </Text>
                    <ChevronRight style={{ color: FlossPalette.PRIMARY_FOREGROUND }} />
                </Grid>
                {otherIsSelected && (
                    <>
                        <Grid item className={classes.otherHeader}>
                            <Text variant={'caption'} medium>
                                Other
                            </Text>
                            <Text variant={'caption'} color={'GRAY'}>
                                (Required)
                            </Text>
                        </Grid>
                        <Grid item style={{ width: `100%` }}>
                            <SimpleInput
                                value={otherNotes ?? ''}
                                onChange={value => otherNotesSetter(value ?? '')}
                                label={`Describe what's wrong with the ${category?.toLowerCase() ?? ''}`}
                                TextFieldProps={{
                                    InputProps: {
                                        classes: { root: classes.otherInputRoot },
                                        type: 'text',
                                        autoFocus: true,
                                    },
                                    InputLabelProps: {
                                        classes: { root: classes.otherLabel },
                                    },
                                    style: { width: `100%` },
                                    multiline: true,
                                    rows: 2,
                                    error: hasError,
                                    helperText: hasError ? 'This field is required' : undefined,
                                    onBlur: () => setFieldTouched(true),
                                    autoFocus: true,
                                    onClick: ev => ev.stopPropagation(),
                                }}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid
                container
                className={classes.criterionOptions}
                justifyContent={'center'}
                style={{
                    alignSelf: 'stretch',
                    flexBasis: '80%',
                }}
            >
                {showOptionsList && (
                    <PracticeFullScreenDialog
                        dialogPaperStyle={{
                            overflow: `visible`,
                            backgroundColor: FlossPalette.GRAY,
                        }}
                    >
                        <div style={{ minHeight: topMargin }}>&nbsp;</div>
                        <Slide in={true} direction={'up'} timeout={300}>
                            <div
                                style={{
                                    backgroundColor: FlossPalette.WHITE,
                                    borderTopRightRadius: 20,
                                    borderTopLeftRadius: 20,
                                    minHeight: `calc(100vh - ${topMargin}px)`,
                                }}
                            >
                                <div style={{ minHeight: `calc(100vh - ${topMargin}px - 96px)` }}>
                                    <div
                                        style={{
                                            borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
                                            paddingBlock: 12,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <span
                                            style={{ float: 'right', padding: '0 12px 12px 12px', cursor: 'pointer' }}
                                            onClick={() => setShowOptionsList(!showOptionsList)}
                                        >
                                            <CloseIcon style={{ color: FlossPalette.BLACK }} />
                                        </span>
                                        <Text variant={'body2'} medium>
                                            {category}
                                        </Text>
                                    </div>
                                    <div style={{ margin: `24px 0px 16px 0px`, padding: `0px 17px` }}>
                                        <Text variant={'body1'} medium>
                                            What was wrong with the {category}?
                                        </Text>
                                        <Text variant={'body2'} color={'GRAY'}>
                                            Select at least one option
                                        </Text>
                                    </div>
                                    <div style={{ padding: '0px 16px' }}>
                                        {reasonCodes
                                            ?.filter(code => code.category === category)
                                            .map(reasonCode => (
                                                <ReasonCodeIndividualCheckbox
                                                    key={`${itemId}-${reasonCode.id}`}
                                                    reasonCode={reasonCode}
                                                />
                                            ))}
                                    </div>
                                </div>
                                <Grid container className={classes.navAreaMobile} direction={'row'} spacing={1}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant={'secondary'}
                                            style={{ width: `100%`, textAlign: 'center' }}
                                            onClick={() => setShowOptionsList(!showOptionsList)}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant={'primary'}
                                            onClick={() => setShowOptionsList(!showOptionsList)}
                                            style={{ width: `100%`, textAlign: 'center' }}
                                        >
                                            Done
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Slide>
                    </PracticeFullScreenDialog>
                )}
            </Grid>
        </Grid>
    );
};

const ReasonCodeCategoryCellsDesktop: React.VFC<ReasonCodeCategoryCellProps> = ({ reasonCodes, category }) => {
    const selectedReasonCodeIds = useSelectedReasonCodeIdsForCurrentItem();
    const selectedIdsForCategory = useFilterSelectedReasonCodeIdsByCategoryForCurrentItem(reasonCodes, category);
    const [open, setOpen] = React.useState<boolean>(false);
    const [fieldTouched, setFieldTouched] = React.useState<boolean>(false);
    const otherReasonCodeForCategory = reasonCodes?.find(rc => rc.category === category && rc.title === 'Other');
    const otherIsSelected = selectedReasonCodeIds.some(codeId => otherReasonCodeForCategory?.id === codeId);
    const otherNotes = useSelectOtherNotesByReasonCodeIdForCurrentItem(otherReasonCodeForCategory?.id);
    const isChecked = selectedIdsForCategory.length > 0 || open;
    const unsetReasonCodesForCurrentItem = useUnsetReasonCodesForCurrentItem();
    const setReasonCodeForCurrentItem = useSetReasonCodeForCurrentItem();
    const otherNotesSetter = useSetOtherNotesByReasonCodeIdForCurrentItem(otherReasonCodeForCategory?.id ?? '');
    const onMultiSelectChange = React.useCallback(
        (newValues?: string[]) => {
            const newIds = newValues ?? [];
            const oldIds = selectedIdsForCategory;
            const removedIds = _.difference(oldIds, newIds);
            if (removedIds.length) {
                unsetReasonCodesForCurrentItem(removedIds);
            }
            const addedIds = _.difference(newIds, oldIds);
            addedIds.forEach(addedId => {
                const associatedReasonCode = reasonCodes?.find(code => code.id === addedId);
                if (associatedReasonCode) {
                    setReasonCodeForCurrentItem(associatedReasonCode);
                }
            });
        },
        [selectedIdsForCategory, reasonCodes, unsetReasonCodesForCurrentItem, setReasonCodeForCurrentItem],
    );

    const hasError = otherIsSelected && fieldTouched && !otherNotes;
    const classes = useStyles({ hasError });

    return (
        <Grid container>
            <SimpleCheckbox
                checked={isChecked}
                setChecked={() => {
                    const newChecked = !isChecked;
                    if (newChecked) {
                        setOpen(true);
                    } else {
                        unsetReasonCodesForCurrentItem(selectedIdsForCategory);
                    }
                }}
                label={category}
            />
            {isChecked && (
                <Grid container direction={'column'} style={{ paddingBottom: 16 }}>
                    <Grid item style={{ width: '100%' }}>
                        <SimpleMultiSelect
                            options={
                                reasonCodes
                                    ?.filter(code => code.category === category)
                                    .map(option => {
                                        return { value: option.id, label: option.title };
                                    }) ?? []
                            }
                            label={`What was wrong?`}
                            onChange={onMultiSelectChange}
                            value={selectedIdsForCategory}
                            SelectProps={{
                                open,
                                style: { backgroundColor: FlossPalette.WHITE, width: '100%' },
                                onOpen: () => setOpen(true),
                                onClose: () => setOpen(false),
                                variant: 'standard',
                            }}
                            InputLabelProps={{ style: { whiteSpace: `nowrap` } }}
                        />
                    </Grid>
                    {otherIsSelected && (
                        <>
                            <Grid item className={classes.otherHeader}>
                                <Text variant={'caption'} medium>
                                    Other
                                </Text>
                                <Text variant={'caption'} color={'GRAY'}>
                                    (Required)
                                </Text>
                            </Grid>

                            <Grid item>
                                <SimpleInput
                                    value={otherNotes}
                                    onChange={value => otherNotesSetter(value ?? '')}
                                    label={`Describe what's wrong with the ${category?.toLowerCase() ?? ''}`}
                                    TextFieldProps={{
                                        InputProps: {
                                            classes: { root: classes.otherInputRoot },
                                            type: 'text',
                                            autoFocus: true,
                                        },
                                        InputLabelProps: {
                                            classes: { root: classes.otherLabel },
                                        },
                                        style: { width: `100%` },
                                        multiline: true,
                                        rows: 2,
                                        error: hasError,
                                        helperText: hasError ? 'This field is required' : undefined,
                                        onBlur: () => setFieldTouched(true),
                                        autoFocus: true,
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export const ReasonCodeCategoryCells: React.VFC<ReasonCodeCategoryCellProps> = props =>
    useScreenIsMobile() ? <ReasonCodeCategoryCellsMobile {...props} /> : <ReasonCodeCategoryCellsDesktop {...props} />;
