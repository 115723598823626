import { styled } from '@orthly/ui';
import type { ReactNode } from 'react';
import React from 'react';

export interface PainterRootProps {
    /** The action to be performed when the button is clicked */
    designId?: (item: string) => void;
    /** Design Files */
    designFiles?: string[];
    /** Children elements */
    children?: ReactNode;
}

const Layout = styled('div')({
    display: 'grid',
    gridTemplateRows: '1fr auto auto',
    gridTemplateColumns: 'minmax(auto, 400px)',
    gridGap: '10px',
});

export const PainterRoot: React.FC<PainterRootProps> = ({ children }) => {
    return <Layout>{children}Painter Root</Layout>;
};
