import { getGuidedPresetQuestionTitle, getPresetTitle } from '../GuidedWaxup.util';
import { GUIDED_WAXUP_COMMON_STYLES } from '../VisualConstants.util';
import { useUndo } from '../hooks/useUndo';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupAction, useGuidedWaxupSelector } from '../state/GuidedWaxupState';
import { GuidedWaxupScheduleLiveCall } from './LiveDesignReview/GuidedWaxupScheduleLiveCall';
import { RejectionNoteInput } from './RejectionNoteInput';
import { StructuredRejectionNotes } from './StructuredRejectionNotes/StructuredRejectionNotes';
import { LabsGqlGuidedWaxupPresetStatus } from '@orthly/graphql-schema';
import {
    Text,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    FormControlLabel,
    Grid,
    RadioPrimitive as Radio,
    RadioGroupPrimitive as RadioGroup,
    Button,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    container: {
        padding: '0px 24px',
        marginBottom: 10,
    },
    radioGroup: {
        flexWrap: 'wrap',
        [theme.breakpoints.down('lg')]: {
            flexWrap: 'nowrap',
        },
    },
    radioButton: {
        borderRadius: '16px',
        paddingRight: '20px',
        marginLeft: 0,
        ...GUIDED_WAXUP_COMMON_STYLES,
    },
    presetRejectionInput: {
        marginTop: 24,
        padding: '0px 24px',
        [theme.breakpoints.down('lg')]: {
            width: '100%',
            marginBottom: 24,
        },
    },
    questionTitle: {
        marginTop: 24,
        marginBottom: 16,
        [theme.breakpoints.down('lg')]: {
            marginBottom: 8,
        },
    },
}));

export const GuidedPresetControls: React.VFC = () => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const { enableStructuredDesignReviewNotes, selectedTab, order, selectedDesignRevisionId } = useGuidedWaxupContext();
    const presets = useGuidedWaxupSelector(s => s.presets);
    const selectedPreset = presets[selectedTab];
    const isRejected = selectedPreset?.status === LabsGqlGuidedWaxupPresetStatus.Rejected;
    const setPresetStatus = useGuidedWaxupAction('SET_PRESET_STATUS');
    const presetQuestionTitle = getGuidedPresetQuestionTitle(selectedTab);
    const [isContainerOpen, setIsContainerOpen] = React.useState(false);
    const { setSnapshot, resetToSnapshot } = useUndo(selectedPreset);
    const resetPreset = React.useCallback(() => {
        resetToSnapshot();
        setIsContainerOpen(false);
    }, [resetToSnapshot]);

    return (
        <>
            <Grid item md={6} className={classes.container}>
                <Text variant={isMobile ? 'body2' : 'body1'} medium className={classes.questionTitle}>
                    {presetQuestionTitle}
                </Text>
                <RadioGroup
                    defaultValue={''}
                    value={selectedPreset?.status ?? ''}
                    onChange={e => {
                        const presetStatus = e.target.value as LabsGqlGuidedWaxupPresetStatus;

                        setPresetStatus({ presetName: selectedTab, status: presetStatus });
                        setIsContainerOpen(presetStatus === LabsGqlGuidedWaxupPresetStatus.Rejected);
                    }}
                    row={true}
                    className={classes.radioGroup}
                >
                    <FormControlLabel
                        className={classes.radioButton}
                        value={LabsGqlGuidedWaxupPresetStatus.Approved}
                        control={<Radio color={'secondary'} />}
                        label={
                            <Text variant={'body2'} medium>
                                Accept
                            </Text>
                        }
                    />
                    <FormControlLabel
                        className={classes.radioButton}
                        value={LabsGqlGuidedWaxupPresetStatus.Rejected}
                        onClick={() => setIsContainerOpen(true)}
                        control={<Radio color={'secondary'} />}
                        label={
                            <Text variant={'body2'} medium>
                                Redesign
                            </Text>
                        }
                    />
                </RadioGroup>
                {isMobile && selectedDesignRevisionId && (
                    <GuidedWaxupScheduleLiveCall orderId={order.id} revisionId={selectedDesignRevisionId} />
                )}
                {isMobile && !!selectedPreset?.structured_notes?.length && (
                    <Button
                        variant={'ghost'}
                        onClick={() => {
                            setIsContainerOpen(true);
                        }}
                    >
                        View rejection notes
                    </Button>
                )}
            </Grid>
            <Grid item md={6} className={classes.presetRejectionInput}>
                {enableStructuredDesignReviewNotes ? (
                    <StructuredRejectionNotes
                        closeLabel={'Save'}
                        isOpen={isContainerOpen}
                        isRejected={isRejected}
                        onBack={resetPreset}
                        onCancel={resetPreset}
                        onClose={() => {
                            setIsContainerOpen(false);
                            setSnapshot();
                        }}
                    />
                ) : (
                    isRejected && (
                        <RejectionNoteInput
                            rejectionNote={selectedPreset?.notes}
                            presetName={selectedTab}
                            tabTitle={getPresetTitle(selectedTab)}
                        />
                    )
                )}
            </Grid>
        </>
    );
};
