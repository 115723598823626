import type { LabsGqlUpdateCapacityStationPoolArgs } from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';
import React from 'react';

type EditCapacityStationPoolFormFields = Pick<LabsGqlUpdateCapacityStationPoolArgs, 'unit_limit'>;

interface CapacityStationPoolFormProps {
    onSubmit: (result: EditCapacityStationPoolFormFields) => void;
    unit_limit: number;
}

export const EditCapacityStationPoolForm: React.VFC<CapacityStationPoolFormProps> = ({ onSubmit, unit_limit }) => {
    return (
        <QuickForm<EditCapacityStationPoolFormFields>
            dirtySubmitOnly
            onSubmit={onSubmit}
            initialValues={{ unit_limit }}
            fields={{
                unit_limit: {
                    label: 'Unit Limit',
                    type: 'number',
                },
            }}
        />
    );
};
