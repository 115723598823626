import { ProductStationUnitMappingTable } from './CapacityStationTables/ProductStationUnitMappingTable.graphql';
import { CreateProductStationUnitMappingDialog } from './CreateDialogs/CreateProductStationUnitMappingDialog';
import { useMutation, useQuery } from '@apollo/client';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import type { ProductStationUnitMappingDtoFragment } from '@orthly/graphql-inline-react';
import type { LabsGqlCapacityLimitUnitType } from '@orthly/graphql-schema';
import { ActionCard, useRootActionCommand } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

const CapacityStations_Fragment = graphql(`
    fragment CapacityStationDTO on CapacityStationDTO {
        id
        created_at
        updated_at
        organization_id
        name
        unit_type
    }
`);

const Mappings_Fragment = graphql(`
    fragment ProductStationUnitMappingDTO on ProductStationUnitMappingDTO {
        id
        created_at
        updated_at
        station_id
        product_header
        units
    }
`);

const CreateMapping_Mutation = graphql(`
    mutation CreateMapping_Mutation($createProductStationUnitMappingArgs: CreateProductStationUnitMappingArgs!) {
        createProductStationUnitMapping(createProductStationUnitMappingArgs: $createProductStationUnitMappingArgs) {
            ...ProductStationUnitMappingDTO
        }
    }
`);

const AllCapacityStations_Query = graphql(`
    query AllCapacityStations {
        getAllCapacityStations {
            ...CapacityStationDTO
        }
    }
`);

const AllMappings_Query = graphql(`
    query AllProductStationUnitMappings {
        getAllProductStationUnitMappings {
            ...ProductStationUnitMappingDTO
        }
    }
`);

export type MappingRow = ProductStationUnitMappingDtoFragment & {
    station_name?: string;
    station_unit_type?: LabsGqlCapacityLimitUnitType;
};

export const ProductStationUnitMappingsRoot: React.FC = () => {
    const { loading: loadingStations, data: stations } = useQuery(AllCapacityStations_Query, {
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const stationsResult = getFragmentData(CapacityStations_Fragment, stations?.getAllCapacityStations);

    const {
        loading: loadingMappings,
        data: mappings,
        error: mappingsError,
        refetch: refetchMappings,
    } = useQuery(AllMappings_Query, {
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const mappingsResult = getFragmentData(Mappings_Fragment, mappings?.getAllProductStationUnitMappings);

    const createMapping = useMutation(CreateMapping_Mutation);
    const {
        submit: submitMapping,
        submitting: submittingMapping,
        open: openAddMappingDialog,
        setOpen: setOpenAddMappingDialog,
    } = useRootActionCommand(createMapping, {
        onSuccess: async () => {
            await refetchMappings();
            setOpenAddMappingDialog(false);
        },
        successMessage: 'Product Station Unit Mapping created!',
    });

    const mappingRows: MappingRow[] = React.useMemo(
        () =>
            (mappingsResult ?? []).map(mapping => {
                const station = stationsResult?.find(({ id }) => id === mapping.station_id);
                return {
                    ...mapping,
                    station_name: station?.name ?? undefined,
                    station_unit_type: station?.unit_type ?? undefined,
                };
            }),
        [mappingsResult, stationsResult],
    );

    return (
        <Grid container>
            <Grid item xs={12}>
                <ProductStationUnitMappingTable
                    mappingRows={mappingRows}
                    loadingMappings={loadingMappings}
                    setOpenAddMappingDialog={setOpenAddMappingDialog}
                    refetchMappings={refetchMappings}
                />
                <CreateProductStationUnitMappingDialog
                    setOpen={setOpenAddMappingDialog}
                    submitting={submittingMapping}
                    open={openAddMappingDialog}
                    stations={stationsResult ? [...stationsResult] : []}
                    submit={submitMapping}
                    loadingStations={loadingStations}
                />
            </Grid>
            {mappingsError && (
                <ActionCard title={'Error retrieving product station unit mappings'} subtitle={''} variant={'alert'} />
            )}
        </Grid>
    );
};
