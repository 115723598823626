import type { EditCapacityDialogProps } from '../CapacityStationsRoot.graphql';
import { EditStationForm } from '../Forms/EditStationForm';
import type { LabsGqlCapacityStationDto } from '@orthly/graphql-schema';
import { RootActionDialog } from '@orthly/ui';
import React from 'react';

type EditStationProps = EditCapacityDialogProps & {
    station: LabsGqlCapacityStationDto;
};

export const EditStationDialog: React.VFC<EditStationProps> = (props: EditStationProps) => {
    const { submitting, open, setOpen, submit, station } = props;
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Edit Station'}
            content={
                <EditStationForm
                    name={station.name}
                    onSubmit={values => {
                        submit({
                            updateCapacityStationArgs: {
                                ...values,
                                id: station.id,
                            },
                        }).catch(console.error);
                    }}
                />
            }
            hideButton={true}
        />
    );
};
