import { PaginatedOrgTableWrapper } from './PaginatedOrgTableWrapper';
import { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

const rowMapping = [
    { label: 'Name', value: (row: any) => row.name },
    {
        label: 'Parent Organization',
        value: (row: any) => row.ancestors?.map((ancestor: any) => ancestor.name).reverse(),
    },
    { label: 'Created At', value: (row: any) => dayjs(row.created_at).format('MMM D, YYYY') },
];

export const PaginatedPracticesTable: React.VFC = () => {
    const navigate = useNavigate();
    const onRowClick = (rowId: string) => {
        navigate(`/accounts/practices/${rowId}/payment_methods`);
    };

    return (
        <PaginatedOrgTableWrapper
            title={'Practice Organizations'}
            queryFilters={{
                include_ancestors: true,
                type: [LabsGqlOrganizationType.Practice],
            }}
            csvFilters={{ type: [LabsGqlOrganizationType.Practice] }}
            onRowClick={onRowClick}
            rowMapping={rowMapping}
        />
    );
};
