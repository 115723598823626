import { useActiveOrdersViewIsEdited } from '../../components/OrdersSubnav/OrdersCustomViewNavItem';
import {
    useOrdersListQueryVars,
    useOrdersSearchQueryDisabled,
    useOrdersOverviewState,
} from '../../state/OrdersOverview.selectors';
import { useOrderIdsForListView } from './useOrderIdsForListView.graphql';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import constate from 'constate';
import React from 'react';

// Utility for tracking usages of saved searches for Orders
function useTrackOrderSavedSearchLoaded() {
    const savedSearchId = useOrdersOverviewState(s => s.view?.id);
    const activeViewEdited = useActiveOrdersViewIsEdited();

    return React.useCallback(() => {
        if (savedSearchId && !activeViewEdited) {
            BrowserAnalyticsClientFactory.Instance?.track('Ops - Portal - Saved Search Loaded', {
                search_id: savedSearchId,
                search_type: 'order',
            });
        }
    }, [savedSearchId, activeViewEdited]);
}

function _useOrdersListViewOrdersIds() {
    const queryVars = useOrdersListQueryVars();
    const searchQueryDisabled = useOrdersSearchQueryDisabled();
    const trackOrderSavedSearchLoaded = useTrackOrderSavedSearchLoaded();

    return useOrderIdsForListView(queryVars, {
        skip: searchQueryDisabled,
        onCompleted: () => {
            trackOrderSavedSearchLoaded();
        },
    });
}

export const [OrdersListViewOrderIdsProvider, useOrdersListViewOrderIds] = constate(_useOrdersListViewOrdersIds);
