import {
    OrderDetailSidebarItemLayout,
    OrderDetailSidebarItemLoading,
} from '../../../../components/OrderDetailSidebar/OrderDetailSidebarItemLayout';
import { OrderListItemFlags } from '../../../../components/OrderListItem/OrderListItemFlags.graphql';
import { useCurrentOrderDetailId, useOpenOrderDetailOps } from '../../../../utils/router/useOpenOrderDetail';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { OrderFormatUtils } from '@orthly/shared-types';
import { OverflowTextWithTooltip } from '@orthly/ui';
import { FlossPalette, Grid, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const AdminOrderDetailSidebarListItem_Fragment = graphql(`
    fragment AdminOrderDetailSidebarListItem_Fragment on OrderAdminListViewEntry {
        id
        patient_name
        patient_order_count
        items_description
        doctor_name
        flags {
            ...AdminOrderListItemFlag_Fragment
        }
    }
`);

const PatientOrderCountWrapper = styled(Grid)({
    backgroundColor: FlossPalette.GRAY,
    height: 16,
    width: 16,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,
    marginTop: 2,
});

const SecondaryText = styled(Text)({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

interface OrderDetailSidebarListItemContentProps {
    order: FragmentType<typeof AdminOrderDetailSidebarListItem_Fragment>;
    practiceName: string;
    manufacturerName: string;
}

const OrderDetailSidebarListItemContent: React.VFC<OrderDetailSidebarListItemContentProps> = ({
    order,
    practiceName,
    manufacturerName,
}) => {
    const { id, patient_name, patient_order_count, items_description, doctor_name, flags } = getFragmentData(
        AdminOrderDetailSidebarListItem_Fragment,
        order,
    );

    const selectedOrderId = useCurrentOrderDetailId();
    const openOrder = useOpenOrderDetailOps();

    return (
        <OrderDetailSidebarItemLayout selected={id === selectedOrderId} onClick={e => openOrder(id, e)}>
            <Grid container alignItems={'center'} style={{ position: 'relative' }} wrap={'nowrap'}>
                <Grid item container xs={10} alignItems={'center'} style={{ position: 'relative' }} wrap={'nowrap'}>
                    <OverflowTextWithTooltip medium={true} text={patient_name} color={'BLACK'} variant={'body2'} />
                </Grid>
                {patient_order_count > 1 && (
                    <Grid
                        item
                        container
                        xs={2}
                        alignItems={'center'}
                        style={{ position: 'relative' }}
                        justifyContent={'flex-end'}
                    >
                        <PatientOrderCountWrapper container>
                            <Text variant={'caption'} color={'TAN'} style={{ lineHeight: 0 }}>
                                {patient_order_count}
                            </Text>
                        </PatientOrderCountWrapper>
                    </Grid>
                )}
            </Grid>
            <Grid container wrap={'wrap'}>
                <OrderListItemFlags flags={flags} small={true} />
            </Grid>
            <Grid container wrap={'nowrap'} style={{ paddingBottom: 2 }}>
                <SecondaryText variant={'caption'} color={'DARK_GRAY'}>
                    {items_description}
                </SecondaryText>
            </Grid>
            <Grid container wrap={'nowrap'}>
                <SecondaryText variant={'caption'} color={'DARK_GRAY'}>
                    {OrderFormatUtils.getDisplayedStaffMemberName(doctor_name)} • {practiceName} • {manufacturerName}
                </SecondaryText>
            </Grid>
        </OrderDetailSidebarItemLayout>
    );
};

interface OrderDetailSidebarListItemProps {
    listItem?: OrderDetailSidebarListItemContentProps;
}

export const OrderDetailSidebarListItem: React.VFC<OrderDetailSidebarListItemProps> =
    React.memo<OrderDetailSidebarListItemProps>(props =>
        props.listItem ? <OrderDetailSidebarListItemContent {...props.listItem} /> : <OrderDetailSidebarItemLoading />,
    );
