import { usePalateState } from '../../state/Palate.reducer';
import { PalateOrdersList } from './PalateOrdersList';
import { usePalateListViewData } from './usePalateListViewData.graphql';
import { LabsGqlFilterComparator, LabsGqlLabOrderSortKey, LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { OrderFilterIdEnum } from '@orthly/shared-types';
import React from 'react';

/** This pattern is really not great but it's the cleanest way to curry the practiceId into the useItemData function */
function getUseItemData(practiceId: string) {
    return function useItemData(startIndex: number, stopIndex: number) {
        const orderFilters = usePalateState(s => s.orderFilters);

        return usePalateListViewData(startIndex, stopIndex, {
            sort: { key: LabsGqlLabOrderSortKey.DueDate, asc: true },
            filter: {
                excluded_statuses: [
                    LabsGqlLabOrderStatus.Cancelled,
                    LabsGqlLabOrderStatus.NeedsRefabrication,
                    LabsGqlLabOrderStatus.Delivered,
                ],
            },
            criteria: [
                {
                    filter_id: OrderFilterIdEnum.by_partner,
                    comparison_value: practiceId,
                    comparator: LabsGqlFilterComparator.Equals,
                },
                ...(orderFilters ?? []),
            ],
        });
    };
}

interface PalateInFlightOrdersListProps {
    practiceId: string;
}

export const PalateInFlightOrdersList: React.VFC<PalateInFlightOrdersListProps> = ({ practiceId }) => {
    return <PalateOrdersList useItemData={getUseItemData(practiceId)} />;
};
